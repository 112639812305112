import {customStyles} from "../../Constants";
import Modal from "react-modal";
import {React} from "react";
import {useForm} from "react-hook-form";
import {saveReopenQueue} from "../../Functions/ReopenQueueFunctions";

export function ReopenModal({open, setOpen, sellerRequestid}) {
    const { register, handleSubmit , reset,setValue} = useForm();

    return <Modal
        isOpen={open}
        style={customStyles}
        contentLabel="Reopen Seller Request"
        ariaHideApp={false}
    >
        <button onClick={() => setOpen(false)}>close</button>
        <h1>Reopen Request</h1>
        <form onSubmit={handleSubmit(data => saveReopenQueue(data, setOpen))}>
            {setValue('seller_request_id', sellerRequestid)}
            {setValue('status', "Open")}

            <label>Reason to Reopen</label>
            <input name="reason" {...register('reason')} />

            <input type="submit" value="Submit Request" className="saveNewBuyerRequestButton"/>
        </form>
    </Modal>
}
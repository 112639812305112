import React, {useEffect, useState} from "react";
import {fetchDuplicateQueue, updateDuplicateQueue} from "../../Functions/SellersList";
import Modal from "react-modal";
import {BUYER_REQUEST_ID, customStyles, DATE_RANGE, DATE_RANGE_HELPER_TEXT, SELLER_REQUEST_ID} from "../../Constants";
import fetchCategories from "../../Functions/CategoryFunctions";
import {fetchFileTypes} from "../../Functions/Files";
import {show} from "react-modal/lib/helpers/ariaAppHider";

export default function DuplicateQueue() {
    const[approveModal, setApproveModal] = useState(false);
    const[categories, setCategories]= useState({});
    const[duplicateID, setDuplicateID] = useState();
    const[duplicatesQueue, setDuplicateQueue] = useState([]);
    const[fileTypes, setFileTypes] = useState();
    const[filterOpen, setFilterOpen] = useState(false);
    const[reason, setReason] = useState();
    const[projectId, setProjectId] = useState(localStorage.getItem('projectId'));
    const[rejectModal, setRejectModal] = useState(false);
    const[sortOpen, setSortOpen] = useState();
    const redTD = { backgroundColor: '#ffb3b3' };
    const greenTD = { backgroundColor: '#c6ecd9' };
    const[duplicateFilteredData, setDupeFilteredData] = useState({
        status: "Open",
        batch: "",
        seller_request_id: "",
        buyer_request_id: "",
        category_id: "",
        data_requested: "",
        details: "",
        parameters: "",
        file_type_id: "",
    });
    window.addEventListener('projectId', () => {
        if (localStorage.getItem('projectId') === 'Select A Project') {
            localStorage.removeItem('projectId');
            setDuplicateQueue([]);
        } else {
            setProjectId(localStorage.getItem('projectId'));
        }
    })

    useEffect(() => {
        fetchDuplicateQueue(projectId, duplicateFilteredData, setDuplicateQueue, setFilterOpen);
    }, [projectId]);

    useEffect(() => fetchCategories(setCategories), []);
    useEffect(() => fetchFileTypes(setFileTypes), []);

    function handleCloseRejectModal() {
        setRejectModal(false);
        setReason('');
    }

    function handleDuplicate(status) {
        let data = {};
        data.status = status;
        data.reason = reason;
        updateDuplicateQueue(duplicateID, projectId, duplicateFilteredData, setDuplicateQueue, setFilterOpen, data);
        setApproveModal(false);
        setRejectModal(false);
        setReason('');
    }

    const handleReasonChange = (event) => {
        setReason(event.target.value);
    };

    function openApproveModal(duplicateID) {
        setDuplicateID(duplicateID);
        setApproveModal(true);
    }

    function openRejectModal(duplicateID) {
        setDuplicateID(duplicateID);
        setRejectModal(true)
    }

    function statusChange() {
        const status = !duplicateFilteredData.status;
        setDupeFilteredData( {...duplicateFilteredData, status: status});
    }

    function clearFilter() {
        setDupeFilteredData({status: "",
            batch: "",
            seller_request_id: "",
            buyer_request_id: "",
            category_id: "",
            data_requested: "",
            details: "",
            parameters: "",
            file_type_id: ""
        });
        fetchDuplicateQueue(projectId, duplicateFilteredData, setDuplicateQueue, setFilterOpen);
    }

    function clearSort() {

    }

    return <>
        <h1>Seller Duplicate Queue</h1>

        <Modal isOpen={approveModal} style={customStyles} ariaHideApp={false}>
            <button onClick={() => setApproveModal(false)}>close</button>
            <p>Are you sure?</p>
            <button className="newEntityButton" onClick={() => {handleDuplicate("Approved")}}>
                Approve Duplicate
            </button>
        </Modal>

        <Modal isOpen={rejectModal} style={customStyles} ariaHideApp={false}>
            <button onClick={() => handleCloseRejectModal()}>close</button>
            <input type="text" onChange={handleReasonChange} value={reason} />
            <button className="newEntityButton" onClick={() => {handleDuplicate("Rejected")}}>
                Reject Duplicate
            </button>
        </Modal>

        <button onClick={()=> setFilterOpen(!filterOpen)}>Filter</button>
        <button onClick={()=> clearFilter()}>Clear Filter</button>
        {/*<button onClick={()=> setSortOpen(true)}>Sort</button>*/}
        {/*<button onClick={()=> clearSort()}>Clear Sort</button>*/}
        {filterOpen ? (

            <ul className="menu">
                <li className="menu-item">
                    Status <br />
                    <select  name="status" defaultValue={duplicateFilteredData.status}
                             onChange={(e) =>
                                 setDupeFilteredData( {...duplicateFilteredData, status: e.target.value})}>
                        <option key="Open" value="Open">Open</option>
                        <option key="Approved" value="Approved">Approved</option>
                        <option key="Rejected" value="Rejected">Rejected</option>

                    </select>
                </li>

                <li className="menu-item">
                    Batch
                    <input type="text" defaultValue={duplicateFilteredData.phase} name="phase" placeholder="Batch"
                           onChange={(e) =>
                               setDupeFilteredData( {...duplicateFilteredData, phase: e.target.value})}>
                    </input>
                </li>

                <li className="menu-item">
                    Seller Request ID
                    <input type="text" defaultValue={duplicateFilteredData.seller_request_id} name="seller_request_id" placeholder="Seller Request ID"
                           onChange={(e) =>
                               setDupeFilteredData( {...duplicateFilteredData, seller_request_id: e.target.value})}>
                    </input>
                </li>

                {/*<li className="menu-item">*/}
                {/*    Buyer Request ID*/}
                {/*    <input type="text" name="buyer_request_id" defaultValue={duplicateFilteredData.buyer_request_id} placeholder="Buyer Request ID"*/}
                {/*           onChange={(e) =>*/}
                {/*               setDupeFilteredData( {...duplicateFilteredData, buyer_request_id: e.target.value})}>*/}
                {/*    </input>*/}
                {/*</li>*/}

                <li className="menu-item">
                    Category <br />
                    <select  name="category_id" defaultValue={duplicateFilteredData.category_id}
                             onChange={(e) =>
                                 setDupeFilteredData( {...duplicateFilteredData, category_id: e.target.value})}>
                        <option key={0} value={0}>Select a Category</option>
                        {categories && categories.map((category,i)=> {
                            return (
                                <option key={category.id} value={category.id}>{category.category_name}</option>
                            )
                        })}
                    </select>
                </li>
                <li className="menu-item">
                    Request Name
                    <input type="text" name="data_requested" defaultValue={duplicateFilteredData.data_requested} placeholder="Date Requested"
                           onChange={(e) =>
                               setDupeFilteredData( {...duplicateFilteredData, data_requested: e.target.value})}>
                    </input>
                </li>
                <li className="menu-item">
                    Request Details
                    <input type="text" defaultValue={duplicateFilteredData.details} name="filterRequestId" placeholder="Details"
                           onChange={(e) =>
                               setDupeFilteredData( {...duplicateFilteredData, details: e.target.value})}>
                    </input>
                </li>

                <li className="menu-item">
                    Category <br />
                    <select  name="file_type_id" defaultValue={duplicateFilteredData.file_type_id}
                             onChange={(e) =>
                                 setDupeFilteredData( {...duplicateFilteredData, file_type_id: e.target.value})}>
                        <option key={0} value={0}>Select a File Type</option>
                        {fileTypes && fileTypes.map((fileType,i)=> {
                            return (
                                <option key={fileType.id} value={fileType.id}>{fileType.file_type}</option>
                            )
                        })}
                    </select>
                </li>


                <li>
                    <button onClick={()=> fetchDuplicateQueue(projectId, duplicateFilteredData, setDuplicateQueue, setFilterOpen)}>Apply</button>
                </li>
            </ul>
        ) : null}

        <table key="dupeQueueTable" className="sellerListTable">
            <tbody>
            {duplicatesQueue.length > 0 && (duplicatesQueue).map((duplicate,i)=>{
                const status = duplicate.status == true ? "Approved" : "Unapproved";
                const retained = duplicate.retained;
                const deleted = duplicate.deleted;
                let show_buttons = true;
                if (duplicate.status == 'Approved' || duplicate.status == 'Rejected') {
                    show_buttons = false
                }
                    return (<>
                    <tr className="outline">
                        <td>
                            {show_buttons && <button className="approveButton" onClick={()=> { openApproveModal(duplicate.id) }}>Approve</button>}
                            {show_buttons && <button className="rejectButton" onClick={()=> { openRejectModal(duplicate.id) }}>Reject</button>}
                        </td>
                        <td className="outline">Phase</td>
                        <td className="outline">Requested On</td>
                        <td className="outline">{SELLER_REQUEST_ID}</td>
                        <td className="outline">{BUYER_REQUEST_ID}</td>
                        <td className="outline">Category</td>
                        <td className="outline">Request Name</td>
                        <td className="outline">Request Details</td>
                        <td className="outline">{DATE_RANGE}</td>
                        <td className="outline">Preferred File Type</td>
                        <td className="outline">Status</td>
                        <td className="outline">Requestor</td>
                    </tr>
                    <tr key={"deleted"+duplicate.id}>
                        <td className="outline" style={redTD}>Deleted</td>
                        <td className="outline" style={redTD}>{deleted.batch}</td>
                        <td className="outline" style={redTD}>{deleted.assigned_date}</td>
                        <td className="outline" style={redTD}>{deleted.seller_request_id}</td>
                        <td className="outline" style={redTD}>{deleted.buyer_request_ids}</td>
                        <td className="outline" style={redTD}>{deleted.category}</td>
                        <td className="outline" style={redTD}>{deleted.data_requested}</td>
                        <td className="outline" style={redTD}>{deleted.details}</td>
                        <td className="outline" style={redTD}>{deleted.parameters}</td>
                        <td className="outline" style={redTD}>{deleted.file_type}</td>
                        <td className="outline" style={redTD}>{status}</td>
                        <td className="outline" style={redTD}>{duplicate.user}</td>
                    </tr>

                    <tr key={"retainted"+duplicate.id} >
                        <td className="outline" style={greenTD}>Retained</td>
                        <td className="outline" style={greenTD}>{retained.batch}</td>
                        <td className="outline" style={greenTD}>{retained.assigned_date}</td>
                        <td className="outline" style={greenTD}>{retained.seller_request_id}</td>
                        <td className="outline" style={greenTD}>{retained.buyer_request_ids}</td>
                        <td className="outline" style={greenTD}>{retained.category}</td>
                        <td className="outline" style={greenTD}>{retained.data_requested}</td>
                        <td className="outline" style={greenTD}>{retained.details}</td>
                        <td className="outline" style={greenTD}>{retained.parameters}</td>
                        <td className="outline" style={greenTD}>{retained.file_type}</td>
                        <td className="outline" style={greenTD}>{status}</td>
                        <td className="outline" style={greenTD}>{duplicate.user}</td>
                    </tr>
                </>)
            })}
            </tbody>
        </table>
    </>
}
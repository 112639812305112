import FileUploader from "../FileUploader/FileUploader";
import Modal from "react-modal";
import {React, useEffect, useState} from "react";
import {customStyles} from "../../Constants";
import {useForm} from "react-hook-form";
import {fetchEntities} from "../../Functions/EntityFunctions"
import {importUsersSpreadsheet} from "../../Functions/Users";
import {Link} from 'react-router-dom';


export default function ImportUserModal({importUserModalOpen, setImportUserModalOpen, fetchUsers, userFilteredData,
                                            setUserRowData, setUpdateUserList, filteredData, setFilterOpen, sorting,
                                            setSortOpen, setNotification}) {
    const { register, handleSubmit , reset,setValue} = useForm();
    const[error, setError] = useState();
    const[files, setFiles] = useState([]);
    const[entities, setEntities] = useState([]);
    const[isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        fetchEntities(setEntities);
    }, []);

    function importUserSpreadsheet (data) {
        if(files.length === 0) { setError('You must add a file'); return;}
        importUsersSpreadsheet(setImportUserModalOpen, files, setError, setIsLoading, fetchUsers,
            userFilteredData, setUserRowData, setUpdateUserList, filteredData, setFilterOpen, sorting, setSortOpen, setNotification);
    }

    function closeModal() {
        setImportUserModalOpen(false);
        setError();
        setIsLoading();
    }

    return <>
        <Modal
            isOpen={importUserModalOpen}
            style={customStyles}
            contentLabel="Import Buyer's Requests"
            ariaHideApp={false}
        >
            <h2>Import Buyer's Requests</h2>
            {isLoading && <h1>Loading....</h1>}
            {error && <h1 className="userError">{error}</h1>}
            <button onClick={() => closeModal()}>close</button>

            <form onSubmit={handleSubmit(data => importUserSpreadsheet(data))}>
                <Link to="/ImportUsers.xlsx" target="_blank" download>Template File</Link>
                <br/>
                <br/>

                <FileUploader onFilesSelected={setFiles} width="300px" height='400px' fileFormats='XLSX, CSV'/>
                <input type="submit" value="Import" className="saveNewSellerRequestButton"/>
            </form>
        </Modal>
    </>
}
import {React, useEffect, useState} from "react";
import { useForm } from "react-hook-form";
import './WorkstreamManagement.css';
import {useNavigate, useLocation} from "react-router-dom";
import axios from "axios";
import * as CONSTANTS from "../../Constants";

export default function WorkstreamManagement() {
    const { register, handleSubmit } = useForm();
    const navigate = useNavigate();
    const[ownerRowData, setOwnerRowData] = useState([]);
    const[error, setError] = useState();
    const {state} = useLocation();
    const { workstream_category_id, deliverable_code } = state;

    function saveData(data) {
        data.workstream_category_id = workstream_category_id;

        if (data['project_id'] === 0) { setError(CONSTANTS.PROJECT_ERROR_MESSAGE); return;}
        if (data['workstream_category_id'] === '') { setError("You must type a Workstream Category Code"); return;}
        if (data['workstream_code'] === '') { setError("You must type a workstream code"); return;}
        if (data['workstream_description'] === '') { setError("You must type a workstream description"); return;}
        if (isNaN(data['owner_account_id']) || data['owner_account_id'] === 'Select An Owner') { setError(CONSTANTS.OWNER_ERROR_MESSAGE); return;}
        const token = localStorage.getItem("accessToken");
        axios.post(process.env.REACT_APP_API_BASE_URL + '/api/v1/workstreams', data,
            { headers: {
                    "Authorization" : `Bearer ${token}`
                }})
            .then(function (response) {
                navigate("/deliverableManagement", {state: {
                    workstream_id: response.data.workstream.id,
                    deliverable_code: deliverable_code + response.data.workstream.workstream_code
                }});
            })
            .catch(function (error) {
                setError((error));
                console.log(error);
            })
    }

    useEffect( () => {
        const token = localStorage.getItem("accessToken");
        axios.get(process.env.REACT_APP_API_BASE_URL + '/api/v1/users',
            { headers: {
                    "Authorization" : `Bearer ${token}`
                }})
            .then(function (response) {
                console.log(response['data']['users']);
                setOwnerRowData(response['data']['users']);
            })
            .catch(function (error) {
                setError((error));
                console.log(error);
            })
    }, []);

    return <>
        <h1>Project Management</h1>
        {error && <h1 className="userError">{error}</h1>}
        <form className="categoryManagmentForm" onSubmit={handleSubmit(data => saveData(data))}>
            <h1>Workstream Category</h1>
            <label>Code</label>
            <input name="workstream_code" className="categoryManagmenInput"  {...register('workstream_code')} />
            <label>Description</label>
            <input name="workstream_description" className="categoryManagmenInput" {...register('workstream_description')} />
            <label>Owner</label>
            <select name="owner_account_id" {...register('owner_account_id')} >
                <option> Select An Owner</option>
                {ownerRowData && ownerRowData.map((owner,i)=>{
                    return (
                        <option value={owner.id} key={owner.id}>{owner.full_name}</option>
                    )
                })}
            </select>

            <input type="submit" value="Save and Continue"/>
        </form>

    </>
}
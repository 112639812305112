import axios from "axios";
import * as CONSTANTS from "../Constants";

const token = localStorage.getItem("accessToken");

export function fetchResponses(responseFilters, setResponses, setFilterOpen, projectId) {
    let params = "";
    if (projectId != "Select A Project") {params += "&project_id="+ projectId; }
    if (responseFilters.seller_request_id && responseFilters.seller_request_id !== "") {params += "&seller_request_id="+ responseFilters.seller_request_id; }
    if (responseFilters.response_id && responseFilters.response_id !== "") { params += "&response_id="+ responseFilters.response_id; }
    if (responseFilters.response_details !== "") { params += "&response_details="+ responseFilters.response_details; }
    if (responseFilters.edited_response !== "") { params += "&edited_response="+ responseFilters.edited_response; }
    if (responseFilters.response_type && responseFilters.response_type !== "") { params += "&response_type="+ responseFilters.response_type; }
    if (responseFilters.status !== "") { params += "&status="+ responseFilters.status; }

    axios.get(process.env.REACT_APP_API_BASE_URL + '/api/v1/seller_request_response?' + params,
        { headers: {
                "Authorization" : `Bearer ${token}`
            }})
        .then(function (response) {
            setResponses(response.data.seller_request_response);
        })
        .catch(function (error) {
            console.log(error);
        })
    setFilterOpen(false)
}
export function fetchResponse(response_id, resetUpdate, setEditResponseData, setResponseError) {
    axios.get(process.env.REACT_APP_API_BASE_URL + '/api/v1/seller_response/' + response_id,
        { headers: {
                "Authorization" : `Bearer ${token}`
            }})
        .then(function (response) {
            resetUpdate();
            setEditResponseData(response.data.seller_request_response);
            setResponseError(false);
        })
        .catch(function (error) {
            console.log(error);
        })
}

export function getResponseData(sellerResponseId, setSellerResponseViewData) {
    axios.get(process.env.REACT_APP_API_BASE_URL + '/api/v1/seller_response_all/' + sellerResponseId,
        { headers: {
                "Authorization" : `Bearer ${token}`
            }})
        .then(function (response) {
            setSellerResponseViewData(response.data.seller_request_response);
        })
        .catch(function (error) {
            console.log(error);
        })
}

export function saveNewResponse(data, setResponseError, files, setIsLoading, setError, setOpenResponseModal,
                                fetchData) {
    if (files) {
        data['file_count'] = files.length;
    } else {
        data['file_count'] = 0;
    }

    if (data['status_id'] === '') {setResponseError("Status must be filled out"); return; }
    axios.post(process.env.REACT_APP_API_BASE_URL + '/api/v1/seller_response', data,
        { headers: {
                "Authorization" : `Bearer ${token}`
            }})
        .then(data => {
            setResponseError(false);
            const response_id = data.data.seller_request_response.id;

            files.map((file,i)=> {
                setIsLoading(true);
                const fileName = file.name;
                axios.post(process.env.REACT_APP_API_BASE_URL + '/api/docstorage/v1/'+ fileName +"/"+response_id,
                    { "file":file},
                    { headers: {
                            'Content-Type': 'multipart/form-data',
                            "Authorization" : `Bearer ${token}`
                        }}
                ).then(data => {
                    setIsLoading(false);
                })
                    .catch(function (error) {
                        if (error.response) {
                            // The request was made and the server responded with a status code
                            // that falls out of the range of 2xx
                            if (error.response.data.detail) {
                                setError(error.response.data.detail);
                            } else if (error.response.data.msg) {
                                setError(error.response.data.msg);
                            } else {
                                setError("Unknown Error Occurred");
                            }
                        } else if (error.request) {
                            console.log(error.request);
                        } else {
                            // Something happened in setting up the request that triggered an Error
                            //setError(error.message);
                            console.log('Error', error.message);
                        }
                        console.log(error.config);
                    });
                return true;
            })
            setIsLoading(false);
            setOpenResponseModal(false);
            fetchData();
        }).catch(function (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
        } else if (error.request) {
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
        }
        console.log(error.config);
    });
}

export function updateResponse(data, setResponseError, files, setIsLoading, setOpenResponseModal,
                               handleSellersListFetch, setUpdateListData, setError) {
    if (data['response_details'] === '') {setResponseError("Details must be filled out"); return;}
    axios.put(process.env.REACT_APP_API_BASE_URL + '/api/v1/seller_response/'+ data['id'], data,
        { headers: {
                "Authorization" : `Bearer ${token}`
            }})
        .then(data => {
            setResponseError(false);
            const response_id = data.data.seller_request_response.id;
            files.map((file,i)=> {
                setIsLoading(true);
                const fileName = file.name;

                axios.post(process.env.REACT_APP_API_BASE_URL + '/api/docstorage/v1/'+ fileName +"/"+response_id,
                    { "file":file},
                    { headers: {
                            'Content-Type': 'multipart/form-data',
                            "Authorization" : `Bearer ${token}`
                        }}
                ).then(data => {
                    setIsLoading(false);
                    setUpdateListData(true);
                })
                    .catch(function (error) {
                        if (error.response) {
                            // The request was made and the server responded with a status code
                            // that falls out of the range of 2xx
                            if (error.response.data.detail) {
                                setError(error.response.data.detail);
                            } else if (error.response.data.msg) {
                                setError(error.response.data.msg);
                            } else {
                                setError("Unknown Error Occurred");
                            }
                        } else if (error.request) {
                            console.log(error.request);
                        } else {
                            // Something happened in setting up the request that triggered an Error
                            //setError(error.message);
                            console.log('Error', error.message);
                        }
                        console.log(error.config);
                    });
            })
            setIsLoading(false);
            setOpenResponseModal(false);
            handleSellersListFetch();
        }).catch(function (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
        } else if (error.request) {
            console.log(error.request);
        } else {
            // Something happened in setting up the request that triggered an Error
            console.log('Error', error.message);
        }
        console.log(error.config);
    });
}

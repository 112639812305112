import {React, useEffect, useState} from "react";
import Modal from "react-modal";
import {set, useForm} from "react-hook-form";
import './buyerList.css';
import FileUploader from "../../components/FileUploader/FileUploader";
import {downloadFile, fetchDocuments} from "../../Functions/Files";
import {getResponseData} from "../../Functions/Responses";
import {customStyles, DATE_RANGE, DATE_RANGE_HELPER_TEXT, statusSelect} from "../../Constants";
import {deleteBuyersList, fetchBuyersList, fetchBuyerRequest} from "../../Functions/BuyersList";
import { saveNewResponse, updateResponse } from "../../Functions/Responses";
import ResponseViewModal from "../../components/Modals/ResponseViewModal";
import {fetchSellerRequest} from "../../Functions/SellersList";
import DeleteConfirmation from "../../components/Modals/DeleteConfirmation";
import NewBuyerRequest from "../../components/Modals/NewBuyerRequest";
import {ReopenModal} from "./ReopenModal";

export default function BuyersList() {
    const { register, handleSubmit , reset,setValue} = useForm();
    const { register:registerResponse, handleSubmit:handleSubmitResponse , reset:resetResponse} = useForm();
    const { register: registerUpdate, handleSubmit: handleSubmitUpdate , reset:resetUpdate} = useForm();
    const[action, setAction] = useState();
    const[buyersListRowData, setBuyersListRowData] = useState([]);
    const[buyerRequest, setBuyerRequest] = useState([]);
    const[deleteConfirmationModalOpen, setdeleteConfirmationModalOpen] = useState();
    const[editResponseData, ] = useState([]);
    const[error, setError] = useState();
    const[filterOpen, setFilterOpen] = useState(false);
    const[files, setFiles] = useState([]);
    const[isLoading, setIsLoading] = useState(false);
    const[modalIsOpen, setIsOpen] = useState(false);
    const[projectId, setProjectId] = useState(localStorage.getItem('projectId'));
    const[requestAction, setRequestAction] = useState();
    const[responseError, setResponseError] = useState();
    const[ResponseViewModalOpen, setOpenViewResponseModal] = useState(false);;
    const[ResponseModalOpen, setOpenResponseModal] = useState(false);
    const[reopenModal, setReopenModal] = useState(false);
    const[reopenModalSellerRequestId, setReopenModalSellerRequestId] = useState(false);
    const[sellerRequest, setSellerRequest] = useState([]);
    const[sellerResponseRequestId, setSellerResponseRequestId] = useState('');
    const[sellerResponseViewData, setSellerResponseViewData] = useState([]);
    const[sellerResponseId, setSellerResponseId] = useState();
    const[sortOpen, setSortOpen] = useState();
    const[updateListData, setUpdateListData] = useState(false);
    const [popup, setPopup] = useState({
        show: false, // initial values set to false and null
        id: null,
    });

    const[buyerFilteredData, setBuyerFilteredData] = useState({
        buyer_request_list_id: "",
        category: "",
        date_range: "",
        details: "",
        entity_id: "",
        last_response: "",
        linkedRequests: "",
        phase: "",
        priority: "",
        requestId: "",
        request_name: "",
        seller_request_id: "",
        seller_request_status: "",
    });

    const[buyerListSorting, setBuyerListSorting] = useState({
        sort_one: "",
        sort_one_direction: "asc",
        sort_two: "",
        sort_two_direction:"asc",
        sort_three: "",
        sort_three_direction:"asc",
        sort_four: "",
        sort_four_direction:"asc",
        sort_five: "",
        sort_five_direction:"asc",
        sort_six: "",
        sort_six_direction:"asc",
        sort_seven: "",
        sort_seven_direction:"asc",
        sort_eight: "",
        sort_eight_direction:"asc",
        sort_nine: "",
        sort_nine_direction:"asc",
        sort_ten: "",
        sort_ten_direction:"asc",
        sort_eleven: "",
        sort_eleven_direction:"asc",
        sort_twelve: "",
        sort_twelve_direction:"asc",
    });

    const sort_array = [
        {id: "buyer_request_list_id", name: "Request ID"},
        {id: "time_created", name:"Created"},
        {id: "priority", name:"Priority"},
        {id: "category", name:"Category"},
        {id: "request_name", name:"Request Name"},
        {id: "date_range", name:DATE_RANGE},
        {id: "details", name:"Request Details"},
        {id: "file_type.file_type", name:"Preferred File Type"},
        {id: "seller_request_id", name:"Seller Request ID"},
        {id: "seller_request.batch", name:"Phase"},
        {id: "assigned_date", name:"Released"},
        {id: "status.status", name:"Seller Request Status"},
    ];

    window.addEventListener('projectId', () => {
        if (localStorage.getItem('projectId') === 'Select A Project') {
            localStorage.removeItem('projectId');
        } else {
            setProjectId(localStorage.getItem('projectId'));
        }
        setUpdateListData(true);
        reset();
        resetResponse();
    })

    const handleBuyersFetchClick = (e) => {
        setError(null);
        if (projectId === null) {
            setBuyersListRowData([]);
            setUpdateListData(true);
            setError('Please select a project');
            return;
        }
        fetchBuyersList(projectId, buyerFilteredData, setBuyersListRowData, setFilterOpen, buyerListSorting, setSortOpen);
        setUpdateListData(false);
    };

    useEffect(handleBuyersFetchClick, [updateListData, projectId]);

    function clearFilter() {
        setBuyerFilteredData( {
            buyer_request_list_id: "",
            category: "",
            date_range: "",
            details: "",
            entity_id: "",
            last_response: "",
            linkedRequests: "",
            phase: "",
            priority: "",
            requestId: "",
            request_name: "",
            seller_request_id: "",
            seller_request_status: "",
        });
        setUpdateListData(true);
        fetchBuyersList(projectId, buyerFilteredData, setBuyersListRowData, setFilterOpen, buyerListSorting, setSortOpen);
    }

    function clearSort() {
        setBuyerListSorting({
            sort_one: "",
            sort_one_direction: "asc",
            sort_two: "",
            sort_two_direction:"asc",
            sort_three: "",
            sort_three_direction:"asc",
            sort_four: "",
            sort_four_direction:"asc",
            sort_five: "",
            sort_five_direction:"asc",
            sort_six: "",
            sort_six_direction:"asc",
            sort_seven: "",
            sort_seven_direction:"asc",
            sort_eight: "",
            sort_eight_direction:"asc",
            sort_nine: "",
            sort_nine_direction:"asc",
            sort_ten: "",
            sort_ten_direction:"asc",
            sort_eleven: "",
            sort_eleven_direction:"asc",
            sort_twelve: "",
            sort_twelve_direction:"asc",
        });
        fetchBuyersList(projectId, buyerFilteredData, setBuyersListRowData, setFilterOpen, buyerListSorting, setSortOpen);
        setUpdateListData(true);
    }

    function createNewBuyerRequest() {
        setBuyerRequest([]);
        reset();
        resetResponse();
        setRequestAction("POST");
        setIsOpen(true);
    }

    function editBuyerRequest(buyerRequestId, action) {
        setRequestAction(action);
        setIsOpen(true);
        fetchBuyerRequest(buyerRequestId, setBuyerRequest);
    }

    const openFilter = () => {
        setFilterOpen(!filterOpen);
    };

    const openSort = () => { setSortOpen(!sortOpen); };

    function openViewResponsesModal(sellerResponseRequestId) {
        setSellerResponseId(sellerResponseRequestId);
        fetchSellerRequest(sellerResponseRequestId, setSellerRequest);
        getResponseData(sellerResponseRequestId, setSellerResponseViewData);
        setOpenViewResponseModal(true);
    }

    function openResponseModal(seller_request_id) {
        reset();
        setAction("POST");
        fetchSellerRequest(seller_request_id, setSellerRequest);
        getResponseData(seller_request_id, setSellerResponseViewData);
        setOpenViewResponseModal(false);
        setSellerResponseRequestId(seller_request_id);
        setOpenResponseModal(!ResponseModalOpen);
    }

    function reopen(sellerRequestId) {
        setReopenModal(true);
        setReopenModalSellerRequestId(sellerRequestId);
    }

    function saveResponse(data) {
        saveNewResponse(data, setResponseError, files, setIsLoading, setError, setOpenResponseModal, fetchBuyersList);
        fetchBuyersList(projectId, buyerFilteredData, setBuyersListRowData, setFilterOpen, buyerListSorting, setSortOpen);
    }

    const handleDelete = (id) => {
        setdeleteConfirmationModalOpen(true)
        setPopup({
            show: true,
            id,
        });
    };

    const handleDeleteTrue = () => {
        if (popup.show && popup.id) {
            deleteBuyersList(popup.id, projectId, buyerFilteredData, setBuyersListRowData, setFilterOpen)
            setPopup({
                show: false,
                id: null,
            });
        }
    };

    const handleDeleteFalse = () => {
        setPopup({
            show: false,
            id: null,
        });
    };

    return (
        <div>
            <ReopenModal
                open={reopenModal}
                setOpen={setReopenModal}
                sellerRequestid={reopenModalSellerRequestId}
            />

            <Modal
                isOpen={ResponseModalOpen}
                onRequestClose={() => setOpenResponseModal(false)}
                style={customStyles}
                contentLabel="Seller Request Response"
                ariaHideApp={false}
            >
                <h2>Seller Request Response</h2>
                <button onClick={() => setOpenResponseModal(false)}>close</button>
                {isLoading && <h1>Loading....</h1>}
                {responseError && <h1 className="userError">{responseError}</h1>}
                {sellerRequest &&
                    <>
                        <p><b>Data Requested:</b> {sellerRequest.data_requested}</p>
                        <p><b>Request Parameters:</b> {sellerRequest.parameters}</p>
                        <p><b>Details:</b> {sellerRequest.details}</p>
                    </>
                }
                {action === "POST" &&
                    <form onSubmit={handleSubmit(data => saveResponse(data))}>
                        <input name="response_type" defaultValue="buyer" {...register('response_type')} hidden />
                        <input name="approved" defaultValue={false} {...register('approved')} hidden />
                        <input name="seller_request_id" defaultValue={sellerResponseRequestId} {...register('seller_request_id')} hidden />
                        <input name="response_id" defaultValue={sellerResponseId} {...register('response_id')} hidden />

                        <label>Details <br /><em>Any pertinent information that might be needed</em></label>
                        <input name="response_details" {...register('response_details')} />

                        <FileUploader onFilesSelected={setFiles} width="300px" height='400px'/>

                        <input type="submit" value="Save Response" className="saveNewSellerRequestButton"/>
                    </form>
                }

                {action === "PUT" &&
                    <form onSubmit={handleSubmitUpdate(data => updateResponse(data,
                        setResponseError, files, setIsLoading, setOpenResponseModal, fetchBuyersList,
                        setUpdateListData, setError))}>
                        <input name="id" defaultValue={editResponseData.id} {...registerUpdate('id')} hidden />
                        <label>Request Status</label>
                        <select name="status" {...registerUpdate('status')}>
                            {statusSelect.map((status,i) => {
                                return (
                                    <option value={status.name}>{status.name}</option>
                                )
                            })}
                        </select>

                        <label>Details <br /><em>Any pertinent information that might be needed</em></label>
                        <input name="response_details" defaultValue={editResponseData.response_details}  {...registerUpdate('response_details')} />

                        <FileUploader onFilesSelected={setFiles} width="300px" height='400px'/>

                        <input type="submit" value="Save Response" className="saveNewSellerRequestButton"/>
                    </form>
                }
            </Modal>

            <h1>Manage Buyer's List</h1>
            {error && <h1 className="userError">{error}</h1>}

            <NewBuyerRequest
                setIsOpen={setIsOpen}
                requestAction={requestAction}
                buyerRequest={buyerRequest}
                setBuyerRequest={setBuyerRequest}
                projectId={projectId}
                buyerFilteredData={buyerFilteredData}
                setBuyersListRowData={setBuyersListRowData}
                setFilterOpen={setFilterOpen}
                modalIsOpen={modalIsOpen}
            ></NewBuyerRequest>

            <ResponseViewModal
                ResponseViewModalOpen={ResponseViewModalOpen}
                setOpenViewResponseModal={setOpenViewResponseModal}
                sellerResponseViewData={sellerResponseViewData}
                handleSubmit={handleSubmit}
                downloadFile={downloadFile}
                openResponseModal={openResponseModal}
                sellerRequest={sellerRequest}
            />

            {popup.show && (
                <DeleteConfirmation
                    handleDeleteTrue={handleDeleteTrue}
                    handleDeleteFalse={handleDeleteFalse}
                    deleteConfirmationModalOpen={deleteConfirmationModalOpen}
                    setdeleteConfirmationModalOpen={setdeleteConfirmationModalOpen}
                />
            )}
            {projectId && <button className="newBuyersRequestButton" onClick={() => createNewBuyerRequest()} >
                New Buyer Request
            </button>}
            <br/>

            <div>
                <button onClick={openFilter}>Filter</button>
                <button onClick={()=>clearFilter()}>Clear Filter</button>
                <button onClick={openSort}>Sort</button>
                <button onClick={()=>clearSort()}>Clear Sort</button>
                {filterOpen ? (
                    <ul className="menu">
                        <li className="menu-item">
                            Linked <br />
                            <select name="filterlinkedRequests" defaultValue={buyerFilteredData.linkedRequests} onChange={(e) =>
                                setBuyerFilteredData( {...buyerFilteredData, linkedRequests: e.target.value})}>
                                <option value="all">All</option>
                                <option value="unlinked">Unlinked</option>
                                <option value="linked">Linked</option>
                            </select>
                        </li>
                        <li className="menu-item">
                            Request ID
                            <input type="text" defaultValue={buyerFilteredData.buyer_request_list_id} name="buyer_request_list_id" placeholder="Request ID"
                                   onChange={(e) =>
                                       setBuyerFilteredData( {...buyerFilteredData, buyer_request_list_id: e.target.value})}>
                            </input>
                        </li>
                        <li className="menu-item">
                            Priority <br />
                            <select  name="filterPriority" defaultValue={buyerFilteredData.priority}
                                     onChange={(e) =>
                                         setBuyerFilteredData( {...buyerFilteredData, priority: e.target.value})}>
                                <option value="">Filter By Priority</option>
                                <option value="Low">Low</option>
                                <option value="Medium">Medium</option>
                                <option value="High">High</option>
                            </select>
                        </li>
                        <li className="menu-item">
                            Category
                            <input type="text" name="filterCategory" defaultValue={buyerFilteredData.category} placeholder="Category"
                                   onChange={(e) =>
                                       setBuyerFilteredData( {...buyerFilteredData, category: e.target.value})}>
                            </input>
                        </li>
                        <li className="menu-item">
                            Request Name
                            <input type="text" name="filterRequestName" defaultValue={buyerFilteredData.request_name} placeholder="Request Name"
                                   onChange={(e) =>
                                       setBuyerFilteredData( {...buyerFilteredData, request_name: e.target.value})}>
                            </input>
                        </li>
                        <li className="menu-item">
                            Request Details
                            <input type="text" defaultValue={buyerFilteredData.details} name="filterRequestId" placeholder="Details"
                                   onChange={(e) =>
                                       setBuyerFilteredData( {...buyerFilteredData, details: e.target.value})}>
                            </input>
                        </li>
                        <li className="menu-item">
                            Seller Request ID
                            <input type="text" defaultValue={buyerFilteredData.seller_request_id} name="filterRequestId" placeholder="Seller Request ID"
                                   onChange={(e) =>
                                       setBuyerFilteredData( {...buyerFilteredData, seller_request_id: e.target.value})}>
                            </input>
                        </li>


                        {/*// , Phase, Seller Request Status, and Last Response.*/}
                        <li>
                            <button onClick={handleBuyersFetchClick}>Apply</button>
                        </li>
                    </ul>
                ) : null}
            </div>

            {sortOpen ? (
                <ul className="menu">
                    <li className="menu-item">
                        Sort By
                        <select name="id" defaultValue={buyerListSorting.sort_one}
                                onChange={(e) =>
                                    setBuyerListSorting({...buyerListSorting, sort_one: e.target.value})}>
                            <option value={0}>Sort Column</option>
                            {sort_array.map((sort,i)=> {
                                return (
                                    <option key={sort.id} value={sort.id}>{sort.name}</option>
                                )
                            })}
                        </select>

                        <select name="sort_one_direction" defaultValue={buyerListSorting.sort_one_direction}
                                onChange={(e) =>
                                    setBuyerListSorting({...buyerListSorting, sort_one_direction: e.target.value})}>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </select>
                    </li>

                    <li className="menu-item">
                        Then By
                        <select name="sort_two" defaultValue={buyerListSorting.sort_two}
                                onChange={(e) =>
                                    setBuyerListSorting({...buyerListSorting, sort_two: e.target.value})}>
                            <option value={0}>Sort Column</option>
                            {sort_array.map((sort,i)=> {
                                return (
                                    <option key={sort.id} value={sort.id}>{sort.name}</option>
                                )
                            })}
                        </select>
                        <select name="sort_two_direction" defaultValue={buyerListSorting.sort_two_direction}
                                onChange={(e) =>
                                    setBuyerListSorting({...buyerListSorting, sort_two_direction: e.target.value})}>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </select>
                    </li>
                    <li className="menu-item">
                        Then By
                        <select name="sort_three" defaultValue={buyerListSorting.sort_three}
                                onChange={(e) =>
                                    setBuyerListSorting({...buyerListSorting, sort_three: e.target.value})}>
                            <option value={0}>Sort Column</option>
                            {sort_array.map((sort,i)=> {
                                return (
                                    <option key={sort.id} value={sort.id}>{sort.name}</option>
                                )
                            })}
                        </select>
                        <select name="sort_three_direction" defaultValue={buyerListSorting.sort_three_direction}
                                onChange={(e) =>
                                    setBuyerListSorting({...buyerListSorting, sort_three_direction: e.target.value})}>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </select>
                    </li>

                    <li className="menu-item">
                        Then By
                        <select name="sort_four" defaultValue={buyerListSorting.sort_four}
                                onChange={(e) =>
                                    setBuyerListSorting({...buyerListSorting, sort_four: e.target.value})}>
                            <option value={0}>Sort Column</option>
                            {sort_array.map((sort,i)=> {
                                return (
                                    <option key={sort.id} value={sort.id}>{sort.name}</option>
                                )
                            })}
                        </select>
                        <select name="sort_four_direction" defaultValue={buyerListSorting.sort_four_direction}
                                onChange={(e) =>
                                    setBuyerListSorting({...buyerListSorting, sort_four_direction: e.target.value})}>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </select>
                    </li>

                    <li className="menu-item">
                        Then By
                        <select name="sort_five" defaultValue={buyerListSorting.sort_five}
                                onChange={(e) =>
                                    setBuyerListSorting({...buyerListSorting, sort_five: e.target.value})}>
                            <option value={0}>Sort Column</option>
                            {sort_array.map((sort,i)=> {
                                return (
                                    <option key={sort.id} value={sort.id}>{sort.name}</option>
                                )
                            })}
                        </select>
                        <select name="sort_five_direction" defaultValue={buyerListSorting.sort_five_direction}
                                onChange={(e) =>
                                    setBuyerListSorting({...buyerListSorting, sort_five_direction: e.target.value})}>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </select>
                    </li>

                    <li className="menu-item">
                        Then By
                        <select name="sort_six" defaultValue={buyerListSorting.sort_six}
                                onChange={(e) =>
                                    setBuyerListSorting({...buyerListSorting, sort_six: e.target.value})}>
                            <option value={0}>Sort Column</option>
                            {sort_array.map((sort,i)=> {
                                return (
                                    <option key={sort.id} value={sort.id}>{sort.name}</option>
                                )
                            })}
                        </select>
                        <select name="sort_six_direction" defaultValue={buyerListSorting.sort_six_direction}
                                onChange={(e) =>
                                    setBuyerListSorting({...buyerListSorting, sort_six_direction: e.target.value})}>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </select>
                    </li>
                    <li className="menu-item">
                        Then By
                        <select name="sort_seven" defaultValue={buyerListSorting.sort_seven}
                                onChange={(e) =>
                                    setBuyerListSorting({...buyerListSorting, sort_seven: e.target.value})}>
                            <option value={0}>Sort Column</option>
                            {sort_array.map((sort,i)=> {
                                return (
                                    <option key={sort.id} value={sort.id}>{sort.name}</option>
                                )
                            })}
                        </select>
                        <select name="sort_seven_direction" defaultValue={buyerListSorting.sort_seven_direction}
                                onChange={(e) =>
                                    setBuyerListSorting({...buyerListSorting, sort_seven_direction: e.target.value})}>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </select>
                    </li>
                    <li className="menu-item">
                        Then By
                        <select name="sort_eight" defaultValue={buyerListSorting.sort_eight}
                                onChange={(e) =>
                                    setBuyerListSorting({...buyerListSorting, sort_eight: e.target.value})}>
                            <option value={0}>Sort Column</option>
                            {sort_array.map((sort,i)=> {
                                return (
                                    <option key={sort.id} value={sort.id}>{sort.name}</option>
                                )
                            })}
                        </select>
                        <select name="sort_eight_direction" defaultValue={buyerListSorting.sort_eight_direction}
                                onChange={(e) =>
                                    setBuyerListSorting({...buyerListSorting, sort_eight_direction: e.target.value})}>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </select>
                    </li>
                    <li className="menu-item">
                        Then By
                        <select name="sort_nine" defaultValue={buyerListSorting.sort_nine}
                                onChange={(e) =>
                                    setBuyerListSorting({...buyerListSorting, sort_nine: e.target.value})}>
                            <option value={0}>Sort Column</option>
                            {sort_array.map((sort,i)=> {
                                return (
                                    <option key={sort.id} value={sort.id}>{sort.name}</option>
                                )
                            })}
                        </select>
                        <select name="sort_nine_direction" defaultValue={buyerListSorting.sort_nine_direction}
                                onChange={(e) =>
                                    setBuyerListSorting({...buyerListSorting, sort_nine_direction: e.target.value})}>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </select>
                    </li>
                    <li className="menu-item">
                        Then By
                        <select name="sort_ten" defaultValue={buyerListSorting.sort_ten}
                                onChange={(e) =>
                                    setBuyerListSorting({...buyerListSorting, sort_ten: e.target.value})}>
                            <option value={0}>Sort Column</option>
                            {sort_array.map((sort,i)=> {
                                return (
                                    <option key={sort.id} value={sort.id}>{sort.name}</option>
                                )
                            })}
                        </select>
                        <select name="sort_ten_direction" defaultValue={buyerListSorting.sort_ten_direction}
                                onChange={(e) =>
                                    setBuyerListSorting({...buyerListSorting, sort_ten_direction: e.target.value})}>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </select>
                    </li>
                    <li className="menu-item">
                        Then By
                        <select name="sort_eleven" defaultValue={buyerListSorting.sort_eleven}
                                onChange={(e) =>
                                    setBuyerListSorting({...buyerListSorting, sort_eleven: e.target.value})}>
                            <option value={0}>Sort Column</option>
                            {sort_array.map((sort,i)=> {
                                return (
                                    <option key={sort.id} value={sort.id}>{sort.name}</option>
                                )
                            })}
                        </select>
                        <select name="sort_eleven_direction" defaultValue={buyerListSorting.sort_eleven_direction}
                                onChange={(e) =>
                                    setBuyerListSorting({...buyerListSorting, sort_eleven_direction: e.target.value})}>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </select>
                    </li>
                    <li className="menu-item">
                        Then By
                        <select name="sort_twelve" defaultValue={buyerListSorting.sort_twelve}
                                onChange={(e) =>
                                    setBuyerListSorting({...buyerListSorting, sort_twelve: e.target.value})}>
                            <option value={0}>Sort Column</option>
                            {sort_array.map((sort,i)=> {
                                return (
                                    <option key={sort.id} value={sort.id}>{sort.name}</option>
                                )
                            })}
                        </select>
                        <select name="sort_twelve_direction" defaultValue={buyerListSorting.sort_twelve_direction}
                                onChange={(e) =>
                                    setBuyerListSorting({...buyerListSorting, sort_twelve_direction: e.target.value})}>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </select>
                    </li>

                    <li>
                        <button onClick={handleBuyersFetchClick}>Apply</button>
                    </li>
                </ul>
            ) : null}
            <table className="sellerListTable">
                <thead>
                <tr className="outline">
                    <th className="outline">Request ID</th>
                    <th className="outline">Priority</th>
                    <th className="outline">Category</th>
                    <th className="outline">Request Name</th>
                    <th className="outline">Request Details <br /><em>(Notes to explain request)</em></th>
                    <th className="outline">{DATE_RANGE}<br /><em>({DATE_RANGE_HELPER_TEXT})</em></th>
                    <th className="outline">Preferred File Type</th>
                    <th className="outline">Seller ID</th>
                    <th className="outline">Phase</th>
                    <th className="outline">Released</th>
                    <th className="outline">Seller Request Status</th>
                    <th className="outline">Last Response</th>
                    <th className="outline">Responses</th>
                    <th></th>
                </tr>
                {/* Responses (Button), , Edit (Button).*/}
                </thead>
                <tbody>
                {buyersListRowData.length > 0 && (buyersListRowData).map((buyerList,i)=>{
                    const assigned_date = buyerList.assigned_date == null ? 'Not Assigned' :  buyerList.assigned_date;
                    const showResponse = buyerList.seller_request_id != "" && buyerList.seller_request_status !== 'Closed';
                    const showDelete = buyerList.seller_request_id === "";
                    const showReopen = buyerList.seller_request_status === 'Closed';
                    let last_response_date = '';
                    if (buyerList.last_response !== null) {
                        last_response_date = new Date(buyerList.last_response.time_created).toLocaleString("en-US", {timeZone: "America/New_York"});
                    }
                    return (
                        <tr key={buyerList.id} className="outline">
                            <td className="outline">{buyerList.buyer_request_list_id}</td>
                            <td className="outline">{buyerList.priority}</td>
                            <td className="outline">{buyerList.category}</td>
                            <td className="outline">{buyerList.request_name}</td>
                            <td className="outline">{buyerList.details}</td>
                            <td className="outline">{buyerList.date_range}</td>
                            <td className="outline">{buyerList.file_type}</td>
                            <td className="outline">{buyerList.seller_request_id}</td>
                            <td className="outline">{buyerList.seller_request_phase}</td>
                            <td className="outline">{assigned_date}</td>
                            <td className="outline">{buyerList.seller_request_status}</td>
                            <td className="outline">{last_response_date}</td>

                            <td className="outline">
                                {buyerList.response_count > 0 &&
                                    <button onClick={() => { openViewResponsesModal(buyerList.request_id) }}>
                                        View {buyerList.response_count} Responses
                                    </button>
                                }
                            </td>
                            <td>
                                {showResponse && <button onClick={() => { openResponseModal(buyerList.id, '', "POST") }}>Respond</button>}
                                {showReopen && <button onClick={() => { reopen(buyerList.id) }}>Request Reopen</button>}
                                { showDelete
                                    &&
                                    <>
                                        <button onClick={() => { editBuyerRequest(buyerList.id, "PUT") }}>Edit</button>
                                        <button onClick={() => { handleDelete(buyerList.id) }}>Delete</button>
                                    </>

                                }
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </div>
    )
}
import {React, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {fetchUser, saveUserData} from "../../Functions/Users";
import {fetchEntityTypes} from "../../Functions/EntityTypes";

export default function Projects({setUpdateProject}) {
    const { register, handleSubmit , reset,setValue} = useForm();
    const action = 'PUT';
    const[error, setError] = useState();
    const[isOpen, setIsOpen] = useState();
    const[userRowData, setUserRowData] = useState();
    const[updateUserList, setUpdateUserList] = useState();
    const[user, setUser] = useState();
    const userId = localStorage.getItem("userId");

    useEffect(() => {
        fetchUser(userId, setUser);
    }, []);

    return <>
        <h1>Profile</h1>
        {error && <h1 className="userError">{error}</h1>}
        <form onSubmit={handleSubmit(data =>
            saveUserData(data, setError, setUpdateUserList, setIsOpen, reset, setUserRowData, action, setUser))
        }>

            {user && setValue('id', user.id)}
            {user && <input name="id" defaultValue={user.id} {...register('id')} hidden />}

            {user && setValue('active', user.active)}
            {user && <input name="active" defaultValue={user.active} {...register('active')} hidden />}

            {user && setValue('user_name', user.user_name)}
            {user && <input name="user_name" defaultValue={user.user_name} {...register('user_name')} disabled={true}/>}

            <label>First Name</label>
            {user && setValue('first_name', user.first_name)}
            {user && <input name="first_name" {...register('first_name')} /> }

            <label>Nickname</label>
            {user && setValue('nickname', user.nickname)}
            {user && <input name="nickname" {...register('nickname')} /> }

            <label>Last Name</label>
            {user && setValue('last_name', user.last_name)}
            {user && <input name="last_name" {...register('last_name')} /> }

            <label>Password</label>
            {user && setValue('credential', user.credential)}
            {user && <input type='password' name="credential" {...register('credential')} /> }

            <label>Confirm Password</label>
            {user && setValue('verify_credential', user.credential)}
            {user && <input type='password' name="verify_credential" {...register('verify_credential')} /> }

            <label>Entity</label>
            {user && setValue('entity_name', user.entity_name)}
            {user && <input name="entity_name" {...register('entity_name')}  disabled={true}/> }

            <input type="submit" value="Submit" className="saveNewBuyerRequestButton"/>
        </form>
    </>
}
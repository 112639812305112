import * as CONSTANTS from "../Constants";
import axios from "axios";

const token = localStorage.getItem("accessToken");

export function fetchEntities(setEntities, setUpdateEntityList, filteredData, setFilterOpen, sorting, setSortOpen) {
    let params = "";
    if (filteredData) {
        if (filteredData.active && filteredData.active != 'Select Active/Inactive') {params += "active="+filteredData.active; }
        if (filteredData.code) {params += "&code="+filteredData.code; }
        if (filteredData.entity_name) {params += "&entity_name="+filteredData.entity_name; }
        if (filteredData.entity_type_id && filteredData.entity_type_id != 'Entity Type') {params += "&entity_type_id="+filteredData.entity_type_id; }
        if (filteredData.entity_type_name) {params += "&entity_type_name="+filteredData.entity_type_name; }
    }

    if (sorting) {
        if (sorting.sort_one !== "" && sorting.sort_one !== "0") { params += "&sort="+sorting.sort_one +","+ sorting.sort_one_direction; }
        if (sorting.sort_two !== "" && sorting.sort_two !== "0") { params += "&sort="+sorting.sort_two +","+ sorting.sort_two_direction; }
        if (sorting.sort_three !== "" && sorting.sort_three !== "0") { params += "&sort="+sorting.sort_three +","+ sorting.sort_three_direction; }
        if (sorting.sort_four !== "" && sorting.sort_four !== "0") { params += "&sort="+sorting.sort_four +","+ sorting.sort_four_direction; }
    }

    axios.get(process.env.REACT_APP_API_BASE_URL + '/api/v1/entities?' + params,
        { headers: {
                "Authorization" : `Bearer ${token}`
            }})
        .then(function (response) {
            setEntities(response['data']['entities']);
            if(setUpdateEntityList) { setUpdateEntityList(true); }
            if(setFilterOpen) { setFilterOpen(false); }
            if(setSortOpen) { setSortOpen(false); }
        })
        .catch(function (error) {
            console.log(error);
        })
}

export function fetchEntity(id, setEntity, setError) {
    axios.get(process.env.REACT_APP_API_BASE_URL + '/api/v1/entities/' + id,
        { headers: {
                "Authorization" : `Bearer ${token}`
            }})
        .then(function (response) {
            setEntity(response.data.entity);
            setError(false);
        })
        .catch(function (error) {
            console.log(error);
        })
}

export function saveEntity({fetchEntities, setError, reset, setIsOpen, data, setEntityRowData, setUpdateEntityList, action, filteredData}) {
    if (data['code'] === "") { setError(CONSTANTS.CODE_ERROR_MESSAGE); return; }
    if (data['entity_name'] === "") { setError(CONSTANTS.NAME_ERROR); return; }
    if (isNaN(data['entity_type_id'])) { setError(CONSTANTS.INVALID_ENTITY_TYPE); return; }
    if (data['active'] === "Select Active/Inactive") { setError(CONSTANTS.ACTIVE_ERROR_MESSAGE); return; }
    data.entity_type_id = Number(data.entity_type_id);

    if (action === 'POST') {
        data.active = data.active === 'true';
        axios.post(process.env.REACT_APP_API_BASE_URL + '/api/v1/entities', data,
            { headers: {
                    "Authorization" : `Bearer ${token}`
                }})
            .then(data => {
                fetchEntities(setEntityRowData, setUpdateEntityList, filteredData);
                setError(false);
                reset();
                setIsOpen(false);
            })
            .catch(function (error) {
                if (error.response) {
                    setError(error.response.data.detail);
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
            });
    } else if (action === 'PUT') {
        axios.put(process.env.REACT_APP_API_BASE_URL + '/api/v1/entities/'+ data.id, data,
            { headers: {
                    "Authorization" : `Bearer ${token}`
                }})
            .then(response => {
                fetchEntities(setEntityRowData, setUpdateEntityList, filteredData);
                setError(false);
                reset();
                setIsOpen(false);
            })
            .catch(function (error) {
                if (error.response) {
                    setError(error.response.data.detail);
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
            });
    }
}
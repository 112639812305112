import {React, useEffect, useState} from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import './workstreamCategoryManagment.css';
import "react-color-palette/css";
import axios from "axios";
import * as CONSTANTS from '../../Constants';

export default function WorkstreamCategoryManagement() {
    const { register, handleSubmit } = useForm();
    const[ownerRowData, setOwnerRowData] = useState([]);
    const[error, setError] = useState();
    const navigate = useNavigate();

    function saveData(data) {
        data.project_id = localStorage.getItem('projectId');
        const token = localStorage.getItem("accessToken");
        if (data['project_id'] === 0) { setError(CONSTANTS.PROJECT_ERROR_MESSAGE); return;}
        if (data['workstream_category_code'] === '') { setError("You must type a Workstream Category Code"); return;}
        if (data['workstream_category_description'] === '') { setError("You must type a workstream category description"); return;}
        if (isNaN(data['owner_account_id'])) { setError(CONSTANTS.OWNER_ERROR_MESSAGE); return;}
        axios.post(process.env.REACT_APP_API_BASE_URL + '/api/v1/workstream_categories', data,
            { headers: {
                    "Authorization" : `Bearer ${token}`
                }})
            .then(function (response) {
                navigate("/workstreamManagement", {state: {
                    workstream_category_id: response.data.workstream_category.id,
                        deliverable_code: response.data.workstream_category.workstream_category_code
                    }});
            })
            .catch(function (error) {
                setError((error));
                console.log(error);
            })
    }

    useEffect( () => {
       fetchOwners();
    }, []);

    function fetchOwners() {
        const token = localStorage.getItem("accessToken");
        axios.get(process.env.REACT_APP_API_BASE_URL + '/api/v1/users',
            { headers: {
                    "Authorization" : `Bearer ${token}`
                }})
            .then(function (response) {
                console.log(response['data']['users']);
                setOwnerRowData(response['data']['users']);
            })
            .catch(function (error) {
                setError((error));
                console.log(error);
            })
    }

    return <>
        <h1>Project Management</h1>
        {error && <h1 className="userError">{error}</h1>}
        <form onSubmit={handleSubmit(data => saveData(data))}>
            <h1>Workstream Categories</h1>

            <label>Code</label>
            <input name="workstream_category_code" {...register('workstream_category_code')} />
            <label>Description</label>
            <input name="workstream_category_description" {...register('workstream_category_description')} />
            <label>Owner</label>
            <select name="owner_account_id" {...register('owner_account_id')} >
                <option> Select An Owner</option>
                {ownerRowData && ownerRowData.map((owner,i)=>{
                    return (
                        <option value={owner.id} key={owner.id}>{owner.full_name}</option>
                    )
                })}
            </select>
            <input type="submit" value="Save and Continue"/>
        </form>
    </>
}
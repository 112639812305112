import Modal from "react-modal";
import {React, useEffect, useState} from "react";
import {customStyles} from "../../Constants";
import {fetchThirdPartyAssignments, saveThirdPartyAssignments} from "../../Functions/ThirdPartyFunctions";
import {useForm} from "react-hook-form";
import {fetchEntities} from "../../Functions/EntityFunctions";
import {fetchProjects} from "../../Functions/ProjectsFunctions";
import {fetchEntityTypes} from "../../Functions/EntityTypes";
import fetchCategories from "../../Functions/CategoryFunctions";

function NewThirdPartyAssignmentModal({modalIsOpen, setIsOpen, action, setThirdPartyAssignments, thirdPartyAssignment, setThirdPartyAssignment}) {
    const { register, handleSubmit , reset, setValue} = useForm();
    const[categories, setCategories] = useState([]);
    const[entityFilteredData, setEntityFilteredData] = useState({code: "", name: "", active: "", entity_type_id:""});
    const[entityTypes, setEntityTypes] = useState([]);
    const[error, setError] = useState();
    const[filterOpen, setFilterOpen] = useState();
    const[projects, setProjects] = useState([]);
    const[thirdParties, setThirdParties] = useState([]);
    const[updateEntityList, setUpdateEntityList] = useState();
    const[sortOpen, setSortOpen] = useState();
    const[entityListSorting, setEntityListSorting] = useState({
        sort_one: "code",
        sort_one_direction: "asc",
        sort_two: "",
        sort_two_direction:"asc",
        sort_three: "",
        sort_three_direction:"asc",
        sort_four: "",
        sort_four_direction:"asc",
    });


    useEffect(() => {
        fetchEntityTypes(setEntityTypes);
        fetchProjects([], setProjects);
        }, [])
    useEffect(() => {fetchCategories(setCategories);}, []);

    useEffect(() => {
        fetchThirdPartyAssignments(setThirdPartyAssignments);
        const thirdPartyEntityId = entityTypes.filter(entityType => entityType['entity_type'] === 'Third Party');
        setEntityFilteredData( {...entityFilteredData, active: true})
        if (thirdPartyEntityId[0]) {
            setEntityFilteredData( {...entityFilteredData, entity_type_id: thirdPartyEntityId[0].id})
        }
        fetchEntities(setThirdParties, setUpdateEntityList, entityFilteredData, setFilterOpen, entityListSorting, setSortOpen);
    }, [updateEntityList, entityTypes]);

    const ThirdpartyAssignmentCheckboxes   = ({ category, thirdPartyAssignment }) => {
        const [checkedCategories, setCheckedCategories] = useState([]);

        useEffect(() => {
            if (thirdPartyAssignment && thirdPartyAssignment.category_id != null) {
                let initialCheckedCategories = thirdPartyAssignment.category_id;
                if (typeof initialCheckedCategories === 'string') {
                    // Parse the string to get an array
                    initialCheckedCategories = initialCheckedCategories.replace(/[{}]/g, '').split(',').map(Number);
                }
                setCheckedCategories(initialCheckedCategories);
            }
        }, [thirdPartyAssignment]);

        const handleCheckboxChange = (categoryId) => {
            setCheckedCategories((prevCheckedCategories) => {
                if (prevCheckedCategories.includes(categoryId)) {
                    return prevCheckedCategories.filter((id) => id !== categoryId);
                } else {
                    return [...prevCheckedCategories, categoryId];
                }
            });
        };

        return (
            <p>
                <input
                    key={category.id}
                    type="checkbox"
                    value={category.id}
                    style={{width: "revert"}}
                    {...register("category_id")}
                    checked={checkedCategories.includes(category.id)}
                    onChange={() => handleCheckboxChange(category.id)}
                />
                {category.category_name}
            </p>
        );
    };

    function closeModal() {
        setThirdPartyAssignment([]);
        reset();
        setIsOpen(false);
    }

    return (
        <Modal isOpen={modalIsOpen} style={customStyles} ariaHideApp={false}>
            {action === 'PUT' && <h2>Update Third Party</h2>}
            {action === 'POST' && <h2>Add Third Party</h2>}
            {error && <h1 className="userError">{error}</h1>}
            <button onClick={() => closeModal()}>close</button>
            <form onSubmit={handleSubmit(data =>
                saveThirdPartyAssignments(
                    {fetchThirdPartyAssignments, setError, reset, setIsOpen, data, action, setThirdPartyAssignments}))
            }>

                {action === 'PUT' && setValue('id', thirdPartyAssignment.id)}
                {action === 'PUT' && <input name="id" {...register('id')} hidden/>}

                {action === "POST" && <>
                    <label>Project</label>
                    <select name="project_id" {...register('project_id')} >
                        <option>Select A Project</option>
                        {projects && projects.map((project,i)=> {
                            return (
                                <option value={project.id}>{project.project_name}</option>
                            )
                        })}
                    </select>
                </>}

                {action === 'PUT' && <p>Third Party: {thirdPartyAssignment.name}</p>}
                {action === "POST" && <>
                    <label>Third Party</label>
                    <select name="third_party_id" {...register('third_party_id')} >
                        <option>Select A Third Party</option>
                        {thirdParties && thirdParties.map((thirdParty,i)=> {
                            return (
                                <option value={thirdParty.id}>{thirdParty.entity_name}</option>
                            )
                        })}
                    </select>
                </>}

                {setValue('active', thirdPartyAssignment.active)}
                <label>Project Assignment Status</label>
                <select name="active" {...register('active')} >
                    <option value={false}>Inactive</option>
                    <option value={true}>Active</option>
                </select>

                <label>Advisor Type</label>
                <select name="entity_type" {...register('entity_type')} >
                    <option value="buyer">Buyer Advisor</option>
                    <option value="seller">Seller Advisor</option>
                </select>

                {setValue('email_list', thirdPartyAssignment.email_list)}
                <label>Email List <em>Comma Separated List</em></label>
                <input name="email_list" {...register('email_list')} />

                <label>Categories<br /><em>For email purposes, leave blank for all emails</em></label>
                {categories && categories.map((category,i)=> {
                    return <ThirdpartyAssignmentCheckboxes category={category} thirdPartyAssignment={thirdPartyAssignment}/>
                })}

                {action === 'POST' && <input type="submit" value="Add Third Party" className="saveNewBuyerRequestButton"/>}
                {action === 'PUT' && <input type="submit" value="Update Third Party" className="saveNewBuyerRequestButton"/>}
            </form>
        </Modal>
    )
}

export default NewThirdPartyAssignmentModal;
import {React, useEffect, useState} from "react";
import {fetchDocuments} from "../../Functions/Files";
import {fetchUsers} from "../../Functions/Users";

export default function Docstorage() {
    const[csvData, setcsvData] = useState([]);
    const[documents, setDocuments] = useState([]);
    const[docstorageFilteredData, setDocstorageFilteredData] = useState({
        blob_name: "",
        blob_deleted: "",
        data_requested: "",
        seller_request_id: "",
        uploaded_by: "",
    });
    const[docstorageSorting, setDocstorageSorting] = useState({
        sort_one: "",
        sort_one_direction: "asc",
        sort_two: "",
        sort_two_direction:"asc",
        sort_three: "",
        sort_three_direction:"asc",
        sort_four: "",
        sort_four_direction:"asc",
        sort_five: "",
        sort_five_direction:"asc",
        sort_six: "",
        sort_six_direction:"asc",
    });
    const fileName = 'documents';
    const sort_array = [
        {id: "seller_request_list.seller_request_id", name: "Seller Request ID"},
        {id: "seller_request_list.data_requested", name:"Seller Request Name"},
        {id: "blob_name", name:"File Name"},
        {id: "first_name", name:"Uploaded By"},
        {id: "blob_creation_time", name:"Uploaded"},
        {id: "blob_deleted", name:"Deleted"},
    ];
    const[filterOpen, setFilterOpen] = useState(false);
    const[sortOpen, setSortOpen] = useState();
    const[projectId, setProjectId] = useState(localStorage.getItem('projectId'));
    const[users, setUsers] = useState([]);

    const openFilter = () => { setFilterOpen(!filterOpen); };
    const openSort = () => { setSortOpen(!sortOpen); };

    window.addEventListener('projectId', () => {
        if (localStorage.getItem('projectId') === 'Select A Project') {
            localStorage.removeItem('projectId');
            setDocuments([]);
        } else {
            setProjectId(localStorage.getItem('projectId'));
        }
    });

    useEffect(() => {
            fetchDocuments(projectId, setDocuments, docstorageFilteredData, docstorageSorting, setFilterOpen, setSortOpen);
    }, [projectId]);
    useEffect(() => fetchUsers(setUsers), [projectId]);


    const convertToCSV = (objArray) => {
        const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
        let str = 'Seller Request ID, Seller Request Name, File, Uploaded By, Uploaded, Deleted\r\n';
        let line = '';
        for (let i = 0; i < array.length; i++) {
            const doc = array[i]['document'];
            const seller_request = array[i]['seller_request'];
            const deleted = doc.blob_deleted == true ? "Deleted" : "";

            if (line !== '') line += ',';
            line = seller_request.seller_request_id+","+seller_request.data_requested + "," + doc.blob_name + "," +
                array[i].user_name + "," +doc.blob_creation_time;
            str += line + '\r\n';
        }
        return str;
    };

   const downloadCSV = () => {
        const csvData = new Blob([convertToCSV(documents)], { type: 'text/csv' });
        const csvURL = URL.createObjectURL(csvData);
        const link = document.createElement('a');
        link.href = csvURL;
        link.download = `${fileName}.csv`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };


    function clearFilter() {
        setDocstorageFilteredData({
            blob_name: "",
            blob_deleted: "",
            data_requested: "",
            seller_request_id: "",
            uploaded_by: "",
        });
        fetchDocuments(projectId, setDocuments, docstorageFilteredData, docstorageSorting, setFilterOpen, setSortOpen);
    }
    function clearSort() {
        setDocstorageSorting({
            sort_one: "",
            sort_one_direction: "asc",
            sort_two: "",
            sort_two_direction:"asc",
            sort_three: "",
            sort_three_direction:"asc",
            sort_four: "",
            sort_four_direction:"asc",
            sort_five: "",
            sort_five_direction:"asc",
            sort_six: "",
            sort_six_direction:"asc",
            sort_seven: "",
            sort_seven_direction:"asc",
        });
        fetchDocuments(projectId, setDocuments, docstorageFilteredData, docstorageSorting, setFilterOpen, setSortOpen);
    }

    function sortAndFilter() {
        fetchDocuments(projectId, setDocuments, docstorageFilteredData, docstorageSorting, setFilterOpen, setSortOpen);
    }

    return (<>
        <h2>Document Storage</h2>
        <div>
            <button onClick={openFilter}>Filter</button>
            <button onClick={()=>clearFilter()}>Clear Filter</button>
            <button onClick={openSort}>Sort</button>
            <button onClick={()=>clearSort()}>Clear Sort</button>
            <button onClick={downloadCSV}>Download CSV</button>
            {filterOpen ? (
                <ul className="menu">
                    <li className="menu-item">
                        Seller Request ID
                        <input type="text" placeholder="Seller Request ID" defaultValue={docstorageFilteredData.seller_request_id}
                               onChange={(e) =>
                                   setDocstorageFilteredData({...docstorageFilteredData, seller_request_id: e.target.value})}>
                        </input>
                    </li>
                    <li className="menu-item">
                        Seller Request Name
                        <input type="text" placeholder="Seller Request Name" defaultValue={docstorageFilteredData.data_requested}
                               onChange={(e) =>
                                   setDocstorageFilteredData({...docstorageFilteredData, data_requested: e.target.value})}>
                        </input>
                    </li>
                    <li className="menu-item">
                        File
                        <input type="text" placeholder="File" defaultValue={docstorageFilteredData.blob_name}
                               onChange={(e) =>
                                   setDocstorageFilteredData({...docstorageFilteredData, blob_name: e.target.value})}>
                        </input>
                    </li>
                    <li className="menu-item">
                        <label>Uploaded By</label>
                        <select name="uploaded_by" defaultValue={docstorageFilteredData.uploaded_by}
                                onChange={(e) =>
                                    setDocstorageFilteredData({...docstorageFilteredData, uploaded_by: e.target.value})}>
                            <option value="">Uploaded By</option>
                            {users && users.map((user,i)=> {
                                return (
                                    <option key={user.id} value={user.id}>{user.full_name}</option>
                                )
                            })}
                        </select>
                    </li>
                    <li className="menu-item">
                        <label>Deleted?</label>
                        <select name="status" defaultValue={docstorageFilteredData.blob_deleted}
                                onChange={(e) =>
                                    setDocstorageFilteredData({...docstorageFilteredData, blob_deleted: e.target.value})}>
                            <option value="">Filter By Deleted</option>
                            <option value={true}>True</option>
                            <option value={false}>False</option>
                        </select>
                    </li>
                    <li>
                        <button onClick={()=> sortAndFilter()}>Apply</button>
                    </li>
                </ul>
            ) : null}

            {sortOpen ? (
                <ul className="menu">
                    <li className="menu-item">
                        Sort By
                        <select name="id" defaultValue={docstorageSorting.sort_one}
                                onChange={(e) =>
                                    setDocstorageSorting({...docstorageSorting, sort_one: e.target.value})}>
                            <option value={0}>Sort Column</option>
                            {sort_array.map((sort,i)=> {
                                return (
                                   <option key={sort.id} value={sort.id}>{sort.name}</option>
                                )
                            })}
                        </select>

                        <select name="sort_one_direction" defaultValue={docstorageSorting.sort_one_direction}
                                onChange={(e) =>
                                    setDocstorageSorting({...docstorageSorting, sort_one_direction: e.target.value})}>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </select>
                    </li>

                    <li className="menu-item">
                        Then By
                        <select name="sort_two" defaultValue={docstorageSorting.sort_two}
                                onChange={(e) =>
                                    setDocstorageSorting({...docstorageSorting, sort_two: e.target.value})}>
                            <option value={0}>Sort Column</option>
                            {sort_array.map((sort,i)=> {
                                return (
                                    <option key={sort.id} value={sort.id}>{sort.name}</option>
                                )
                            })}
                        </select>
                        <select name="sort_two_direction" defaultValue={docstorageSorting.sort_two_direction}
                                onChange={(e) =>
                                    setDocstorageSorting({...docstorageSorting, sort_two_direction: e.target.value})}>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </select>
                    </li>
                    <li className="menu-item">
                        Then By
                        <select name="sort_three" defaultValue={docstorageSorting.sort_three}
                                onChange={(e) =>
                                    setDocstorageSorting({...docstorageSorting, sort_three: e.target.value})}>
                            <option value={0}>Sort Column</option>
                            {sort_array.map((sort,i)=> {
                                return (
                                    <option key={sort.id} value={sort.id}>{sort.name}</option>
                                )
                            })}
                        </select>
                        <select name="sort_three_direction" defaultValue={docstorageSorting.sort_three_direction}
                                onChange={(e) =>
                                    setDocstorageSorting({...docstorageSorting, sort_three_direction: e.target.value})}>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </select>
                    </li>

                    <li className="menu-item">
                        Then By
                        <select name="sort_four" defaultValue={docstorageSorting.sort_four}
                                onChange={(e) =>
                                    setDocstorageSorting({...docstorageSorting, sort_four: e.target.value})}>
                            <option value={0}>Sort Column</option>
                            {sort_array.map((sort,i)=> {
                                return (
                                    <option key={sort.id} value={sort.id}>{sort.name}</option>
                                )
                            })}
                        </select>
                        <select name="sort_four_direction" defaultValue={docstorageSorting.sort_four_direction}
                                onChange={(e) =>
                                    setDocstorageSorting({...docstorageSorting, sort_four_direction: e.target.value})}>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </select>
                    </li>

                    <li className="menu-item">
                        Then By
                        <select name="sort_five" defaultValue={docstorageSorting.sort_five}
                                onChange={(e) =>
                                    setDocstorageSorting({...docstorageSorting, sort_five: e.target.value})}>
                            <option value={0}>Sort Column</option>
                            {sort_array.map((sort,i)=> {
                                return (
                                    <option key={sort.id} value={sort.id}>{sort.name}</option>
                                )
                            })}
                        </select>
                        <select name="sort_five_direction" defaultValue={docstorageSorting.sort_five_direction}
                                onChange={(e) =>
                                    setDocstorageSorting({...docstorageSorting, sort_five_direction: e.target.value})}>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </select>
                    </li>

                    <li className="menu-item">
                        Then By
                        <select name="sort_six" defaultValue={docstorageSorting.sort_six}
                                onChange={(e) =>
                                    setDocstorageSorting({...docstorageSorting, sort_six: e.target.value})}>
                            <option value={0}>Sort Column</option>
                            {sort_array.map((sort,i)=> {
                                return (
                                    <option key={sort.id} value={sort.id}>{sort.name}</option>
                                )
                            })}
                        </select>
                        <select name="sort_six_direction" defaultValue={docstorageSorting.sort_six_direction}
                                onChange={(e) =>
                                    setDocstorageSorting({...docstorageSorting, sort_six_direction: e.target.value})}>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </select>
                    </li>
                    <li className="menu-item">
                        Then By
                        <select name="sort_seven" defaultValue={docstorageSorting.sort_seven}
                                onChange={(e) =>
                                    setDocstorageSorting({...docstorageSorting, sort_seven: e.target.value})}>
                            <option value={0}>Sort Column</option>
                            {sort_array.map((sort,i)=> {
                                return (
                                    <option key={sort.id} value={sort.id}>{sort.name}</option>
                                )
                            })}
                        </select>
                        <select name="sort_seven_direction" defaultValue={docstorageSorting.sort_seven_direction}
                                onChange={(e) =>
                                    setDocstorageSorting({...docstorageSorting, sort_seven_direction: e.target.value})}>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </select>
                    </li>

                    <li>
                        <button onClick={()=> sortAndFilter()}>Apply</button>
                    </li>
                </ul>
            ) : null}
        </div>
        <table className="sellerListTable">
        <thead>
        <tr className="outline">
            <th className="outline">Seller Request ID</th>
            <th className="outline">Seller Request Name</th>
            <th className="outline">File</th>
            <th className="outline">Uploaded By</th>
            <th className="outline">Uploaded</th>
            <th className="outline">Deleted</th>
        </tr>
        </thead>
        <tbody>
        {documents && documents.map((document,i)=>{
            const deleted = document.blob_deleted == true ? "Deleted" : "";
            const time_created = new Date(document.blob_creation_time).toLocaleString("en-US", {timeZone: "America/New_York"});

            return (
                <tr key={document.id} className="outline">
                    <td className="outline">{document.seller_request_id}</td>
                    <td className="outline">{document.data_requested}</td>
                    <td className="outline">{document.blob_name}</td>
                    <td className="outline">{document.user_name}</td>
                    <td className="outline">{time_created}</td>
                    <td className="outline">{document.deleted}</td>
                </tr>
            )
        })}
        </tbody>
    </table>
    </>)
}
import {React, useCallback, useEffect, useState,} from "react";
import axios from "axios";
import Modal from 'react-modal';
import { useForm } from "react-hook-form";
import './dataRequestmanagement.css';
import FileUploader from "../../components/FileUploader/FileUploader";
import {customStyles, FILE_TYPE_ERROR_MESSAGE, UNKNOWN_ERROR_MESSAGE} from "../../Constants";
import {downloadFile}  from "../../Functions/Files";
import {fetchResponse, getResponseData} from "../../Functions/Responses";
import ResponseViewModal from "../../components/Modals/ResponseViewModal";
import {fetchBuyersList} from "../../Functions/BuyersList";
import {fetchEntities} from "../../Functions/EntityFunctions";
import ImportSpreadsheetModal from "../../components/Modals/ImportSpreadsheetModal";
import fetchCategories from "../../Functions/CategoryFunctions";
import {fetchFileTypes} from "../../Functions/Files";
import {fetchSellerList, saveNewSellerData, updateSellerList} from "../../Functions/SellersList";
import {fetchStatuses} from "../../Functions/StatusFunction";


export default function DataRequestManagement() {
    const { register, handleSubmit , reset, setValue} = useForm();
    const { register:registerNewSellerData, handleSubmit:handleSubmitNewSellerData, reset:resetNewSellerData} = useForm();
    const { register: registerUpdate, handleSubmit: handleSubmitUpdate , reset:resetUpdate} = useForm();
    const[buyerRowData, setBuyerRowData] = useState([]);
    const[newSellerRequestError, setNewSellerRequestError] = useState();
    const[buyerFilteredData, setBuyerFilteredData] = useState({
        buyer_request_list_id: "",
        category: "",
        date_range: "",
        details: "",
        entity_id: "",
        last_response: "",
        linkedRequests: "unlinked",
        phase: "",
        priority: "",
        requestId: "",
        request_name: "",
        seller_request_id: "",
        seller_request_status: "",
    });
    const[sellerFilteredData, setSellerFilteredData] = useState({
        data_requested: "",
        parameters: "",
        details: "",
        status: "",
        batch: "",
        category_id: "",
    });
    const[clearData, setClearData] = useState();
    const[dataRequest, setDataRequest] = useState([]);
    const[entities, setEntityRowData] = useState([]);
    const[errorMessage, setErrorMessage] = useState();
    const[openSellerSort, setOpenSellerSort] = useState();

    const[importBuyerRequestModalOpen, setImportBuyerRequestModalOpen] = useState(false);
    const[linkConfirmationModalStatus, setLinkConfirmationModalStatus] = useState(false);
    const[modalIsOpen, setIsOpen] = useState(false);
    const[newRowToLink, setNewRowToLink] = useState();
    const[notification, setNotification] = useState();
    const[sellerRequest, setSellerRequest] = useState();
    const[sellerRequestId, setSellerRequestId] = useState();
    const[successMessage, setSuccessMessage] = useState();
    const[sellerSortOpen, setSellerSortOpen] = useState(false);
    const[projectId, setProjectId] = useState(localStorage.getItem('projectId'))
    const[sellerDataRequested, setSellerDataRequested] = useState("");
    const[sellerRequestParameters, setSellerRequestParameters] = useState("");
    const[sellerRequestDetails, setSellerRequestDetails] = useState("");
    const[sellerRequestFileType, setSellerRequestFileType] = useState("");
    const[sellerCategory, setSellerCategory] = useState("");
    const[action, setAction] = useState();
    const[buyerFilterOpen, setBuyerFilterOpen] = useState(false);
    const[sellerListRowData, setsellerListRowData] = useState([]);
    const[boxes, setBoxes] = useState({});
    const[dataRequestedInput, setDataRequestedInput] = useState("");
    const[deliverablePhaseInput, setDeliverablePhaseInput] = useState("");
    const[disableCheckboxes, setDisableCheckboxes] = useState(false);
    const[editResponseData, setEditResponseData] = useState([]);
    const[error, setError] = useState();
    const[responseError, setResponseError] = useState();
    const[files, setFiles] = useState([]);
    const[fileFormatInput, setFileFormatInput] = useState("");
    const[filterOpen, setFilterOpen] = useState(false);
    const[isLoading, setIsLoading] = useState(false);
    const[requestDescriptionInput, setRequestDescriptionInput] = useState("");
    const[requestDetailsInput, setRequestDetailsInput] = useState("");
    const[ResponseModalOpen, setOpenResponseModal] = useState(false);
    const[ResponseViewModalOpen, setOpenViewResponseModal] = useState(false);
    const[requestParametersInput, setRequestParametersInput] = useState("");
    const[sellerResponseRequestId, setSellerResponseRequestId] = useState('');
    const[sellerResponseViewData, setSellerResponseViewData] = useState([]);
    const[sellerReponseId, setSellerResponseId] = useState();
    const[buyerSortOpen, setBuyerSortOpen] = useState();
    const[statuses, setStatuses] = useState();
    const[filtered, setFiltered] = useState();
    const[statusFilter, setStatusFilter] = useState("");
    const[updateBuyerList, setUpdateBuyersList] = useState(false);
    const[updateSellerListData, setUpdateSellerListData] = useState(false);
    const[categories, setCategories] = useState();
    const[fileTypes, setFileTypes] = useState();
    const[buyerListSorting, setBuyerListSorting] = useState({
        sort_one: "",
        sort_one_direction: "asc",
        sort_two: "",
        sort_two_direction:"asc",
        sort_three: "",
        sort_three_direction:"asc",
        sort_four: "",
        sort_four_direction:"asc",
        sort_five: "",
        sort_five_direction:"asc",
        sort_six: "",
        sort_six_direction:"asc",
        sort_seven: "",
        sort_seven_direction:"asc",
        sort_eight: "",
        sort_eight_direction:"asc",
        sort_nine: "",
        sort_nine_direction:"asc",
        sort_ten: "",
        sort_ten_direction:"asc",
        sort_eleven: "",
        sort_eleven_direction:"asc",
        sort_twelve: "",
        sort_twelve_direction:"asc",
    });
    const buyer_sort_array = [
        {id: "buyer_request_list_id", name: "Request ID"},
        {id: "time_created", name:"Created"},
        {id: "priority", name:"Priority"},
        {id: "category", name:"Category"},
        {id: "request_name", name:"Request Name"},
        {id: "date_range", name:"Date Range"},
        {id: "details", name:"Request Details"},
        {id: "file_type.file_type", name:"Preferred File Type"},
        {id: "seller_request_id", name:"Seller Request ID"},
        {id: "seller_request.batch", name:"Phase"},
        {id: "assigned_date", name:"Released"},
        {id: "status.status", name:"Seller Request Status"},
    ];
    const[sellerListSorting, setSellerListSorting] = useState({
        sort_one: "",
        sort_one_direction: "asc",
        sort_two: "",
        sort_two_direction:"asc",
        sort_three: "",
        sort_three_direction:"asc",
        sort_four: "",
        sort_four_direction:"asc",
        sort_five: "",
        sort_five_direction:"asc",
        sort_six: "",
        sort_six_direction:"asc",
        sort_seven: "",
        sort_seven_direction:"asc",
        sort_eight: "",
        sort_eight_direction:"asc",
        sort_nine: "",
        sort_nine_direction:"asc",
    });
    const seller_sort_array = [
        {id: "seller_request_id", name: "Request ID"},
        {id: "category.category_name", name: "Category"},
        {id: "batch", name: "Phase"},
        {id: "assigned_date", name: "Requested On"},
        {id: "data_requested", name: "Request Name"},
        {id: "parameters", name: "Date Range"},
        {id: "details", name: "Request Details"},
        {id: "file_type.file_type", name: "Preferred File Type"},
        {id: "status.status", name: "Status"},
    ];

    const token = localStorage.getItem("accessToken");

    window.addEventListener('projectId', () => {
        if (localStorage.getItem('projectId') === 'Select A Project') {
            localStorage.removeItem('projectId');
        } else {
            setProjectId(localStorage.getItem('projectId'));
        }
        resetNewSellerData();
        reset();
    })
    const[,setUpdateEntityList] = useState();
    const[filteredData,] = useState([]);
    useEffect(() => fetchEntities(setEntityRowData, setUpdateEntityList, filteredData, setFilterOpen), [])
    useEffect(() => fetchCategories(setCategories), []);
    useEffect(() => fetchFileTypes(setFileTypes), []);
    useEffect(() => fetchStatuses(setStatuses), []);
    const openFilter = () => { setFilterOpen(!filterOpen); };

    function openResponseModal(seller_request_id, seller_response_id, action, data) {
        reset();
        resetUpdate();
        if (action === "PUT") {
            setAction("PUT");
            fetchResponse(seller_response_id, resetUpdate, setEditResponseData, setResponseError);
        } else {
            setAction("POST");
        }
        setSellerRequest(data);
        setOpenViewResponseModal(false);
        setSellerResponseRequestId(seller_request_id);
        setSellerResponseId(seller_response_id);
        setOpenResponseModal(!ResponseModalOpen);
    }

    function openViewResponsesModal(sellerResponseRequestid, data) {
        setSellerRequest(data);
        getResponseData(sellerResponseRequestid, setSellerResponseViewData);
        setOpenViewResponseModal(!ResponseViewModalOpen);
    }

    function clearBuyerFilter() {
        setBuyerFilteredData( {
            buyer_request_list_id: "",
            category: "",
            date_range: "",
            details: "",
            entity_id: "",
            last_response: "",
            linkedRequests: "",
            phase: "",
            priority: "",
            requestId: "",
            request_name: "",
            seller_request_id: "",
            seller_request_status: "",
        });
        handleBuyersFetch();
    }

    function clearBuyerSort() {
        setBuyerListSorting({
            sort_one: "",
            sort_one_direction: "asc",
            sort_two: "",
            sort_two_direction:"asc",
            sort_three: "",
            sort_three_direction:"asc",
            sort_four: "",
            sort_four_direction:"asc",
            sort_five: "",
            sort_five_direction:"asc",
            sort_six: "",
            sort_six_direction:"asc",
            sort_seven: "",
            sort_seven_direction:"asc",
            sort_eight: "",
            sort_eight_direction:"asc",
            sort_nine: "",
            sort_nine_direction:"asc",
            sort_ten: "",
            sort_ten_direction:"asc",
            sort_eleven: "",
            sort_eleven_direction:"asc",
            sort_twelve: "",
            sort_twelve_direction:"asc",
        });
        fetchBuyersList(projectId, buyerFilteredData, setBuyerRowData, setFilterOpen, buyerListSorting, setBuyerSortOpen);
    }

    function clearSellerSort() {
        setSellerListSorting({
            sort_one: "",
            sort_one_direction: "asc",
            sort_two: "",
            sort_two_direction:"asc",
            sort_three: "",
            sort_three_direction:"asc",
            sort_four: "",
            sort_four_direction:"asc",
            sort_five: "",
            sort_five_direction:"asc",
            sort_six: "",
            sort_six_direction:"asc",
            sort_seven: "",
            sort_seven_direction:"asc",
            sort_eight: "",
            sort_eight_direction:"asc",
            sort_nine: "",
            sort_nine_direction:"asc",
            sort_ten: "",
            sort_ten_direction:"asc",
            sort_eleven: "",
            sort_eleven_direction:"asc",
            sort_twelve: "",
            sort_twelve_direction:"asc",
        });
        fetchSellerList(projectId, sellerFilteredData, setsellerListRowData, setFilterOpen, sellerListSorting, setSellerSortOpen);
    }

    const handleBuyersFetch = () => {
        const projectId = localStorage.getItem('projectId');
        if (projectId === null) {
            setBuyerRowData([]);
        } else {
            fetchBuyersList(projectId, buyerFilteredData, setBuyerRowData, setFilterOpen, buyerListSorting, setBuyerSortOpen);
            setBuyerFilterOpen(false);
        }
    }

    useEffect(handleBuyersFetch, [projectId]);

    function clearSellerFilter() {
        setSellerFilteredData( {
            data_requested: "",
            parameters: "",
            details: "",
            status: "",
            batch: "",
            category_id: "",
        });
        handleSellersListFetch();
    }
    const handleSellersListFetch = (e) => {
        setError(null);
        if (projectId === null) {
            setsellerListRowData([]);
            setUpdateSellerListData(true);
            setError('Please select a project');
            return;
        }

        fetchSellerList(projectId, sellerFilteredData, setsellerListRowData, setFilterOpen, sellerListSorting, setSellerSortOpen)
    };

    useEffect(handleSellersListFetch, [setUpdateSellerListData, projectId]);

    function handleChange(e) {
        const {
            target: { id, checked }
        } = e;
        setBoxes({ ...boxes, [id]: checked });
    }

    function closeSellerRequestResponseModal() {
        setOpenResponseModal(false);
    }

    function closeResponseViewModal() {
        setOpenViewResponseModal(false);
    }

    function saveNewSellerResponseRequest(data) {
        if (data['status'] === '') {setResponseError("Status must be filled out"); return; }
        if (data['response_details'] === '') {setResponseError("Details must be filled out"); return;}
        if (data['response_type'] === '') {setResponseError(FILE_TYPE_ERROR_MESSAGE); return; }
        data['file_count'] = files.length;
        axios.post(process.env.REACT_APP_API_BASE_URL + '/api/v1/seller_response', data,
            { headers: {
                    "Authorization" : `Bearer ${token}`
                }})
            .then(data => {
                setResponseError(false);
                const response_id = data.data.seller_request_response.id;
                files.map((file,i)=> {
                    setIsLoading(true);
                    const fileName = file.name;

                    axios.post(process.env.REACT_APP_API_BASE_URL + '/api/docstorage/v1/'+ fileName +"/"+response_id,
                        { "file":file},
                        { headers: {
                                'Content-Type': 'multipart/form-data',
                                "Authorization" : `Bearer ${token}`
                            }}
                    ).then(data => {
                        setIsLoading(false);

                    })
                        .catch(function (error) {
                            if (error.response) {
                                // The request was made and the server responded with a status code
                                // that falls out of the range of 2xx
                                if (error.response.data.detail) {
                                    setError(error.response.data.detail);
                                } else if (error.response.data.msg) {
                                    setError(error.response.data.msg);
                                } else {
                                    setError("Unknown Error Occurred");
                                }
                            } else if (error.request) {
                                console.log(error.request);
                            } else {
                                // Something happened in setting up the request that triggered an Error
                                //setError(error.message);
                                console.log('Error', error.message);
                            }
                            console.log(error.config);
                        });
                    return true;
                });
                handleSellersListFetch()
                setIsLoading(false);
                setOpenResponseModal(false);
            }).catch(function (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
            console.log(error.config);
        });
    }

    function updateNewSellerResponseRequest(data) {
        if(data['id'] === '' || isNaN(data['id'])) { setResponseError(UNKNOWN_ERROR_MESSAGE); return; }
        if (data['status'] === '') {setResponseError("Status must be filled out"); return; }
        if (data['response_details'] === '') {setResponseError("Details must be filled out"); return;}
        if (data['response_type'] === '') {setResponseError(FILE_TYPE_ERROR_MESSAGE); return; }

        axios.put(process.env.REACT_APP_API_BASE_URL + '/api/v1/seller_response/'+ data['id'], data,
            { headers: {
                    "Authorization" : `Bearer ${token}`
                }})
            .then(data => {
                setResponseError(false);
                const response_id = data.data.seller_request_response.id;
                files.map((file,i)=> {
                    setIsLoading(true);
                    const fileName = file.name;

                    axios.post(process.env.REACT_APP_API_BASE_URL + '/api/docstorage/v1/'+ fileName +"/"+response_id,
                        { "file":file},
                        { headers: {
                                'Content-Type': 'multipart/form-data',
                                "Authorization" : `Bearer ${token}`
                            }}
                    ).then(data => {
                        setIsLoading(false);
                    })
                        .catch(function (error) {
                            if (error.response) {
                                // The request was made and the server responded with a status code
                                // that falls out of the range of 2xx
                                if (error.response.data.detail) {
                                    setError(error.response.data.detail);
                                } else if (error.response.data.msg) {
                                    setError(error.response.data.msg);
                                } else {
                                    setError("Unknown Error Occurred");
                                }
                            } else if (error.request) {
                                console.log(error.request);
                            } else {
                                // Something happened in setting up the request that triggered an Error
                                //setError(error.message);
                                console.log('Error', error.message);
                            }
                            console.log(error.config);
                        });
                    return true;
                })
                setIsLoading(false);
                setOpenResponseModal(false);
                handleSellersListFetch();
                resetUpdate();
                setErrorMessage(false);
            }).catch(function (error) {
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
            console.log(error.config);
        });
    }

    useEffect(()=>{
        setSuccessMessage();
        setClearData(false);
    }, [clearData]);

    function saveLink() {
        if (dataRequest.length === 0 || !sellerRequestId) {
            setErrorMessage('You need to select both a Data Row and Seller Row');
            return;
        }

        dataRequest.forEach((value) => {
            putBuyersRequest(value, sellerRequestId);
            setClearData(true);
        })
        handleBuyersFetch();
        setLinkConfirmationModalStatus(false);
    }

    function putBuyersRequest(id, sellerId) {
        axios.put(process.env.REACT_APP_API_BASE_URL + '/api/v1/buyer_request_list/'+id,
            {'project_id':projectId, 'seller_request_id': sellerId},
            { headers: {
                    "Authorization" : `Bearer ${token}`
                }})
            .then(data => {
                handleBuyersFetch();
            })
            .catch(function (error) {
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
            });
    }

    function createNewSellerRequest(rowId) {
        resetNewSellerData();
        reset();
        setSellerDataRequested("");
        setSellerRequestParameters("");
        setSellerRequestDetails("");
        setSellerCategory("");
        setSellerRequestFileType("");
        setNewRowToLink(rowId);

        //Setting the modal with the existing content
        let filteredDmoData = buyerRowData.filter(dmo => dmo['id'] === rowId);
        setSellerDataRequested(filteredDmoData[0]['request_name']);
        setSellerRequestParameters(filteredDmoData[0]['date_range']);
        setSellerRequestDetails(filteredDmoData[0]['details']);
        setSellerCategory(filteredDmoData[0]['category']);
        setSellerRequestFileType(filteredDmoData[0]['file_type_id']);

        setIsOpen(true);
        setClearData(true);
    }

    function closeModal() {
        setSellerDataRequested("");
        setSellerRequestParameters("");
        setSellerRequestDetails("");
        setSellerCategory("");
        setNewSellerRequestError(false);
        setIsOpen(false);
    }

    function callUpdateSellerList(seller_list_id) {
        const status = statuses.filter(status => status['status'] === "Open");
        updateSellerList(seller_list_id, {"status_id": status[0].id}, projectId, sellerFilteredData, setsellerListRowData, setFilterOpen);
    }

    return (
        <div>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="New Seller Request"
                ariaHideApp={false}
            >
                <h2>New Seller Request</h2>
                {newSellerRequestError && <h1 className="userError">{newSellerRequestError}</h1>}
                <button onClick={closeModal}>close</button>
                <form onSubmit={handleSubmitNewSellerData(data => saveNewSellerData(data,
                    setNewSellerRequestError, putBuyersRequest, setIsOpen, handleSellersListFetch))}
                >
                    <input name="rowToLink" defaultValue={newRowToLink} {...registerNewSellerData('rowToLink')} hidden/>

                    <label>Data Requested</label>
                    <input name="sellerDataRequest" defaultValue={sellerDataRequested} {...registerNewSellerData('data_requested')} />

                    <label>Dates Requested</label>
                    <input name="sellerRequestParameters" defaultValue={sellerRequestParameters} {...registerNewSellerData('parameters')} />

                    <label>Request Details</label>
                    <textarea rows={10} name="sellerRequestDetails" defaultValue={sellerRequestDetails} {...registerNewSellerData('details')} />

                    <label>Phase</label>
                    <input name="sellerBatch" {...registerNewSellerData('batch')} />

                    <label>Category</label>
                    <select {...registerNewSellerData('category_id')}>
                        <option key={0} value={0}>Select a Category</option>
                        {categories && categories.map((category,i)=> {
                            return (
                                <option key={category.id} value={category.id}>{category.category_name}</option>
                            )
                        })}
                    </select>

                    <label>File Type</label>
                    <select {...registerNewSellerData('file_type_id')} defaultValue={sellerRequestFileType}>
                        <option key={0} value={0}>Select a File Type</option>
                        {fileTypes && fileTypes.map((fileType,i)=> {
                            return (
                                <option key={fileType.id} value={fileType.id}>{fileType.file_type}</option>
                            )
                        })}
                    </select>


                    <label style={{ marginTop: '10px' }}>Notify Immediately</label>
                    <input type="checkbox"
                           style={{ marginTop: '-25px' }}
                           {...registerNewSellerData('notify_immediately')}/>

                    <input type="submit" value="Save New Seller Request" className="saveNewSellerRequestButton"/>
                </form>
            </Modal>
            <ImportSpreadsheetModal
                importBuyerRequestModalOpen={importBuyerRequestModalOpen}
                setImportBuyerRequestModalOpen={setImportBuyerRequestModalOpen}
                handleBuyersFetchClick={handleBuyersFetch}
                buyerFilteredData={buyerFilteredData}
                setNotification={setNotification}
            />

            <h1>Data Request Management</h1>
            {notification && <h1 className="userError">{notification}</h1>}
            {errorMessage && <div className="DmoError">{errorMessage}</div> }
            {successMessage && <div className="DmoSuccess">{successMessage}</div> }
                <Modal
                    isOpen={ResponseModalOpen}
                    onRequestClose={closeSellerRequestResponseModal}
                    style={customStyles}
                    contentLabel="Seller Request Response"
                    ariaHideApp={false}
                >
                    <h2>Seller Request Response</h2>
                    <button onClick={closeSellerRequestResponseModal}>close</button>
                    {isLoading && <h1>Loading....</h1>}
                    {responseError && <h1 className="userError">{responseError}</h1>}
                    {sellerRequest &&
                        <>
                            <p><b>Data Requested:</b> {sellerRequest.request_name}</p>
                            <p><b>Request Parameters:</b> {sellerRequest.date_range}</p>
                            <p><b>Details:</b> {sellerRequest.details}</p>
                        </>
                    }
                    {action === "POST" &&
                        <form onSubmit={handleSubmit(data => saveNewSellerResponseRequest(data))}>
                            <input name="response_type" defaultValue="seller" {...register('response_type')} hidden />
                            <input name="approved" defaultValue={true} {...register('approved')} hidden />
                            <input name="seller_request_id" defaultValue={sellerResponseRequestId} {...register('seller_request_id')}  hidden />
                            <input name="response_id" defaultValue={sellerReponseId} {...register('response_id')}  hidden/>
                            <label>Request Status</label>
                            <select name="status" {...register('status')}>
                                {statuses && statuses.map((status,i) => {
                                    return (
                                        <option value={status.status}>{status.status}</option>
                                    )
                                })}
                            </select>

                            <label>Details <br /><em>Any pertinent information that might be needed</em></label>
                            <textarea cols={10} name="response_details" {...register('response_details')} />

                            <FileUploader onFilesSelected={setFiles} width="300px" height='400px'/>

                            <input type="submit" value="Save Response" className="saveNewSellerRequestButton"/>
                        </form>
                    }

                    {action === "PUT" &&
                        <form onSubmit={handleSubmitUpdate(data => updateNewSellerResponseRequest(data))}>
                            <input name="id" defaultValue={editResponseData.id} {...registerUpdate('id')} hidden />
                            <label>Request Status</label>
                            <select name="status" {...registerUpdate('status')}>
                                {statuses && statuses.map((status,i) => {
                                    return (
                                        <option value={status.status}>{status.status}</option>
                                    )
                                })}
                            </select>

                            <label>Details <br /><em>Any pertinent information that might be needed</em></label>
                            <textarea cols={10} name="response_details" defaultValue={editResponseData.response_details}  {...registerUpdate('response_details')} />

                            <FileUploader onFilesSelected={setFiles} width="300px" height='400px'/>

                            <input type="submit" value="Save Response" className="saveNewSellerRequestButton"/>
                        </form>
                    }
                </Modal>

            <Modal
                isOpen={linkConfirmationModalStatus}
                onRequestClose={setLinkConfirmationModalStatus}
                style={customStyles}
                ariaHideApp={false}
            >
                <h1>Confirm Link </h1>
                <button onClick={()=>saveLink()}>Save</button>
                <button onClick={()=>setLinkConfirmationModalStatus(false)}>Cancel</button>
            </Modal>
                <ResponseViewModal
                    ResponseViewModalOpen={ResponseViewModalOpen}
                    setOpenViewResponseModal={setOpenViewResponseModal}
                    sellerResponseViewData={sellerResponseViewData}
                    handleSubmit={handleSubmit}
                    downloadFile={downloadFile}
                    openResponseModal={openResponseModal}
                    sellerRequest={sellerRequest}
                />

            <h1>Buyer Request List</h1>
            <div>
                {projectId && <button className="newBuyersRequestButton" onClick={() => setImportBuyerRequestModalOpen(true)} >
                    Import Buyer's Requests
                </button>}
                <button onClick={() => setBuyerFilterOpen(!buyerFilterOpen)}>Filter</button>
                <button onClick={()=>clearBuyerFilter()}>Clear Filter</button>
                <button onClick={() => setBuyerSortOpen(!buyerSortOpen)}>Sort</button>
                <button onClick={()=>clearBuyerSort()}>Clear Sort</button>
                {buyerFilterOpen ? (
                    <ul className="menu">
                        <li className="menu-item">
                            Linked <br />
                            <select name="filterlinkedRequests" defaultValue={buyerFilteredData.linkedRequests} onChange={(e) =>
                                setBuyerFilteredData( {...buyerFilteredData, linkedRequests: e.target.value})}>
                                <option value="all">All</option>
                                <option value="unlinked">Unlinked</option>
                                <option value="linked">Linked</option>
                            </select>
                        </li>
                        <li className="menu-item">
                            Request ID
                            <input type="text" defaultValue={buyerFilteredData.buyer_request_list_id} name="filterRequestId" placeholder="Request ID"
                                   onChange={(e) =>
                                       setBuyerFilteredData( {...buyerFilteredData, buyer_request_list_id: e.target.value})}>
                            </input>
                        </li>
                        <li className="menu-item">
                            Priority <br />
                            <select  name="filterPriority" defaultValue={buyerFilteredData.priority}
                                     onChange={(e) =>
                                         setBuyerFilteredData( {...buyerFilteredData, priority: e.target.value})}>
                                <option value="">Filter By Priority</option>
                                <option value="Low">Low</option>
                                <option value="Medium">Medium</option>
                                <option value="High">High</option>
                            </select>
                        </li>
                        <li className="menu-item">
                            Category
                            <input type="text" name="filterCategory" defaultValue={buyerFilteredData.category} placeholder="Category"
                                   onChange={(e) =>
                                       setBuyerFilteredData( {...buyerFilteredData, category: e.target.value})}>
                            </input>
                        </li>
                        <li className="menu-item">
                            Request Name
                            <input type="text" name="filterRequestName" defaultValue={buyerFilteredData.request_name} placeholder="Request Name"
                                   onChange={(e) =>
                                       setBuyerFilteredData( {...buyerFilteredData, request_name: e.target.value})}>
                            </input>
                        </li>
                        <li className="menu-item">
                            Request Details
                            <input type="text" defaultValue={buyerFilteredData.details} name="filterRequestId" placeholder="Details"
                                   onChange={(e) =>
                                       setBuyerFilteredData( {...buyerFilteredData, details: e.target.value})}>
                            </input>
                        </li>
                        <li>
                            <button onClick={handleBuyersFetch}>Apply</button>
                        </li>
                    </ul>
                ) : null}
                {buyerSortOpen ? (
                    <ul className="menu">
                        <li className="menu-item">
                            Sort By
                            <select name="id" defaultValue={buyerListSorting.sort_one}
                                    onChange={(e) =>
                                        setBuyerListSorting({...buyerListSorting, sort_one: e.target.value})}>
                                <option value={0}>Sort Column</option>
                                {buyer_sort_array.map((sort,i)=> {
                                    return (
                                        <option key={sort.id} value={sort.id}>{sort.name}</option>
                                    )
                                })}
                            </select>

                            <select name="sort_one_direction" defaultValue={buyerListSorting.sort_one_direction}
                                    onChange={(e) =>
                                        setBuyerListSorting({...buyerListSorting, sort_one_direction: e.target.value})}>
                                <option value="asc">Ascending</option>
                                <option value="desc">Descending</option>
                            </select>
                        </li>

                        <li className="menu-item">
                            Then By
                            <select name="sort_two" defaultValue={buyerListSorting.sort_two}
                                    onChange={(e) =>
                                        setBuyerListSorting({...buyerListSorting, sort_two: e.target.value})}>
                                <option value={0}>Sort Column</option>
                                {buyer_sort_array.map((sort,i)=> {
                                    return (
                                        <option key={sort.id} value={sort.id}>{sort.name}</option>
                                    )
                                })}
                            </select>
                            <select name="sort_two_direction" defaultValue={buyerListSorting.sort_two_direction}
                                    onChange={(e) =>
                                        setBuyerListSorting({...buyerListSorting, sort_two_direction: e.target.value})}>
                                <option value="asc">Ascending</option>
                                <option value="desc">Descending</option>
                            </select>
                        </li>
                        <li className="menu-item">
                            Then By
                            <select name="sort_three" defaultValue={buyerListSorting.sort_three}
                                    onChange={(e) =>
                                        setBuyerListSorting({...buyerListSorting, sort_three: e.target.value})}>
                                <option value={0}>Sort Column</option>
                                {buyer_sort_array.map((sort,i)=> {
                                    return (
                                        <option key={sort.id} value={sort.id}>{sort.name}</option>
                                    )
                                })}
                            </select>
                            <select name="sort_three_direction" defaultValue={buyerListSorting.sort_three_direction}
                                    onChange={(e) =>
                                        setBuyerListSorting({...buyerListSorting, sort_three_direction: e.target.value})}>
                                <option value="asc">Ascending</option>
                                <option value="desc">Descending</option>
                            </select>
                        </li>

                        <li className="menu-item">
                            Then By
                            <select name="sort_four" defaultValue={buyerListSorting.sort_four}
                                    onChange={(e) =>
                                        setBuyerListSorting({...buyerListSorting, sort_four: e.target.value})}>
                                <option value={0}>Sort Column</option>
                                {buyer_sort_array.map((sort,i)=> {
                                    return (
                                        <option key={sort.id} value={sort.id}>{sort.name}</option>
                                    )
                                })}
                            </select>
                            <select name="sort_four_direction" defaultValue={buyerListSorting.sort_four_direction}
                                    onChange={(e) =>
                                        setBuyerListSorting({...buyerListSorting, sort_four_direction: e.target.value})}>
                                <option value="asc">Ascending</option>
                                <option value="desc">Descending</option>
                            </select>
                        </li>

                        <li className="menu-item">
                            Then By
                            <select name="sort_five" defaultValue={buyerListSorting.sort_five}
                                    onChange={(e) =>
                                        setBuyerListSorting({...buyerListSorting, sort_five: e.target.value})}>
                                <option value={0}>Sort Column</option>
                                {buyer_sort_array.map((sort,i)=> {
                                    return (
                                        <option key={sort.id} value={sort.id}>{sort.name}</option>
                                    )
                                })}
                            </select>
                            <select name="sort_five_direction" defaultValue={buyerListSorting.sort_five_direction}
                                    onChange={(e) =>
                                        setBuyerListSorting({...buyerListSorting, sort_five_direction: e.target.value})}>
                                <option value="asc">Ascending</option>
                                <option value="desc">Descending</option>
                            </select>
                        </li>

                        <li className="menu-item">
                            Then By
                            <select name="sort_six" defaultValue={buyerListSorting.sort_six}
                                    onChange={(e) =>
                                        setBuyerListSorting({...buyerListSorting, sort_six: e.target.value})}>
                                <option value={0}>Sort Column</option>
                                {buyer_sort_array.map((sort,i)=> {
                                    return (
                                        <option key={sort.id} value={sort.id}>{sort.name}</option>
                                    )
                                })}
                            </select>
                            <select name="sort_six_direction" defaultValue={buyerListSorting.sort_six_direction}
                                    onChange={(e) =>
                                        setBuyerListSorting({...buyerListSorting, sort_six_direction: e.target.value})}>
                                <option value="asc">Ascending</option>
                                <option value="desc">Descending</option>
                            </select>
                        </li>
                        <li className="menu-item">
                            Then By
                            <select name="sort_seven" defaultValue={buyerListSorting.sort_seven}
                                    onChange={(e) =>
                                        setBuyerListSorting({...buyerListSorting, sort_seven: e.target.value})}>
                                <option value={0}>Sort Column</option>
                                {buyer_sort_array.map((sort,i)=> {
                                    return (
                                        <option key={sort.id} value={sort.id}>{sort.name}</option>
                                    )
                                })}
                            </select>
                            <select name="sort_seven_direction" defaultValue={buyerListSorting.sort_seven_direction}
                                    onChange={(e) =>
                                        setBuyerListSorting({...buyerListSorting, sort_seven_direction: e.target.value})}>
                                <option value="asc">Ascending</option>
                                <option value="desc">Descending</option>
                            </select>
                        </li>
                        <li className="menu-item">
                            Then By
                            <select name="sort_eight" defaultValue={buyerListSorting.sort_eight}
                                    onChange={(e) =>
                                        setBuyerListSorting({...buyerListSorting, sort_eight: e.target.value})}>
                                <option value={0}>Sort Column</option>
                                {buyer_sort_array.map((sort,i)=> {
                                    return (
                                        <option key={sort.id} value={sort.id}>{sort.name}</option>
                                    )
                                })}
                            </select>
                            <select name="sort_eight_direction" defaultValue={buyerListSorting.sort_eight_direction}
                                    onChange={(e) =>
                                        setBuyerListSorting({...buyerListSorting, sort_eight_direction: e.target.value})}>
                                <option value="asc">Ascending</option>
                                <option value="desc">Descending</option>
                            </select>
                        </li>
                        <li className="menu-item">
                            Then By
                            <select name="sort_nine" defaultValue={buyerListSorting.sort_nine}
                                    onChange={(e) =>
                                        setBuyerListSorting({...buyerListSorting, sort_nine: e.target.value})}>
                                <option value={0}>Sort Column</option>
                                {buyer_sort_array.map((sort,i)=> {
                                    return (
                                        <option key={sort.id} value={sort.id}>{sort.name}</option>
                                    )
                                })}
                            </select>
                            <select name="sort_nine_direction" defaultValue={buyerListSorting.sort_nine_direction}
                                    onChange={(e) =>
                                        setBuyerListSorting({...buyerListSorting, sort_nine_direction: e.target.value})}>
                                <option value="asc">Ascending</option>
                                <option value="desc">Descending</option>
                            </select>
                        </li>
                        <li className="menu-item">
                            Then By
                            <select name="sort_ten" defaultValue={buyerListSorting.sort_ten}
                                    onChange={(e) =>
                                        setBuyerListSorting({...buyerListSorting, sort_ten: e.target.value})}>
                                <option value={0}>Sort Column</option>
                                {buyer_sort_array.map((sort,i)=> {
                                    return (
                                        <option key={sort.id} value={sort.id}>{sort.name}</option>
                                    )
                                })}
                            </select>
                            <select name="sort_ten_direction" defaultValue={buyerListSorting.sort_ten_direction}
                                    onChange={(e) =>
                                        setBuyerListSorting({...buyerListSorting, sort_ten_direction: e.target.value})}>
                                <option value="asc">Ascending</option>
                                <option value="desc">Descending</option>
                            </select>
                        </li>
                        <li className="menu-item">
                            Then By
                            <select name="sort_eleven" defaultValue={buyerListSorting.sort_eleven}
                                    onChange={(e) =>
                                        setBuyerListSorting({...buyerListSorting, sort_eleven: e.target.value})}>
                                <option value={0}>Sort Column</option>
                                {buyer_sort_array.map((sort,i)=> {
                                    return (
                                        <option key={sort.id} value={sort.id}>{sort.name}</option>
                                    )
                                })}
                            </select>
                            <select name="sort_eleven_direction" defaultValue={buyerListSorting.sort_eleven_direction}
                                    onChange={(e) =>
                                        setBuyerListSorting({...buyerListSorting, sort_eleven_direction: e.target.value})}>
                                <option value="asc">Ascending</option>
                                <option value="desc">Descending</option>
                            </select>
                        </li>
                        <li className="menu-item">
                            Then By
                            <select name="sort_twelve" defaultValue={buyerListSorting.sort_twelve}
                                    onChange={(e) =>
                                        setBuyerListSorting({...buyerListSorting, sort_twelve: e.target.value})}>
                                <option value={0}>Sort Column</option>
                                {buyer_sort_array.map((sort,i)=> {
                                    return (
                                        <option key={sort.id} value={sort.id}>{sort.name}</option>
                                    )
                                })}
                            </select>
                            <select name="sort_twelve_direction" defaultValue={buyerListSorting.sort_twelve_direction}
                                    onChange={(e) =>
                                        setBuyerListSorting({...buyerListSorting, sort_twelve_direction: e.target.value})}>
                                <option value="asc">Ascending</option>
                                <option value="desc">Descending</option>
                            </select>
                        </li>

                        <li>
                            <button onClick={handleBuyersFetch}>Apply</button>
                        </li>
                    </ul>
                ) : null}
            </div>
            <table className="sellerListTable">
                <thead>
                <tr className="outline">
                    <th></th>
                    <th className="outline">Seller ID</th>
                    <th className="outline">Request ID</th>
                    <th className="outline">Priority</th>
                    <th className="outline">Category</th>
                    <th className="outline">Request Name</th>
                    <th className="outline">Request Details (Notes to explain request)</th>
                    <th className="outline">Date Range</th>
                    <th className="outline">Format (File type or format for request)</th>
                    <th className="outline">Requested Date</th>
                    <th></th>
                </tr>
                </thead>
                <tbody>

                { buyerRowData.length > 0 && (buyerRowData).map((buyerRequest,i) =>{
                    const showNewRequestButton = buyerRequest.seller_request_id === '' ? true : false;
                    return (
                        <tr key={buyerRequest.id} className="outline">
                            <td className="outline">
                                <input type="checkbox"
                                       onClick={(event) => {
                                           let id = buyerRequest.id;
                                           if (event.target.checked) {
                                               const newArray = [...dataRequest, id];
                                               setDataRequest(newArray);
                                           } else {
                                               const filteredArray = dataRequest.filter(item => item !== id)
                                               setDataRequest(filteredArray);
                                           }
                                       }
                                       }
                                />
                            </td>
                            <td className="outline">{buyerRequest.seller_request_id}</td>
                            <td className="outline">{buyerRequest.buyer_request_list_id}</td>
                            <td className="outline">{buyerRequest.priority}</td>
                            <td className="outline">{buyerRequest.category}</td>
                            <td className="outline">{buyerRequest.request_name}</td>
                            <td className="outline">{buyerRequest.details}</td>
                            <td className="outline">{buyerRequest.date_range}</td>
                            <td className="outline">{buyerRequest.file_type}</td>
                            <td className="outline">{buyerRequest.requested_date}</td>
                            <td className="outline">
                                {showNewRequestButton &&
                                    <button className="sellerRequestButton" onClick={() => createNewSellerRequest(buyerRequest.id)}>
                                        New Seller Request
                                    </button>}
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
            <button onClick={() => setLinkConfirmationModalStatus(true)} className="linkButton">Link</button>
                <h1>Seller Request List</h1>
                {error && <h1 className="userError">{error}</h1>}
                <div>
                    <button onClick={openFilter}>Filter</button>
                    <button onClick={()=>clearSellerFilter()}>Clear Filter</button>
                    <button onClick={() => setSellerSortOpen(true)}>Sort</button>
                    <button onClick={()=>clearSellerSort()}>Clear Sort</button>
                    {filterOpen ? (
                        <ul className="menu">
                            {filtered && <h1>Data is Filtered</h1>}
                            <li className="menu-item">
                                Data Requested
                                <input type="text" placeholder="Data Requested" defaultValue={sellerFilteredData.data_requested}
                                       onChange={(e) =>
                                           setSellerFilteredData({...sellerFilteredData, data_requested: e.target.value})}>
                                </input>
                            </li>

                            <li className="menu-item">
                                Request Details
                                <input type="text" placeholder="Request Details" defaultValue={sellerFilteredData.details}
                                       onChange={(e) =>
                                           setSellerFilteredData({...sellerFilteredData, details: e.target.value})}>
                                </input>
                            </li>

                            <li className="menu-item">
                                <label>Request Status</label>
                                <select name="status" defaultValue={sellerFilteredData.status}
                                        onChange={(e) =>
                                            setSellerFilteredData({...sellerFilteredData, status: e.target.value})}>
                                    <option value="">Filter By Status</option>
                                    {statuses && statuses.map((status,i) => {
                                        return (
                                            <option value={status.name}>{status.name}</option>
                                        )
                                    })}
                                </select>
                            </li>
                            <li>
                                <button onClick={handleSellersListFetch}>Apply</button>
                            </li>
                        </ul>
                    ) : null}
                    {sellerSortOpen ? (
                        <ul className="menu">
                            <li className="menu-item">
                                Sort By
                                <select name="id" defaultValue={sellerListSorting.sort_one}
                                        onChange={(e) =>
                                            setSellerListSorting({...sellerListSorting, sort_one: e.target.value})}>
                                    <option value={0}>Sort Column</option>
                                    {seller_sort_array.map((sort,i)=> {
                                        return (
                                            <option key={sort.id} value={sort.id}>{sort.name}</option>
                                        )
                                    })}
                                </select>

                                <select name="sort_one_direction" defaultValue={sellerListSorting.sort_one_direction}
                                        onChange={(e) =>
                                            setSellerListSorting({...sellerListSorting, sort_one_direction: e.target.value})}>
                                    <option value="asc">Ascending</option>
                                    <option value="desc">Descending</option>
                                </select>
                            </li>

                            <li className="menu-item">
                                Then By
                                <select name="sort_two" defaultValue={sellerListSorting.sort_two}
                                        onChange={(e) =>
                                            setSellerListSorting({...sellerListSorting, sort_two: e.target.value})}>
                                    <option value={0}>Sort Column</option>
                                    {seller_sort_array.map((sort,i)=> {
                                        return (
                                            <option key={sort.id} value={sort.id}>{sort.name}</option>
                                        )
                                    })}
                                </select>
                                <select name="sort_two_direction" defaultValue={sellerListSorting.sort_two_direction}
                                        onChange={(e) =>
                                            setSellerListSorting({...sellerListSorting, sort_two_direction: e.target.value})}>
                                    <option value="asc">Ascending</option>
                                    <option value="desc">Descending</option>
                                </select>
                            </li>
                            <li className="menu-item">
                                Then By
                                <select name="sort_three" defaultValue={sellerListSorting.sort_three}
                                        onChange={(e) =>
                                            setSellerListSorting({...sellerListSorting, sort_three: e.target.value})}>
                                    <option value={0}>Sort Column</option>
                                    {seller_sort_array.map((sort,i)=> {
                                        return (
                                            <option key={sort.id} value={sort.id}>{sort.name}</option>
                                        )
                                    })}
                                </select>
                                <select name="sort_three_direction" defaultValue={sellerListSorting.sort_three_direction}
                                        onChange={(e) =>
                                            setSellerListSorting({...sellerListSorting, sort_three_direction: e.target.value})}>
                                    <option value="asc">Ascending</option>
                                    <option value="desc">Descending</option>
                                </select>
                            </li>

                            <li className="menu-item">
                                Then By
                                <select name="sort_four" defaultValue={sellerListSorting.sort_four}
                                        onChange={(e) =>
                                            setSellerListSorting({...sellerListSorting, sort_four: e.target.value})}>
                                    <option value={0}>Sort Column</option>
                                    {seller_sort_array.map((sort,i)=> {
                                        return (
                                            <option key={sort.id} value={sort.id}>{sort.name}</option>
                                        )
                                    })}
                                </select>
                                <select name="sort_four_direction" defaultValue={sellerListSorting.sort_four_direction}
                                        onChange={(e) =>
                                            setSellerListSorting({...sellerListSorting, sort_four_direction: e.target.value})}>
                                    <option value="asc">Ascending</option>
                                    <option value="desc">Descending</option>
                                </select>
                            </li>

                            <li className="menu-item">
                                Then By
                                <select name="sort_five" defaultValue={sellerListSorting.sort_five}
                                        onChange={(e) =>
                                            setSellerListSorting({...sellerListSorting, sort_five: e.target.value})}>
                                    <option value={0}>Sort Column</option>
                                    {seller_sort_array.map((sort,i)=> {
                                        return (
                                            <option key={sort.id} value={sort.id}>{sort.name}</option>
                                        )
                                    })}
                                </select>
                                <select name="sort_five_direction" defaultValue={sellerListSorting.sort_five_direction}
                                        onChange={(e) =>
                                            setSellerListSorting({...sellerListSorting, sort_five_direction: e.target.value})}>
                                    <option value="asc">Ascending</option>
                                    <option value="desc">Descending</option>
                                </select>
                            </li>

                            <li className="menu-item">
                                Then By
                                <select name="sort_six" defaultValue={sellerListSorting.sort_six}
                                        onChange={(e) =>
                                            setSellerListSorting({...sellerListSorting, sort_six: e.target.value})}>
                                    <option value={0}>Sort Column</option>
                                    {seller_sort_array.map((sort,i)=> {
                                        return (
                                            <option key={sort.id} value={sort.id}>{sort.name}</option>
                                        )
                                    })}
                                </select>
                                <select name="sort_six_direction" defaultValue={sellerListSorting.sort_six_direction}
                                        onChange={(e) =>
                                            setSellerListSorting({...sellerListSorting, sort_six_direction: e.target.value})}>
                                    <option value="asc">Ascending</option>
                                    <option value="desc">Descending</option>
                                </select>
                            </li>
                            <li className="menu-item">
                                Then By
                                <select name="sort_seven" defaultValue={sellerListSorting.sort_seven}
                                        onChange={(e) =>
                                            setSellerListSorting({...sellerListSorting, sort_seven: e.target.value})}>
                                    <option value={0}>Sort Column</option>
                                    {seller_sort_array.map((sort,i)=> {
                                        return (
                                            <option key={sort.id} value={sort.id}>{sort.name}</option>
                                        )
                                    })}
                                </select>
                                <select name="sort_seven_direction" defaultValue={sellerListSorting.sort_seven_direction}
                                        onChange={(e) =>
                                            setSellerListSorting({...sellerListSorting, sort_seven_direction: e.target.value})}>
                                    <option value="asc">Ascending</option>
                                    <option value="desc">Descending</option>
                                </select>
                            </li>
                            <li className="menu-item">
                                Then By
                                <select name="sort_eight" defaultValue={sellerListSorting.sort_eight}
                                        onChange={(e) =>
                                            setSellerListSorting({...sellerListSorting, sort_eight: e.target.value})}>
                                    <option value={0}>Sort Column</option>
                                    {seller_sort_array.map((sort,i)=> {
                                        return (
                                            <option key={sort.id} value={sort.id}>{sort.name}</option>
                                        )
                                    })}
                                </select>
                                <select name="sort_eight_direction" defaultValue={sellerListSorting.sort_eight_direction}
                                        onChange={(e) =>
                                            setSellerListSorting({...sellerListSorting, sort_eight_direction: e.target.value})}>
                                    <option value="asc">Ascending</option>
                                    <option value="desc">Descending</option>
                                </select>
                            </li>
                            <li className="menu-item">
                                Then By
                                <select name="sort_nine" defaultValue={sellerListSorting.sort_nine}
                                        onChange={(e) =>
                                            setSellerListSorting({...sellerListSorting, sort_nine: e.target.value})}>
                                    <option value={0}>Sort Column</option>
                                    {seller_sort_array.map((sort,i)=> {
                                        return (
                                            <option key={sort.id} value={sort.id}>{sort.name}</option>
                                        )
                                    })}
                                </select>
                                <select name="sort_nine_direction" defaultValue={sellerListSorting.sort_nine_direction}
                                        onChange={(e) =>
                                            setSellerListSorting({...sellerListSorting, sort_nine_direction: e.target.value})}>
                                    <option value="asc">Ascending</option>
                                    <option value="desc">Descending</option>
                                </select>
                            </li>

                            <li>
                                <button onClick={handleSellersListFetch}>Apply</button>
                            </li>
                        </ul>
                    ) : null}
                </div>
                <table className="sellerListTable">
                    <thead>
                    <tr className="outline">
                        <th className="outline"></th>
                        <th className="outline">Phase</th>
                        <th className="outline">Requested On</th>
                        <th className="outline">Request ID</th>
                        <th className="outline">Category</th>
                        <th className="outline">Request Name</th>
                        <th className="outline">Request Details</th>
                        <th className="outline">Date Range</th>
                        <th className="outline">Preferred File Type</th>
                        <th className="outline">Status</th>
                        <th className="outline">Responses</th>
                        <th className="outline"></th>
                    </tr>
                    </thead>
                    <tbody>
                    {sellerListRowData && sellerListRowData.map((sellerListData,i)=>{
                        const showReopen = sellerListData.status === "Closed";
                        return (
                            <tr key={sellerListData.id} className="outline">
                                <td className="outline">
                                    <input type="checkbox"
                                           disabled={disableCheckboxes && !boxes[sellerListData.id]}
                                           onChange={handleChange}
                                           id={sellerListData.id}
                                           onClick={(event) => {
                                                let id = sellerListData.id;
                                                if (event.target.checked) {
                                                    const newValue = id
                                                    setSellerRequestId(newValue);
                                                    setDisableCheckboxes(true);
                                                } else {
                                                    setSellerRequestId();
                                                    setDisableCheckboxes(false);
                                                }
                                           }}
                                    />
                                </td>
                                <td className="outline">{sellerListData.batch}</td>
                                <td className="outline">{sellerListData.assigned_date}</td>
                                <td className="outline">{sellerListData.seller_request_id}</td>
                                <td className="outline">{sellerListData.category}</td>
                                <td className="outline">{sellerListData.data_requested}</td>
                                <td className="outline">{sellerListData.details}</td>
                                <td className="outline">{sellerListData.parameters}</td>
                                <td className="outline">{sellerListData.file_format}</td>
                                <td className="outline">{sellerListData.status}</td>
                                {sellerListData.response_count>0 && <td className="outline">
                                    <button onClick={() => { openViewResponsesModal(sellerListData.id, sellerListData) }}>
                                        View {sellerListData.response_count} Responses
                                    </button>
                                </td>}
                                {sellerListData.response_count === 0 && <td className="outline"></td>}
                                <td>
                                    <button onClick={() => { openResponseModal(sellerListData.id, '', "POST", sellerListData) }}>Upload Response</button>
                                    {showReopen && <button onClick={() => { callUpdateSellerList(sellerListData.id)}}>Reopen</button>}
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>


        </div>
    );
}
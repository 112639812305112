import React, {useEffect, useState} from "react";
import Modal from "react-modal";
import {useForm} from "react-hook-form";
import {customStyles} from "../../Constants";
import { fetchResponse, fetchResponses, updateResponse} from '../../Functions/Responses'
import {fetchReopenQueue} from "../../Functions/ReopenQueueFunctions";
import ReopenQueueModal from "../../components/Modals/RepoenQueueModal";
import {fetchBuyersList} from "../../Functions/BuyersList";

export default function ResponseManagement() {
    const { register, handleSubmit , reset} = useForm();
    const[error, setError] = useState();
    const[filterOpen, setFilterOpen] = useState(false);
    const[filteredData, setFilteredData] = useState({
        seller_request_id: "",
        response_id: "",
        response_details: "",
        edited_response: "",
        response_type: "buyer",
        approved: "Open",
    });
    const[, setIsLoading] = useState();
    const[modalOpen, setModalOpen] = useState(false);
    const[projectId, setProjectId] = useState(localStorage.getItem('projectId'));
    const[response, setResponse] = useState([]);
    const[responses, setResponses] = useState([]);
    const[updatedResponseList, setUpdateResponseLists] = useState(false);
    const[reopenQueuefilters, setReopenQueuefilters] = useState({
        project_id: localStorage.getItem('projectId'),
        id: "",
        seller_request_id: "",
        reason: "",
        status: "Open",
        user_id: "",
    });
    const[reopenQueueModalOpen, setReopenQueueModalOpen] = useState(false);
    const[reopenQueue,setReopenQueue] = useState([]);
    const[ReopenQueueFilterOpen, setReopenQueueFilterOpen] = useState(false);
    const[sellerRequestId, setSellerRequestId] = useState();
    const[queue_id, setQueueId] = useState();
    window.addEventListener('projectId', () => {
        if (localStorage.getItem('projectId') === 'Select A Project') {
            localStorage.removeItem('projectId');
        } else {
            setProjectId(localStorage.getItem('projectId'));
            setReopenQueuefilters( {...reopenQueuefilters, project_id: localStorage.getItem('projectId')})
        }
        reset();
        setUpdateResponseLists(true);
        setResponses([]);
        setUpdateResponseLists([]);
    })

    function clearFilter() {
        setFilteredData( {
            seller_request_id: "",
            response_id: "",
            response_details: "",
            edited_response: "",
            response_type: "buyer",
            status: "Open",
        });
        setUpdateResponseLists(true);
        fetchResponses(filteredData, setResponses, setFilterOpen, projectId);
    }

    const openResponseModal = (responseId) => {
        setModalOpen(true);
        fetchResponse(responseId, reset, setResponse, setError);
    };

    useEffect(() => {
       fetchResponses(filteredData, setResponses, setFilterOpen, projectId);
    }, [updatedResponseList, projectId]);

    useEffect(() => {
        fetchReopenQueue(reopenQueuefilters, setReopenQueue, setReopenQueueFilterOpen);
        }, [projectId]);


    const hendleFetchResponses = (e) => {
        setError(null);
        if (projectId === null) {
            setResponses([]);
            setError('Please select a project');
            return;
        }
        fetchResponses(filteredData, setResponses, setFilterOpen, projectId);
    };

    const handleUpdateResponse = (data) => {
        updateResponse(data, setError, [], setIsLoading, setModalOpen)
        fetchResponses(filteredData, setResponses, setFilterOpen, projectId);
    }

    function openReopenQueueModel(queue_id, seller_request_id) {
        setQueueId(queue_id);
        setSellerRequestId(seller_request_id);
        setReopenQueueModalOpen(true);
    }

    return (
        <div>
            <h1>Response Management</h1>
            {error && <h1 className="userError">{error}</h1>}
            <Modal
                isOpen={modalOpen}
                onRequestClose={() => setModalOpen(false)}
                style={customStyles}
                contentLabel="Buyer Response Management"
                ariaHideApp={false}
            >
                <button onClick={() => setModalOpen(false)}>close</button>
                {error && <h1 className="userError">{error}</h1>}
                <form onSubmit={handleSubmit(data => handleUpdateResponse(data))}>
                    <input name="id" defaultValue={response.id} {...register('id')} hidden />
                    <label>Request Status</label>

                    <label>Details</label>
                    <input name="response_details" defaultValue={response.response_details}  {...register('response_details')} disabled/>

                    <label>Edits</label>
                    <input name="edited_response" defaultValue={response.edited_response}  {...register('edited_response')} />

                    <label>Approval</label>
                    <select defaultValue={response.status} {...register('status')}>
                        <option value="Open">Open</option>
                        <option value="Approved">Approved</option>
                        <option value="Rejected">Rejected</option>
                    </select>
                    <input type="submit" value="Save Response" className="saveNewSellerRequestButton"/>
                </form>
            </Modal>

            <ReopenQueueModal
                queue_id={queue_id}
                sellerRequestId={sellerRequestId}
                reopenQueueModalOpen={reopenQueueModalOpen}
                setReopenQueueModalOpen={setReopenQueueModalOpen}
                reopenQueuefilters={reopenQueuefilters}
                setReopenQueue={setReopenQueue}
                setReopenQueueFilterOpen={setReopenQueueFilterOpen}
            />

            <div>
                <button onClick={() => setFilterOpen(!filterOpen)}>Filter</button>
                <button onClick={()=>clearFilter()}>Clear Filter</button>
                {filterOpen ? (
                    <ul className="menu">
                        <li className="menu-item">
                            Request ID
                            <input type="text" defaultValue={filteredData.id} name="filterRequestId" placeholder="Request ID"
                                   onChange={(e) =>
                                       setFilteredData( {...filteredData, id: e.target.value})}>
                            </input>
                        </li>
                        <li className="menu-item">
                            Status <br />
                            <select name="filterlinkedRequests" defaultValue={filteredData.status} onChange={(e) =>
                                setFilteredData( {...filteredData, status: e.target.value})}>
                                <option value="Open">Open</option>
                                <option value="Approved">Approved</option>
                                <option value="Rejected">Rejected</option>
                                <option value="">All</option>
                            </select>
                        </li>
                        <li className="menu-item">
                            Details
                            <input type="text" defaultValue={filteredData.response_details} name="filterRequestId" placeholder="Details"
                                   onChange={(e) =>
                                       setFilteredData( {...filteredData, response_details: e.target.value})}>
                            </input>
                        </li>
                        <li className="menu-item">
                            Edits
                            <input type="text" defaultValue={filteredData.edited_response} name="filterRequestId" placeholder="Edited Data"
                                   onChange={(e) =>
                                       setFilteredData( {...filteredData, edited_response: e.target.value})}>
                            </input>
                        </li>

                        <li>
                            <button onClick={hendleFetchResponses}>Apply</button>
                        </li>
                    </ul>
                ) : null}
            </div>

            <table className="sellerListTable">
                <thead>
                <tr className="outline">
                    <th className="outline">Requestor</th>
                    <th className="outline">Status</th>
                    <th className="outline">Details</th>
                    <th className="outline">Edit</th>
                    <th className="outline"></th>
                </tr>
                </thead>
                <tbody>
                {responses.length > 0 && responses.map((response,i)=>{
                    console.log(responses);
                    return (
                        <tr key={response.id} className="outline">
                            <td className="outline">{response.user}</td>
                            <td className="outline">{response.status}</td>
                            <td className="outline">{response.response_details}</td>
                            <td className="outline">{response.edited_response}</td>
                            <td className="outline">
                                <button onClick={()=>
                                { openResponseModal(response.id) }}>Edit Response
                                </button>
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>

            <h1>Reopen Seller Request Management</h1>
            <table className="sellerListTable">
                <thead>
                <tr className="outline">
                    <th className="outline">Reopen Request ID</th>
                    <th className="outline">Seller Request ID</th>
                    <th className="outline">Reason</th>
                    <th className="outline">Reopen</th>
                    <th className="outline">User</th>
                    <th className="outline">Created</th>
                    <th className="outline">Updated</th>
                    <th className="outline"></th>
                </tr>
                </thead>
                <tbody>
                {reopenQueue && (reopenQueue).map((queue,i)=>{
                    const reopen = queue.reopen == true ? "Approved" : "Unapproved";
                    return (
                        <tr key={queue.id} className="outline">
                            <td className="outline">{queue.id}</td>
                            <td className="outline">{queue.seller_request_details}</td>
                            <td className="outline">{queue.reason}</td>
                            <td className="outline">{reopen}</td>
                            <td className="outline">{queue.user_name}</td>
                            <td className="outline">{queue.time_created}</td>
                            <td className="outline">{queue.time_updated}</td>
                            <td className="outline">
                                <button onClick={()=>
                                { openReopenQueueModel(queue.id, queue.seller_request_id) }}>Reopen
                                </button>
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </div>
    )
}
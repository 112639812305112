import {React, useState} from "react";
import Modal from "react-modal";
import {customStyles, DATE_RANGE} from "../../Constants";
import DeleteConfirmation from "../../components/Modals/DeleteConfirmation";
import {deleteFiles} from "../../Functions/Files";
import './modalStyles.css';

export default function ResponseViewModal({ResponseViewModalOpen, setOpenViewResponseModal, sellerResponseViewData,
                                          handleSubmit, downloadFile, openResponseModal, sellerRequest}) {
    const[deleteConfirmationModalOpen, setdeleteConfirmationModalOpen] = useState();
    const [popup, setPopup] = useState({
        show: false, // initial values set to false and null
        id: null,
    });

    const handleDelete = (id) => {
        setdeleteConfirmationModalOpen(true)
        setPopup({
            show: true,
            id,
        });
    };

    const handleDeleteTrue = () => {
        if (popup.show && popup.id) {
            deleteFiles(popup.id);
            setPopup({
                show: false,
                id: null,
            });
        }
    };

    const handleDeleteFalse = () => {
        setPopup({
            show: false,
            id: null,
        });
    };

    return <>
        {popup.show && (
            <DeleteConfirmation
                handleDeleteTrue={handleDeleteTrue}
                handleDeleteFalse={handleDeleteFalse}
                deleteConfirmationModalOpen={deleteConfirmationModalOpen}
                setdeleteConfirmationModalOpen={setdeleteConfirmationModalOpen}
            />
        )}
        <Modal
            isOpen={ResponseViewModalOpen}
            onRequestClose={() => setOpenViewResponseModal(false)}
            style={customStyles}
            contentLabel="Seller Responses"
            ariaHideApp={false}
        >
            <h2>Seller Response</h2>
            <button onClick={() => setOpenViewResponseModal(false)}>close</button>
            {sellerRequest &&
                <>
                    <p><b>Request Name:</b> {sellerRequest.data_requested}</p>
                    <p><b>Request Details:</b> {sellerRequest.details}</p>
                    <p><b>{DATE_RANGE}:</b> {sellerRequest.parameters}</p>
                    <p><b>Seller Status:</b> {sellerRequest.status}</p>
                </>
            }
            <br/>
            <button onClick={()=> { openResponseModal(sellerRequest.id, "POST") }}>Upload Response</button>
            <table className='sellerListTable'>
                <thead>
                <tr>
                    <th className="outline">Response ID</th>
                    <th className="outline">Uploaded Date</th>
                    <th className="outline">Uploaded By</th>
                    <th className="outline">Comments</th>
                    <th className="outline">Document Count</th>
                </tr>
                </thead>
                <tbody>
                {sellerResponseViewData && sellerResponseViewData.map((sellerResponses,i)=>{
                    const time_created = new Date(sellerResponses.time_created).toLocaleDateString();
                    console.log(sellerResponses)
                    return (
                        <>
                            <tr key={sellerResponses.id}>
                                <td className="outline">{sellerResponses.seller_response_id}</td>
                                <td className="outline">{time_created}</td>
                                <td className="outline">{sellerResponses.user}</td>
                                <td className="outline">{sellerResponses.response_details}</td>
                                <td className="outline" style={{textAlign: "right"}}>{sellerResponses.document_count}</td>
                            </tr>
                        </>
                    )
                })}
                </tbody>
            </table>
        </Modal>
    </>
}
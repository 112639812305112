import Modal from "react-modal";
import {React, useEffect, useState} from "react";
import {customStyles} from "../../Constants";
import {useForm} from "react-hook-form";
import {updateReopenQueue} from "../../Functions/ReopenQueueFunctions";
import {saveNewResponse} from "../../Functions/Responses";


export default function ReopenQueueModal({queue_id, sellerRequestId, reopenQueueModalOpen, setReopenQueueModalOpen, reopenQueuefilters, setReopenQueue, setReopenQueueFilterOpen}) {
    const { register, handleSubmit , reset} = useForm();
    const[error, setError] = useState();
    const[isLoading, setIsLoading] = useState(false);

    function reopenSellerRequest(data) {
        updateReopenQueue(queue_id,  {'status': "Approved"}, reopenQueuefilters, setReopenQueue, setReopenQueueFilterOpen);
        const responseData = {
            seller_request_id:sellerRequestId,
            response_details:data['response_details'],
            response_type:"seller",
            response_id: ""
        }
        saveNewResponse(responseData);
        setReopenQueueModalOpen(false);
        reset();
    }

    return <>
        <Modal
            isOpen={reopenQueueModalOpen}
            style={customStyles}
            contentLabel="Import Buyer's Requests"
            ariaHideApp={false}
        >
            <h2>Import Buyer's Requests</h2>
            {isLoading && <h1>Loading....</h1>}
            {error && <h1 className="userError">{error}</h1>}
            <button onClick={() => setReopenQueueModalOpen(false)}>close</button>

            <form onSubmit={handleSubmit(data =>
                reopenSellerRequest(data))}>
                <label>Response</label>
                <input name="response_details" {...register('response_details')} />
                <input type="submit" value="Reopen" className="saveNewSellerRequestButton"/>
            </form>
        </Modal>
    </>
}
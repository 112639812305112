import {React, useEffect, useState} from "react";
import Modal from "react-modal";
import {useForm} from "react-hook-form";
import {customStyles} from "../../Constants";
import {fetchFileTypes} from "../../Functions/Files";
import {saveBuyersList} from "../../Functions/BuyersList";
import {parsePermissions} from "../../Functions/PermissionsFunctions";
import {fetchEntities} from "../../Functions/EntityFunctions";

function NewBuyerRequest({setIsOpen, requestAction, buyerRequest, setBuyerRequest, projectId, buyerFilteredData, setBuyersListRowData, setFilterOpen, modalIsOpen}) {
    const { register, handleSubmit , reset,setValue} = useForm();
    const[adminPermission, setAdminPermission] = useState();
    const[entities, setEntities] = useState([]);
    const[error, setError] = useState(false);
    const[fileTypes, setFileTypes] = useState([]);
    const[,setBuyerPermission] = useState();
    const[,setSellerPermission] = useState();

    useEffect(() => {
        fetchEntities(setEntities);
        fetchFileTypes(setFileTypes);
        parsePermissions(setBuyerPermission, setSellerPermission, setAdminPermission);
    }, []);

    function closeNewBuyerRequestModal() {
        setIsOpen(false);
        setBuyerRequest([]);
    }
    return <>
        <Modal
            isOpen={modalIsOpen}
            style={customStyles}
            contentLabel="New Buyer Request"
            ariaHideApp={false}
        >

        {requestAction === "PUT" && <h2>Edit Buyer Request</h2>}
        {requestAction == "POST" && <h2>New Buyer Request</h2>}

        {error && <h1 className="userError">{error}</h1>}
        <button onClick={() => closeNewBuyerRequestModal()}>close</button>
        <form onSubmit={handleSubmit(data =>
            saveBuyersList(data, setError, reset, "", setIsOpen, projectId,
                buyerFilteredData, setBuyersListRowData, setFilterOpen, requestAction))}>

            {requestAction === "PUT" && setValue('id', buyerRequest.id)}
            {requestAction == "PUT" && <input name="id" {...register('id')} hidden/>}

            {adminPermission && <>
                {requestAction === "PUT" && setValue('entity_id', buyerRequest.entity_id)}
                <label>Requesting Entity</label>
                <select name="entity_id" {...register('entity_id')} >
                    <option>Select Requesting Entity</option>
                    {entities && entities.map((entity,i)=> {
                        return (
                            <option value={entity.id}>{entity.entity_name}</option>
                        )
                    })}
                </select>
            </>}

            {requestAction === "PUT" && setValue('priority', buyerRequest.priority)}
            {((requestAction === "PUT") || requestAction == "POST") && <>
                <label>Priority</label>
                <select {...register('priority')}>
                    <option key="newBuyerRequestLow" value="Low">Low</option>
                    <option key="newBuyerRequestMedium" value="Medium">Medium</option>
                    <option key="newBuyerRequestHigh" value="High">High</option>
                </select>
            </>}

            {requestAction === "PUT" && setValue('category', buyerRequest.category)}
            {((buyerRequest.seller_request_id === null && requestAction === "PUT") || requestAction == "POST") && <>
                <label>Category</label>
                <input name="category" {...register('category')} />
            </>}

            {requestAction === "PUT" && setValue('request_name', buyerRequest.request_name)}
            {((buyerRequest.seller_request_id === null && requestAction === "PUT") || requestAction == "POST") && <>
                <label>Request Name <br/> <em>(Name or Label for request)</em></label>
                <input name="request_name" maxLength={50} {...register('request_name')} />
            </>}

            {requestAction === "PUT" && setValue('details', buyerRequest.details)}
            {((buyerRequest.seller_request_id === null && requestAction === "PUT") || requestAction == "POST") && <>
                <label>Request Details <br/> <em>(Notes to explain details)</em></label>
                <textarea rows={10} name="details" {...register('details')} />
            </>}

            {requestAction === "PUT" && setValue('date_range', buyerRequest.date_range)}
            {((buyerRequest.seller_request_id === null && requestAction === "PUT") || requestAction == "POST") && <>
                <label>Scope<br/> <em>Date range or other scope-limiting parameter (i.e. 2020-2024, TTM, Active)</em></label>
                <input name="date_range" {...register('date_range')} />
            </>}

            {requestAction === "PUT" && setValue('file_type_id', buyerRequest.file_type_id)}
            {((buyerRequest.seller_request_id === null && requestAction === "PUT") || requestAction == "POST") && <>
                <label>Preferred File Formats</label>
                <select {...register('file_type_id')}>
                    <option key={0}></option>
                    {fileTypes && fileTypes.map((fileType,i)=> {
                        return (
                            <option key={fileType.id} value={fileType.id}>{fileType.file_type}</option>
                        )
                    })}
                </select>
            </>}

            {requestAction == "POST" && <input type="submit" value="Save Request" className="saveNewBuyerRequestButton"/>}
            {requestAction == "PUT" && <input type="submit" value="Update Request" className="saveNewBuyerRequestButton"/>}
        </form>
        </Modal>
        </>
}

export default NewBuyerRequest;
import FileUploader from "../FileUploader/FileUploader";
import Modal from "react-modal";
import {React, useEffect, useState} from "react";
import {customStyles} from "../../Constants";
import {useForm} from "react-hook-form";
import {fetchEntities} from "../../Functions/EntityFunctions"
import {import_spreadsheet} from "../../Functions/ImportBuyersRequests";
import {Link} from 'react-router-dom';


export default function ImportSpreadsheetModal({importBuyerRequestModalOpen, setImportBuyerRequestModalOpen,
                                                   handleBuyersFetchClick, buyerFilteredData, setNotification}) {
    const { register, handleSubmit , reset,setValue} = useForm();
    const[error, setError] = useState();
    const[files, setFiles] = useState([]);
    const[entities, setEntities] = useState([]);
    const[isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        fetchEntities(setEntities);
    }, []);

    function importBuyersRequestSpreadsheet (data) {
        const projectId = localStorage.getItem('projectId');
        if(projectId === 'Select A Project') { setError('You must select a project'); return;}
        if(files.length === 0) { setError('You must add a file'); return;}
        import_spreadsheet(projectId, data.entity_id, setImportBuyerRequestModalOpen, files, setError, setIsLoading,
            handleBuyersFetchClick, buyerFilteredData, setNotification);
    }

    return <>
        <Modal
            isOpen={importBuyerRequestModalOpen}
            style={customStyles}
            contentLabel="Import Buyer's Requests"
            ariaHideApp={false}
        >
            <h2>Import Buyer's Requests</h2>
            {isLoading && <h1>Loading....</h1>}
            {error && <h1 className="userError">{error}</h1>}
            <button onClick={() => setImportBuyerRequestModalOpen(false)}>close</button>

            <form onSubmit={handleSubmit(data =>
                importBuyersRequestSpreadsheet(data))}>
                <Link to="/ImportBuyersRequestsTemplate.xlsx" target="_blank" download>Template File</Link>
                <br/>
                <br/>
                
                <FileUploader onFilesSelected={setFiles} width="300px" height='400px' fileFormats='XLSX, CSV'/>
                <input type="submit" value="Import" className="saveNewSellerRequestButton"/>
            </form>
        </Modal>
    </>
}
import * as React from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
// import AccountTreeIcon from '@mui/icons-material/AccountTree';
import './navigation.css'
import { createBrowserRouter, Link, RouterProvider, Outlet} from 'react-router-dom';
import DataRequestManagement from "../DataRequestManagement/DataRequestManagement";
import WorkstreamManagement from "../WorkstreamManagement/WorkstreamManagement";
import WorkstreamCategoryManagement from "../WorkstreamCategoryManagement/WorkstreamCategoryManagement";
import SellerList from "../SellerList/SellerList";
import Projects from "../Projects/Projects";
import BuyersList from "../BuyersList/BuyersList";
import EntityManagement from "../EntityManagement/EntityManagement";
import Profile from "../Profile/Profile";
import User from "../User/User";
import ThirdPartyAssignment from "../ThirdPartyAssignment/ThirdPartyAssignment";
import {useEffect, useState} from "react";
import axios from "axios";
import * as CONSTANTS from '../../Constants';
import Roles from "../Role/Roles";
import ResponseManagement from "../ResponseManagement/ResponseManagement";
import * as PERMISSIONS from "../../Functions/PermissionsFunctions";
import Docstorage from "../Docstorage/Docstorage";
import DuplicateQueue from "../DuplicateQueue/DuplicateQueue";
import Auditlog from "../Auditlog/Auditlog";


export default function Navigation() {
    const [value, setValue] = React.useState(0);
    const [sellerRowData, setSellerRowData] = useState([]);
    const [projects, setProjects] = useState([]);
    const[selectedOption, setProjectId] = useState();
    let selectStyle = { fontSize: 25 };
    const[buyerPermission, setBuyerPermission] = useState(false);
    const[adminPermission, setAdminPermission] = useState(false);
    const[sellerPermission, setSellerPermission] = useState(false);
    const[cantSeePermission, setCantSeePermission] = useState(false);
    const[updateProject, setUpdateProject] = useState();
    const ADMIN = CONSTANTS.ADMIN;
    const BUYER = CONSTANTS.BUYER;
    const SELLER = CONSTANTS.SELLER;
    const parsePermissions = PERMISSIONS.parsePermissions;
    const LogoutWrapper = () => {
        localStorage.removeItem('projectId');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('userId');
        localStorage.removeItem('permission');
        window.location.href = '/';
    };

    useEffect( () => {
        const token = localStorage.getItem("accessToken");
        axios.get(process.env.REACT_APP_API_BASE_URL + '/api/v1/projects?active=1',
            { headers: {
                    "Authorization" : `Bearer ${token}`
                }})
            .then(function (response) {
                setProjects(response['data']['projects']);
            })
            .catch(function (error) {
                console.log(error);
            })
    }, [updateProject]);

    useEffect( () => {
        setAdminPermission(false);
        setSellerPermission(false);
        setBuyerPermission(false);
        parsePermissions(setBuyerPermission, setSellerPermission, setAdminPermission)
    }, [BUYER, SELLER, ADMIN]);

    function handleSelectChange(event) {
        const projectId = event.target.value;
        setProjectId(projectId);
        localStorage.setItem('projectId', projectId);
        window.dispatchEvent(new Event("projectId"));
    }

    const router = createBrowserRouter([
        {
            element: (
                <>
                    <BottomNavigation
                        showLabels
                        value={value}
                        onChange={(event, newValue) => {
                            setValue(newValue);
                        }}
                    >
                        <BottomNavigationAction
                        component={Link}
                        label="Profile"
                        to='/'

                        />
                        {adminPermission && <BottomNavigationAction
                            component={Link}
                            label="Project Admin"
                            to='/projectCreation'

                        />}
                        {adminPermission && <BottomNavigationAction
                            component={Link}
                            label="Entity Management"
                            to='/entity'
                        />}
                        {cantSeePermission && <BottomNavigationAction
                            component={Link}
                            label="Roles/Permissions Management"
                            to='/rolesManagement'
                        />}
                        {adminPermission && <BottomNavigationAction
                            component={Link}
                            label="User Management"
                            to='/user'
                        />}
                        {adminPermission && <BottomNavigationAction
                            component={Link}
                            label="Project Assignment"
                            to='/thirdPartyAssignment'
                        />}
                        {buyerPermission && <BottomNavigationAction
                            component={Link}
                            label="Buyer Requests"

                            to='/buyersListManagement'
                        /> }
                        {adminPermission && <BottomNavigationAction
                            component={Link}
                            label="Buyer Advisor Response Managment"

                            to='/responseManagement'
                        /> }
                        {adminPermission && <BottomNavigationAction
                            component={Link}
                            label="Request Management"

                            to='/dmo'
                        />}
                        {sellerPermission && <BottomNavigationAction
                            component={Link}
                            label="Seller Requests"

                            to='/sellerList'
                        />}
                        {adminPermission && <BottomNavigationAction
                            component={Link}
                            label="Seller Duplicates"

                            to='/seller_duplicates'
                        />}
                        {buyerPermission && <BottomNavigationAction
                            component={Link}
                            label="Document Storage"

                            to='/docstorage'
                        /> }
                        {adminPermission && <BottomNavigationAction
                            component={Link}
                            label="Auditlog"

                            to='/auditlog'
                        /> }
                        <BottomNavigationAction
                            component={Link}
                            label="Logout"

                            to="/logout"
                        />
                    </BottomNavigation>
                    <Outlet />
                </>
            ),
            children: [
                { path: "buyersListManagement", element: <BuyersList />, },
                { path: "", element: <Profile />, },
                { path: "dmo", element: <DataRequestManagement />, },
                { path: "entity", element: <EntityManagement />, },
                { path: "logout", element: <LogoutWrapper />, },
                { path: "projectCreation", element: <Projects setUpdateProject={setUpdateProject} />, },
                { path: "sellerList",
                    element: <SellerList showCheckboxes={false} setSellerRequest=""
                                         sellerRowData={sellerRowData}
                                         setSellerRowData={setSellerRowData}
                    />,
                },
                { path: "user", element: <User />, },
                { path: "workstreamManagement", element: <WorkstreamManagement />, },
                { path: "workstreamCategoryManagement", element: <WorkstreamCategoryManagement />, },
                { path: "thirdPartyAssignment", element: <ThirdPartyAssignment />},
                { path: "rolesManagement", element: <Roles />},
                { path: "responseManagement", element: <ResponseManagement />},
                { path: "docstorage", element:  <Docstorage/>},
                { path: "seller_duplicates", element:  <DuplicateQueue/>},
                { path: "auditlog", element:  <Auditlog/>},
            ],
        },
    ]);

    return (
        <div>
            <select style={selectStyle} onChange={handleSelectChange} value={selectedOption}>
                <option>Select A Project</option>
                {projects && projects.map((project,i)=>{
                    return (
                        <option id={project.id} value={project.id}>{project.project_name} Batch: {project.batch}</option>
                    )
                })}
            </select>
            <br/>
            <br/>
            <br/>
        <Box>
            <RouterProvider router={router} />
        </Box>
        </div>
    );
}
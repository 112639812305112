import axios from "axios";
const token = localStorage.getItem("accessToken");

export function fetchAuditlog(setAuditlogs, auditlogFilteredData, sorting, setFilterOpen, setSortOpen){
    let params = '';
    if (auditlogFilteredData) {
        if (auditlogFilteredData.id !== "") { params += "&id="+ auditlogFilteredData.id; }
        if (auditlogFilteredData.timestamp !== "") { params += "&timestamp="+ auditlogFilteredData.timestamp }
        if (auditlogFilteredData.log_entry !== "") { params += "&log_entry="+ auditlogFilteredData.log_entry; }
    }

    if (sorting) {
        if (sorting.sort_one !== "" && sorting.sort_one !== "0") { params += "&sort="+sorting.sort_one +","+ sorting.sort_one_direction; }
        if (sorting.sort_two !== "" && sorting.sort_two !== "0") { params += "&sort="+sorting.sort_two +","+ sorting.sort_two_direction; }
        if (sorting.sort_three !== "" && sorting.sort_three !== "0") { params += "&sort="+sorting.sort_three +","+ sorting.sort_three_direction; }
    }

    axios.get(process.env.REACT_APP_API_BASE_URL + '/api/v1/auditlog?' + params,
        { headers: {
                "Authorization" : `Bearer ${token}`
            }})
        .then(function (response) {
            setAuditlogs(response['data']['auditlogs']);
            setFilterOpen(false);
            setSortOpen();
        })
        .catch(function (error) {
            console.log(error);
        })
};
import {React, useEffect, useState} from "react";
import "./entity.css";
import {fetchEntities, fetchEntity} from "../../Functions/EntityFunctions";
import NewEntity from "../../components/Modals/NewEntity";
import {fetchEntityTypes} from "../../Functions/EntityTypes";
import {fetchProjects} from "../../Functions/ProjectsFunctions";

export default function EntityManagement() {
    const[action, setAction] = useState('POST');
    const[entityRowData, setEntityRowData] = useState([]);
    const[entity, setEntity] = useState([]);
    const[entityTypes, setEntityTypes] = useState([]);
    const[, setError] = useState();
    const[filtered, setFiltered] = useState();
    const[filteredData, setFilteredData] = useState({code: "", name: "", active: "", entity_type_id:""});
    const[filterOpen, setFilterOpen] = useState(false);
    const[modalIsOpen, setIsOpen] = useState(false);
    const[updateEntityList, setUpdateEntityList] = useState();
    const[entityListSorting, setEntityListSorting] = useState({
        sort_one: "",
        sort_one_direction: "asc",
        sort_two: "",
        sort_two_direction:"asc",
        sort_three: "",
        sort_three_direction:"asc",
        sort_four: "",
        sort_four_direction:"asc",
    });
    const sort_array = [
        {id: "id", name: "ID"},
        {id: "code", name: "Code"},
        {id: "entity_name", name: "Name"},
        {id: "active", name: "Active"},
    ];
    const[sortOpen, setSortOpen] = useState();

    useEffect( () => {
        fetchEntities(setEntityRowData, setUpdateEntityList, filteredData, setFilterOpen, entityListSorting, setSortOpen)
        fetchEntityTypes(setEntityTypes);
    }, [updateEntityList]);

    function clearFilter() {
        setFilteredData( {
            code: "", name: "", active: "", type:""
        });
        setUpdateEntityList(true);
        fetchEntities(setEntityRowData, setUpdateEntityList, filteredData, setFilterOpen, entityListSorting, setSortOpen)
    }

    function clearSort() {
        setEntityListSorting({
            sort_one: "",
            sort_one_direction: "asc",
            sort_two: "",
            sort_two_direction:"asc",
            sort_three: "",
            sort_three_direction:"asc",
            sort_four: "",
            sort_four_direction:"asc",
        });
        fetchEntities(setEntityRowData, setUpdateEntityList, filteredData, setFilterOpen, entityListSorting, setSortOpen)
        setUpdateEntityList(true)
    }

    function fetchEntityData(id) {
        setError(false);
        setAction('PUT');
        setIsOpen(true);
        fetchEntity(id, setEntity, setError);
    }

    const openSort = () => { setSortOpen(!sortOpen); };

    function saveEntityData() {
        setEntity([]);
        setIsOpen(true);
        setAction("POST");
    }

    return <>
        <h1>Entity Management</h1>
        <NewEntity
            modalIsOpen={modalIsOpen}
            setIsOpen={setIsOpen}
            action={action}
            setEntityRowData={setEntityRowData}
            setUpdateEntityList={setUpdateEntityList}
            entity={entity}
            filteredData={filteredData}
        />

        <button className="newEntityButton" onClick={() => {saveEntityData()}}>Create New Entity</button>
        <div> {/* Filter Code */}
            <button onClick={() => setFilterOpen(!filterOpen)}>Filter</button>
            <button onClick={()=>clearFilter()}>Clear Filter</button>
            <button onClick={openSort}>Sort</button>
            <button onClick={()=>clearSort()}>Clear Sort</button>
            {filterOpen ? (
                <ul className="menu">
                    {filtered && <h1>Data is Filtered</h1>}
                    <li className="menu-item">
                        Code
                        <input type="text" defaultValue={filteredData.code} placeholder="Code" onChange={(e) =>
                            setFilteredData( {...filteredData, code: e.target.value})}>
                        </input>
                    </li>

                    <li className="menu-item">
                        Name
                        <input type="text" defaultValue={filteredData.entity_name} placeholder="Name" onChange={(e) =>
                            setFilteredData({...filteredData, entity_name:  e.target.value})}>
                        </input>
                    </li>

                    <li className="menu-item">
                        Type <br/>
                        <select defaultValue={filteredData.entity_type_id} onChange={(e) =>
                                setFilteredData( {...filteredData, entity_type_id:  e.target.value})}>
                            <option>Entity Type</option>
                            {entityTypes && entityTypes.map((entityType,i)=> {
                                return (
                                    <option value={entityType.id}>{entityType.entity_type}</option>
                                )
                            })}
                        </select>
                    </li>

                    <li className="menu-item">
                        Active <br/>
                        <select defaultValue={filteredData.active} onChange={(e) =>
                            setFilteredData( {...filteredData, active:  e.target.value})} >
                            <option>Select Active/Inactive</option>
                            <option value={true}>Active</option>
                            <option value={false}>Inactive</option>
                        </select>
                    </li>

                    <li>
                        <button onClick={() => fetchEntities(setEntityRowData, setUpdateEntityList, filteredData, setFilterOpen, entityListSorting, setSortOpen)}>Apply</button>
                    </li>
                </ul>
            ) : null}
            {sortOpen ? (
                <ul className="menu">
                    <li className="menu-item">
                        Sort By
                        <select name="id" defaultValue={entityListSorting.sort_one}
                                onChange={(e) =>
                                    setEntityListSorting({...entityListSorting, sort_one: e.target.value})}>
                            <option value={0}>Sort Column</option>
                            {sort_array.map((sort,i)=> {
                                return (
                                    <option key={sort.id} value={sort.id}>{sort.name}</option>
                                )
                            })}
                        </select>

                        <select name="sort_one_direction" defaultValue={entityListSorting.sort_one_direction}
                                onChange={(e) =>
                                    setEntityListSorting({...entityListSorting, sort_one_direction: e.target.value})}>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </select>
                    </li>

                    <li className="menu-item">
                        Then By
                        <select name="sort_two" defaultValue={entityListSorting.sort_two}
                                onChange={(e) =>
                                    setEntityListSorting({...entityListSorting, sort_two: e.target.value})}>
                            <option value={0}>Sort Column</option>
                            {sort_array.map((sort,i)=> {
                                return (
                                    <option key={sort.id} value={sort.id}>{sort.name}</option>
                                )
                            })}
                        </select>
                        <select name="sort_two_direction" defaultValue={entityListSorting.sort_two_direction}
                                onChange={(e) =>
                                    setEntityListSorting({...entityListSorting, sort_two_direction: e.target.value})}>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </select>
                    </li>
                    <li className="menu-item">
                        Then By
                        <select name="sort_three" defaultValue={entityListSorting.sort_three}
                                onChange={(e) =>
                                    setEntityListSorting({...entityListSorting, sort_three: e.target.value})}>
                            <option value={0}>Sort Column</option>
                            {sort_array.map((sort,i)=> {
                                return (
                                    <option key={sort.id} value={sort.id}>{sort.name}</option>
                                )
                            })}
                        </select>
                        <select name="sort_three_direction" defaultValue={entityListSorting.sort_three_direction}
                                onChange={(e) =>
                                    setEntityListSorting({...entityListSorting, sort_three_direction: e.target.value})}>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </select>
                    </li>

                    <li className="menu-item">
                        Then By
                        <select name="sort_four" defaultValue={entityListSorting.sort_four}
                                onChange={(e) =>
                                    setEntityListSorting({...entityListSorting, sort_four: e.target.value})}>
                            <option value={0}>Sort Column</option>
                            {sort_array.map((sort,i)=> {
                                return (
                                    <option key={sort.id} value={sort.id}>{sort.name}</option>
                                )
                            })}
                        </select>
                        <select name="sort_four_direction" defaultValue={entityListSorting.sort_four_direction}
                                onChange={(e) =>
                                    setEntityListSorting({...entityListSorting, sort_four_direction: e.target.value})}>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </select>
                    </li>

                    <li className="menu-item">
                        Then By
                        <select name="sort_five" defaultValue={entityListSorting.sort_five}
                                onChange={(e) =>
                                    setEntityListSorting({...entityListSorting, sort_five: e.target.value})}>
                            <option value={0}>Sort Column</option>
                            {sort_array.map((sort,i)=> {
                                return (
                                    <option key={sort.id} value={sort.id}>{sort.name}</option>
                                )
                            })}
                        </select>
                        <select name="sort_five_direction" defaultValue={entityListSorting.sort_five_direction}
                                onChange={(e) =>
                                    setEntityListSorting({...entityListSorting, sort_five_direction: e.target.value})}>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </select>
                    </li>

                    <li className="menu-item">
                        Then By
                        <select name="sort_six" defaultValue={entityListSorting.sort_six}
                                onChange={(e) =>
                                    setEntityListSorting({...entityListSorting, sort_six: e.target.value})}>
                            <option value={0}>Sort Column</option>
                            {sort_array.map((sort,i)=> {
                                return (
                                    <option key={sort.id} value={sort.id}>{sort.name}</option>
                                )
                            })}
                        </select>
                        <select name="sort_six_direction" defaultValue={entityListSorting.sort_six_direction}
                                onChange={(e) =>
                                    setEntityListSorting({...entityListSorting, sort_six_direction: e.target.value})}>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </select>
                    </li>
                    <li className="menu-item">
                        Then By
                        <select name="sort_seven" defaultValue={entityListSorting.sort_seven}
                                onChange={(e) =>
                                    setEntityListSorting({...entityListSorting, sort_seven: e.target.value})}>
                            <option value={0}>Sort Column</option>
                            {sort_array.map((sort,i)=> {
                                return (
                                    <option key={sort.id} value={sort.id}>{sort.name}</option>
                                )
                            })}
                        </select>
                        <select name="sort_seven_direction" defaultValue={entityListSorting.sort_seven_direction}
                                onChange={(e) =>
                                    setEntityListSorting({...entityListSorting, sort_seven_direction: e.target.value})}>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </select>
                    </li>
                    <li className="menu-item">
                        Then By
                        <select name="sort_eight" defaultValue={entityListSorting.sort_eight}
                                onChange={(e) =>
                                    setEntityListSorting({...entityListSorting, sort_eight: e.target.value})}>
                            <option value={0}>Sort Column</option>
                            {sort_array.map((sort,i)=> {
                                return (
                                    <option key={sort.id} value={sort.id}>{sort.name}</option>
                                )
                            })}
                        </select>
                        <select name="sort_eight_direction" defaultValue={entityListSorting.sort_eight_direction}
                                onChange={(e) =>
                                    setEntityListSorting({...entityListSorting, sort_eight_direction: e.target.value})}>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </select>
                    </li>
                    <li className="menu-item">
                        Then By
                        <select name="sort_nine" defaultValue={entityListSorting.sort_nine}
                                onChange={(e) =>
                                    setEntityListSorting({...entityListSorting, sort_nine: e.target.value})}>
                            <option value={0}>Sort Column</option>
                            {sort_array.map((sort,i)=> {
                                return (
                                    <option key={sort.id} value={sort.id}>{sort.name}</option>
                                )
                            })}
                        </select>
                        <select name="sort_nine_direction" defaultValue={entityListSorting.sort_nine_direction}
                                onChange={(e) =>
                                    setEntityListSorting({...entityListSorting, sort_nine_direction: e.target.value})}>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </select>
                    </li>
                    <li className="menu-item">
                        Then By
                        <select name="sort_ten" defaultValue={entityListSorting.sort_ten}
                                onChange={(e) =>
                                    setEntityListSorting({...entityListSorting, sort_ten: e.target.value})}>
                            <option value={0}>Sort Column</option>
                            {sort_array.map((sort,i)=> {
                                return (
                                    <option key={sort.id} value={sort.id}>{sort.name}</option>
                                )
                            })}
                        </select>
                        <select name="sort_ten_direction" defaultValue={entityListSorting.sort_ten_direction}
                                onChange={(e) =>
                                    setEntityListSorting({...entityListSorting, sort_ten_direction: e.target.value})}>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </select>
                    </li>
                    <li className="menu-item">
                        Then By
                        <select name="sort_eleven" defaultValue={entityListSorting.sort_eleven}
                                onChange={(e) =>
                                    setEntityListSorting({...entityListSorting, sort_eleven: e.target.value})}>
                            <option value={0}>Sort Column</option>
                            {sort_array.map((sort,i)=> {
                                return (
                                    <option key={sort.id} value={sort.id}>{sort.name}</option>
                                )
                            })}
                        </select>
                        <select name="sort_eleven_direction" defaultValue={entityListSorting.sort_eleven_direction}
                                onChange={(e) =>
                                    setEntityListSorting({...entityListSorting, sort_eleven_direction: e.target.value})}>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </select>
                    </li>
                    <li className="menu-item">
                        Then By
                        <select name="sort_twelve" defaultValue={entityListSorting.sort_twelve}
                                onChange={(e) =>
                                    setEntityListSorting({...entityListSorting, sort_twelve: e.target.value})}>
                            <option value={0}>Sort Column</option>
                            {sort_array.map((sort,i)=> {
                                return (
                                    <option key={sort.id} value={sort.id}>{sort.name}</option>
                                )
                            })}
                        </select>
                        <select name="sort_twelve_direction" defaultValue={entityListSorting.sort_twelve_direction}
                                onChange={(e) =>
                                    setEntityListSorting({...entityListSorting, sort_twelve_direction: e.target.value})}>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </select>
                    </li>

                    <li>
                        <button onClick={() => fetchEntities(setEntityRowData, setUpdateEntityList, filteredData, setFilterOpen, entityListSorting, setSortOpen)}>Apply</button>
                    </li>
                </ul>
            ) : null}
        </div>

        <table className="entityListTable">
            <thead>
            <tr className="outline">
                <td className="outline">ID</td>
                <td className="outline">Code</td>
                <td className="outline">Name</td>
                <td className="outline">Active</td>
                <td></td>
            </tr>
            </thead>
            <tbody>
            {entityRowData && entityRowData.map((entityData,i)=>{
                const entityId = entityData.id;
                return (
                    <tr key={entityData.id} className="outline">
                        <td className="outline">{entityData.id}</td>
                        <td className="outline">{entityData.code}</td>
                        <td className="outline">{entityData.entity_name}</td>
                        <td className="outline">{entityData.active===true ? "Active" : "Inactive"}</td>
                        <td><button onClick={() => {fetchEntityData(entityId)}}>
                            Edit
                        </button></td>
                    </tr>
                )
            })}
            </tbody>
        </table>
    </>

}
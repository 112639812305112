import {React, useState} from 'react';
import {useForm} from "react-hook-form";
import './login.css'
import axios from "axios";
import { jwtDecode } from 'jwt-decode';
import * as CONSTANTS from "../../Constants";
import QRious from "qrious";

export default function Login() {
    const { register, handleSubmit } = useForm();
    const ADMIN = CONSTANTS.ADMIN;
    const BUYER = CONSTANTS.BUYER;
    const SELLER = CONSTANTS.SELLER;
    const[qrCode, setQRCode] = useState();
    const[error, setError] = useState();

    function generateOTP(data) {
        axios.post(process.env.REACT_APP_API_BASE_URL + '/auth/v1/otp/generate', data)
            .then(response => {
                const qr = new QRious({
                    value: response.data.otpauth_url,
                });
                const dataURL = qr.toDataURL();
                setQRCode(dataURL);
            }).catch(function (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                console.log(error.request);
            } else {
                // Something happened in setting up the request that triggered an Error
                console.log('Error', error.message);
            }
            console.log(error.config);
        });
    }

    function login(data) {
        axios.post(process.env.REACT_APP_API_BASE_URL + '/auth/v1/login', data)
            .then(response => {

                const message = response.data.message;
                if (message && message == 'generate_otp_token') {
                    generateOTP(data);
                    return;
                }
                const jwt = response.data.jwt;
                const user = jwtDecode(jwt);

                const permissions = user.permissions;
                localStorage.setItem("accessToken", jwt);
                localStorage.setItem("userId", user.user_id);
                localStorage.setItem("permission", JSON.stringify(permissions));

                if (permissions?.find((el) => el.name === ADMIN)?.name === ADMIN) {
                    window.location.href = window.location + "dmo";
                } else if (permissions?.find((el) => el.name === SELLER)?.name === SELLER) {
                    window.location.href = window.location + "sellerList";
                } else if (permissions?.find((el) => el.name === BUYER)?.name === BUYER) {
                    window.location.href = window.location + "buyersListManagement";
                } else {
                    localStorage.removeItem('accessToken');
                    localStorage.removeItem("userId");
                    localStorage.removeItem("permission");
                    window.location.href = "/login";
                }
            })
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    setError("Login Failed");
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
            });
    }
    return(
        <form onSubmit={handleSubmit(data => login(data))}>
            {error && <h1 className="userError">{error}</h1>}
            <p><b>Username and Password are Case Sensitive</b></p>
            <label>
                <p>Username</p>
                <input type="text" {...register('user_name')} />
            </label>
            <label>
                <p>Password</p>
                <input type="password" {...register('password')} />
            </label>
            <label>
                <p>MFA Code (if you have not setup MFA, hit Submit to get QR Code)</p>
                <input type="otp" {...register('otp')} />
            </label>
            <div>
                <button type="submit">Submit</button>
            </div>
            {qrCode && <img style={{ width: "200px", height: "200px" }} src={qrCode}/>}
        </form>
    )
}
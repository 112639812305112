import axios from "axios";
import * as CONSTANTS from "../Constants";
import {EMAIL_ERROR_MESSAGE, INVALID_ENTITY_TYPE, THIRD_PARTY_ERROR_MESSAGE} from "../Constants";
const token = localStorage.getItem("accessToken");

export function fetchThirdPartyAssignment(id, setThirdPartyAssignment, setError) {
    axios.get(process.env.REACT_APP_API_BASE_URL + '/api/v1/project_entity_third_parties/'+ id,
        { headers: {
                "Authorization" : `Bearer ${token}`
            }})
        .then(response => {
            setThirdPartyAssignment(response.data.project_entity_third_party);
            setError(false);
        })
        .catch(function (error) {
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
            console.log(error.config);
        });
}

export function fetchThirdPartyAssignments(setThirdPartyAssignments, reset) {
    let params = '';
    axios.get(process.env.REACT_APP_API_BASE_URL + '/api/v1/project_entity_third_parties?'+ params,
        { headers: {
                "Authorization" : `Bearer ${token}`
            }})
        .then(response => {
            setThirdPartyAssignments(response.data.project_entity_third_parties);
            if (reset) { reset(); }
        })
        .catch(function (error) {
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            } else if (error.request) {
                console.log(error.request);
            } else {
                console.log('Error', error.message);
            }
            console.log(error.config);
        });
}

export function saveThirdPartyAssignments({fetchThirdPartyAssignments, setError, reset, setIsOpen, data, action, setThirdPartyAssignments}) {
    if (data.active === 'Select Active/Inactive' || data.active === '' ) { setError(CONSTANTS.ACTIVE_ERROR_MESSAGE); return;}
    if (data.email_list === undefined) { setError(CONSTANTS.EMAIL_ERROR_MESSAGE); return;}
    if (data.entity_type === '') { setError(CONSTANTS.INVALID_ENTITY_TYPE); return;}

    data.active = data.active === 'true' || data.active === true;
    if (action === "POST") {
        if (data.project_id === 'Select a Project') { setError(CONSTANTS.PROJECT_ERROR_MESSAGE); return;}
        if (data.third_party_id === 'Select A Third Party') { setError(CONSTANTS.THIRD_PARTY_ERROR_MESSAGE); return;}
        data.project_id = parseInt(data.project_id);
        data.third_party_id = parseInt(data.third_party_id);
        axios.post(process.env.REACT_APP_API_BASE_URL + '/api/v1/project_entity_third_parties', data,
            { headers: {
                    "Authorization" : `Bearer ${token}`
                }})
            .then(data => {
                fetchThirdPartyAssignments(setThirdPartyAssignments, reset);
                setError(false);
                reset();
                setIsOpen(false);
            })
            .catch(function (error) {
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error.message);
                }
                console.log(error.config);
            });
    } else if (action === "PUT") {

        const json_data = {
            "active": data.active,
            "email_list": data.email_list,
            "category_id": data.category_id.toString()
        }
        axios.put(process.env.REACT_APP_API_BASE_URL + '/api/v1/project_entity_third_parties/' + data.id,
            json_data,
            { headers: {
                    "Authorization" : `Bearer ${token}`
                }})
            .then(data => {
                fetchThirdPartyAssignments(setThirdPartyAssignments, reset);
                setError(false);
                reset();
                setIsOpen(false);
            })
            .catch(function (error) {
                if (error.response) {
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    console.log('Error', error.message);
                }
                console.log(error.config);
            });
    }
}


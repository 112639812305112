import {React, useEffect, useState} from "react";
import { useForm } from "react-hook-form";
import {customStyles} from "../../Constants";
import {fetchProjects, fetchProject, saveProject} from '../../Functions/ProjectsFunctions'
import Modal from "react-modal";
import {fetchEntities} from "../../Functions/EntityFunctions";
import NewEntity from "../../components/Modals/NewEntity";
import {fetchEntityTypes} from "../../Functions/EntityTypes";

export default function Projects({setUpdateProject}) {
    const { register, handleSubmit , reset,setValue} = useForm();
    const[action, setAction] = useState();
    const[entity, setEntity] = useState([]);
    const[entities, setEntities]=useState([]);
    const[buyerEntities, setBuyerEntities]=useState([]);
    const[sellerEntities, setSellerEntities]=useState([]);
    const[entityTypes, setEntityTypes] = useState([]);
    const[entityModalIsOpen, setIsEntityModalOpen] = useState(false);
    const[error, setError] = useState();
    const[filterOpen, setFilterOpen] = useState();
    const[decrementModal, setDecrementModal] = useState(false);
    const[incrementModal, setIncrementModal] = useState(false);
    const[filteredData, setFilteredData] = useState({
        "active": "",
        "project_name": "",
        "seller_entity_id": "",
        "buyer_entity_id": "",
        "batch": "",
    });
    const[decrementActiveFlag, setProjectActiveFlag] = useState();
    const[decrementProjectBatch, setProjectBatch] = useState();
    const[decrementProjectId, setProjectId] = useState();
    const[modalIsOpen, setModalIsOpen] = useState(false);
    const[project, setProject] = useState([]);
    const[projects, setProjects] = useState([]);
    const[updateEntityList, setUpdateEntityList] = useState();
    const[projectListSorting, setProjectListSorting] = useState({
        sort_one: "",
        sort_one_direction: "asc",
        sort_two: "",
        sort_two_direction:"asc",
        sort_three: "",
        sort_three_direction:"asc",
        sort_four: "",
        sort_four_direction:"asc",
        sort_five: "",
        sort_five_direction:"asc",
        sort_six: "",
        sort_six_direction:"asc",
        sort_seven: "",
        sort_seven_direction:"asc",
    });
    const sort_array = [
        {id: "project.active", name: "Active"},
        {id: "project_name", name: "Project Name"},
        {id: "seller_entity_id", name: "Seller"},
        {id: "seller_email_list", name: "Seller Distribution List"},
        {id: "buyer_entity_id", name: "Buyer"},
        {id: "buyer_email_list", name: "Buyer Distribution List"},
        {id: "batch", name: "Phase"},
    ];
    const[entityListSorting, setEntityListSorting] = useState({
        sort_one: "code",
        sort_one_direction: "asc",
        sort_two: "",
        sort_two_direction:"asc",
        sort_three: "",
        sort_three_direction:"asc",
        sort_four: "",
        sort_four_direction:"asc",
    });
    const[sortOpen, setSortOpen] = useState();
    
    useEffect(() => {
        fetchEntityTypes(setEntityTypes);
        fetchProjects(filteredData, setProjects, setFilterOpen, projectListSorting, setSortOpen);
    }, []);

    useEffect(()=>{
        const entityFilteredData = {code: "", name: "", active: "true", entity_type_id:"", entity_type_name:"Seller"}
        fetchEntities(setSellerEntities, setUpdateEntityList, entityFilteredData, setFilterOpen, entityListSorting, setSortOpen);
    }, [updateEntityList, entityTypes]);


    useEffect(() => {
        const entityFilteredData = {code: "", name: "", active: "true", entity_type_id:"", entity_type_name:"Buyer"}
        fetchEntities(setBuyerEntities, setUpdateEntityList, entityFilteredData, setFilterOpen, entityListSorting, setSortOpen);
    }, [updateEntityList, entityTypes])

    function clearSort() {
        setProjectListSorting({
            sort_one: "",
            sort_one_direction: "asc",
            sort_two: "",
            sort_two_direction:"asc",
            sort_three: "",
            sort_three_direction:"asc",
            sort_four: "",
            sort_four_direction:"asc",
            sort_five: "",
            sort_five_direction:"asc",
            sort_six: "",
            sort_six_direction:"asc",
            sort_seven: "",
            sort_seven_direction:"asc",
        });
        fetchProjects(filteredData, setProjects, setFilterOpen, projectListSorting, setSortOpen)
    }

    function fetchProjectData(projectId) {
        setError(false);
        setAction('PUT');
        setModalIsOpen(true);
        fetchProject(projectId, setProject);
    }

    const openSort = () => { setSortOpen(!sortOpen); };

    function saveProjectData() {
        setProject([]);
        reset();
        setModalIsOpen(true);
        setAction("POST");
    }

    function openIncrementModal(projectId, batch, activeFlag) {
        setIncrementModal(true);
        setProjectId(projectId);
        setProjectBatch(batch);
        setProjectActiveFlag(activeFlag);
    }

    function incrementBatch(projectId, batch, active) {
        // when a new request is set to the current phase, it should add todays date
        reset();
        setAction('PUT');
        let data = {};
        data['batch'] = decrementProjectBatch + 1;
        data['active'] = decrementActiveFlag;
        data['id'] = decrementProjectId;
        saveProject(data, setError, filteredData, setProjects, setFilterOpen, setModalIsOpen, action);
        setIncrementModal(false);
    }

    function openDecrementModal(projectId, batch, activeFlag) {
        setDecrementModal(true);
        setProjectId(projectId);
        setProjectBatch(batch);
        setProjectActiveFlag(activeFlag);
    }
    
    function decrementBatch() {
        reset();
        setAction('PUT');
        let data = {};
        data['batch'] = decrementProjectBatch - 1;
        data['active'] = decrementActiveFlag;
        data['id'] = decrementProjectId;
        saveProject(data, setError, filteredData, setProjects, setFilterOpen, setModalIsOpen, action);
        setDecrementModal(false);
    }

    function saveEntityData() {
        setEntity([]);
        setIsEntityModalOpen(true);
        setAction("POST");
    }

    return <>
        <h1>Project Management</h1>

        <NewEntity
            modalIsOpen={entityModalIsOpen}
            setIsOpen={setIsEntityModalOpen}
            action={action}
            setEntityRowData={setEntities}
            setUpdateEntityList={setUpdateEntityList}
            entity={entity}
            filteredData={filteredData}
        />
        
        {/*Creation/Update Code*/}
        <Modal isOpen={modalIsOpen} style={customStyles} ariaHideApp={false}>
            {action === 'PUT' && <h2>Update Project</h2>}
            {action === 'POST' && <h2>New Project</h2>}
            {error && <h1 className="userError">{error}</h1>}
            <button onClick={() => setModalIsOpen(false)}>close</button>
            <form onSubmit={handleSubmit(data =>
                saveProject(data, setError, filteredData, setProjects, setFilterOpen, setModalIsOpen, action))
            }>
                {action == "PUT" && setValue('id', project.id)}
                {action == "PUT" && <input name="id" defaultValue={project.id} {...register('id')} hidden/>}

                {action == "PUT" && setValue('active', project.active)}
                <label>Active</label>
                <select name="active" {...register("active")}>
                    <option value={false}>Inactive</option>
                    <option value={true}>Active</option>
                </select>

                {action == "PUT" && setValue('project_name', project.project_name)}
                <label>Name</label>
                <input name="project_name" {...register('project_name')} />

                {action == "PUT" && setValue('admin_name', project.admin_name)}
                <label>Admin Name for Notifications</label>
                <input name="admin_name" {...register('admin_name')} />

                {action == "PUT" && setValue('admin_email', project.admin_email)}
                <label>Admin Email <em>Comma separated list</em></label>
                <input name="admin_email" {...register('admin_email')} />

                {action == "POST" && <>
                    <label>Seller</label>
                    <select name="seller_entity_id" {...register('seller_entity_id')} >
                        <option>Select Seller</option>
                        {sellerEntities && sellerEntities.map((entity,i)=> {
                            return (
                                <option value={entity.id}>{entity.entity_name}</option>
                            )
                        })}
                    </select>
                </>}

                {action == "PUT" && setValue('seller_email_list', project.seller_email_list)}
                <label>Seller Distribution List <em>Comma separated list</em></label>
                <input name="seller_email_list" {...register('seller_email_list')} />

                {action == "POST" && <>
                    <label>Buyer <em>Comma separate list</em></label>
                    <select name="buyer_entity_id" {...register('buyer_entity_id')} >
                        <option>Select Buyer</option>
                        {buyerEntities && buyerEntities.map((entity,i)=> {
                            return (
                                <option value={entity.id}>{entity.entity_name}</option>
                            )
                        })}
                    </select>
                </>}

                {action == "PUT" && setValue('buyer_email_list', project.buyer_email_list)}
                <label>Buyer Distribution List <em>Comma separated list</em></label>
                <input name="buyer_email_list" {...register('buyer_email_list')} />

                {action === 'POST' && <input type="submit" value="Create New Project" className="saveNewBuyerRequestButton"/>}
                {action === 'PUT' && <input type="submit" value="Update Project" className="saveNewBuyerRequestButton"/>}
            </form>
        </Modal>

        <Modal isOpen={decrementModal} style={customStyles} ariaHideApp={false}>
            <button onClick={() => setDecrementModal(false)}>close</button>
            <p>Decrementing the batch may cause confusion on the Seller's part.</p>
            <p>Are you sure?</p>
            <button className="newEntityButton" onClick={() => {decrementBatch()}}>
                Decrement Phase
            </button>
        </Modal>
        <Modal isOpen={incrementModal} style={customStyles} ariaHideApp={false}>
            <button onClick={() => setIncrementModal(false)}>close</button>
            <p>Are you sure?</p>
            <button className="newEntityButton" onClick={() => {incrementBatch()}}>
                Increment Phase
            </button>
        </Modal>
        <button className="newEntityButton" onClick={() => {saveProjectData()}}>Create New Project</button>
        <button className="newEntityButton" onClick={() => {saveEntityData()}}>Create New Entity</button>
        <button onClick={openSort}>Sort</button>
        <button onClick={()=>clearSort()}>Clear Sort</button>
        {sortOpen ? (
            <ul className="menu">
                <li className="menu-item">
                    Sort By
                    <select name="id" defaultValue={projectListSorting.sort_one}
                            onChange={(e) =>
                                setProjectListSorting({...projectListSorting, sort_one: e.target.value})}>
                        <option value={0}>Sort Column</option>
                        {sort_array.map((sort,i)=> {
                            return (
                                <option key={sort.id} value={sort.id}>{sort.name}</option>
                            )
                        })}
                    </select>

                    <select name="sort_one_direction" defaultValue={projectListSorting.sort_one_direction}
                            onChange={(e) =>
                                setProjectListSorting({...projectListSorting, sort_one_direction: e.target.value})}>
                        <option value="asc">Ascending</option>
                        <option value="desc">Descending</option>
                    </select>
                </li>

                <li className="menu-item">
                    Then By
                    <select name="sort_two" defaultValue={projectListSorting.sort_two}
                            onChange={(e) =>
                                setProjectListSorting({...projectListSorting, sort_two: e.target.value})}>
                        <option value={0}>Sort Column</option>
                        {sort_array.map((sort,i)=> {
                            return (
                                <option key={sort.id} value={sort.id}>{sort.name}</option>
                            )
                        })}
                    </select>
                    <select name="sort_two_direction" defaultValue={projectListSorting.sort_two_direction}
                            onChange={(e) =>
                                setProjectListSorting({...projectListSorting, sort_two_direction: e.target.value})}>
                        <option value="asc">Ascending</option>
                        <option value="desc">Descending</option>
                    </select>
                </li>
                <li className="menu-item">
                    Then By
                    <select name="sort_three" defaultValue={projectListSorting.sort_three}
                            onChange={(e) =>
                                setProjectListSorting({...projectListSorting, sort_three: e.target.value})}>
                        <option value={0}>Sort Column</option>
                        {sort_array.map((sort,i)=> {
                            return (
                                <option key={sort.id} value={sort.id}>{sort.name}</option>
                            )
                        })}
                    </select>
                    <select name="sort_three_direction" defaultValue={projectListSorting.sort_three_direction}
                            onChange={(e) =>
                                setProjectListSorting({...projectListSorting, sort_three_direction: e.target.value})}>
                        <option value="asc">Ascending</option>
                        <option value="desc">Descending</option>
                    </select>
                </li>

                <li className="menu-item">
                    Then By
                    <select name="sort_four" defaultValue={projectListSorting.sort_four}
                            onChange={(e) =>
                                setProjectListSorting({...projectListSorting, sort_four: e.target.value})}>
                        <option value={0}>Sort Column</option>
                        {sort_array.map((sort,i)=> {
                            return (
                                <option key={sort.id} value={sort.id}>{sort.name}</option>
                            )
                        })}
                    </select>
                    <select name="sort_four_direction" defaultValue={projectListSorting.sort_four_direction}
                            onChange={(e) =>
                                setProjectListSorting({...projectListSorting, sort_four_direction: e.target.value})}>
                        <option value="asc">Ascending</option>
                        <option value="desc">Descending</option>
                    </select>
                </li>

                <li className="menu-item">
                    Then By
                    <select name="sort_five" defaultValue={projectListSorting.sort_five}
                            onChange={(e) =>
                                setProjectListSorting({...projectListSorting, sort_five: e.target.value})}>
                        <option value={0}>Sort Column</option>
                        {sort_array.map((sort,i)=> {
                            return (
                                <option key={sort.id} value={sort.id}>{sort.name}</option>
                            )
                        })}
                    </select>
                    <select name="sort_five_direction" defaultValue={projectListSorting.sort_five_direction}
                            onChange={(e) =>
                                setProjectListSorting({...projectListSorting, sort_five_direction: e.target.value})}>
                        <option value="asc">Ascending</option>
                        <option value="desc">Descending</option>
                    </select>
                </li>

                <li className="menu-item">
                    Then By
                    <select name="sort_six" defaultValue={projectListSorting.sort_six}
                            onChange={(e) =>
                                setProjectListSorting({...projectListSorting, sort_six: e.target.value})}>
                        <option value={0}>Sort Column</option>
                        {sort_array.map((sort,i)=> {
                            return (
                                <option key={sort.id} value={sort.id}>{sort.name}</option>
                            )
                        })}
                    </select>
                    <select name="sort_six_direction" defaultValue={projectListSorting.sort_six_direction}
                            onChange={(e) =>
                                setProjectListSorting({...projectListSorting, sort_six_direction: e.target.value})}>
                        <option value="asc">Ascending</option>
                        <option value="desc">Descending</option>
                    </select>
                </li>
                <li className="menu-item">
                    Then By
                    <select name="sort_seven" defaultValue={projectListSorting.sort_seven}
                            onChange={(e) =>
                                setProjectListSorting({...projectListSorting, sort_seven: e.target.value})}>
                        <option value={0}>Sort Column</option>
                        {sort_array.map((sort,i)=> {
                            return (
                                <option key={sort.id} value={sort.id}>{sort.name}</option>
                            )
                        })}
                    </select>
                    <select name="sort_seven_direction" defaultValue={projectListSorting.sort_seven_direction}
                            onChange={(e) =>
                                setProjectListSorting({...projectListSorting, sort_seven_direction: e.target.value})}>
                        <option value="asc">Ascending</option>
                        <option value="desc">Descending</option>
                    </select>
                </li>
                <li className="menu-item">
                    Then By
                    <select name="sort_eight" defaultValue={projectListSorting.sort_eight}
                            onChange={(e) =>
                                setProjectListSorting({...projectListSorting, sort_eight: e.target.value})}>
                        <option value={0}>Sort Column</option>
                        {sort_array.map((sort,i)=> {
                            return (
                                <option key={sort.id} value={sort.id}>{sort.name}</option>
                            )
                        })}
                    </select>
                    <select name="sort_eight_direction" defaultValue={projectListSorting.sort_eight_direction}
                            onChange={(e) =>
                                setProjectListSorting({...projectListSorting, sort_eight_direction: e.target.value})}>
                        <option value="asc">Ascending</option>
                        <option value="desc">Descending</option>
                    </select>
                </li>
                <li className="menu-item">
                    Then By
                    <select name="sort_nine" defaultValue={projectListSorting.sort_nine}
                            onChange={(e) =>
                                setProjectListSorting({...projectListSorting, sort_nine: e.target.value})}>
                        <option value={0}>Sort Column</option>
                        {sort_array.map((sort,i)=> {
                            return (
                                <option key={sort.id} value={sort.id}>{sort.name}</option>
                            )
                        })}
                    </select>
                    <select name="sort_nine_direction" defaultValue={projectListSorting.sort_nine_direction}
                            onChange={(e) =>
                                setProjectListSorting({...projectListSorting, sort_nine_direction: e.target.value})}>
                        <option value="asc">Ascending</option>
                        <option value="desc">Descending</option>
                    </select>
                </li>
                <li className="menu-item">
                    Then By
                    <select name="sort_ten" defaultValue={projectListSorting.sort_ten}
                            onChange={(e) =>
                                setProjectListSorting({...projectListSorting, sort_ten: e.target.value})}>
                        <option value={0}>Sort Column</option>
                        {sort_array.map((sort,i)=> {
                            return (
                                <option key={sort.id} value={sort.id}>{sort.name}</option>
                            )
                        })}
                    </select>
                    <select name="sort_ten_direction" defaultValue={projectListSorting.sort_ten_direction}
                            onChange={(e) =>
                                setProjectListSorting({...projectListSorting, sort_ten_direction: e.target.value})}>
                        <option value="asc">Ascending</option>
                        <option value="desc">Descending</option>
                    </select>
                </li>
                <li className="menu-item">
                    Then By
                    <select name="sort_eleven" defaultValue={projectListSorting.sort_eleven}
                            onChange={(e) =>
                                setProjectListSorting({...projectListSorting, sort_eleven: e.target.value})}>
                        <option value={0}>Sort Column</option>
                        {sort_array.map((sort,i)=> {
                            return (
                                <option key={sort.id} value={sort.id}>{sort.name}</option>
                            )
                        })}
                    </select>
                    <select name="sort_eleven_direction" defaultValue={projectListSorting.sort_eleven_direction}
                            onChange={(e) =>
                                setProjectListSorting({...projectListSorting, sort_eleven_direction: e.target.value})}>
                        <option value="asc">Ascending</option>
                        <option value="desc">Descending</option>
                    </select>
                </li>
                <li className="menu-item">
                    Then By
                    <select name="sort_twelve" defaultValue={projectListSorting.sort_twelve}
                            onChange={(e) =>
                                setProjectListSorting({...projectListSorting, sort_twelve: e.target.value})}>
                        <option value={0}>Sort Column</option>
                        {sort_array.map((sort,i)=> {
                            return (
                                <option key={sort.id} value={sort.id}>{sort.name}</option>
                            )
                        })}
                    </select>
                    <select name="sort_twelve_direction" defaultValue={projectListSorting.sort_twelve_direction}
                            onChange={(e) =>
                                setProjectListSorting({...projectListSorting, sort_twelve_direction: e.target.value})}>
                        <option value="asc">Ascending</option>
                        <option value="desc">Descending</option>
                    </select>
                </li>

                <li>
                    <button onClick={() => fetchProjects(filteredData, setProjects, setFilterOpen, projectListSorting, setSortOpen)}>Apply</button>
                </li>
            </ul>
        ) : null}
        <table className="entityListTable">
            <thead>
            <tr className="outline">
                <td className="outline">Active</td>
                <td className="outline">Project Name</td>
                <td className="outline">Seller</td>
                <td className="outline">Seller Distribution List</td>
                <td className="outline">Buyer</td>
                <td className="outline">Buyer Distribution List</td>
                <td className="outline">Phase</td>
                <td></td>
            </tr>
            </thead>
            <tbody>
            {projects && projects.map((project,i)=>{
                const projectId = project.id;
                const buyerEmailList = project.buyer_email_list;
                const sellerEmailList = project.seller_email_list;
                return (
                    <tr key={projectId} className="outline">
                        <td className="outline">{project.active===true ? "Active" : "Inactive"}</td>
                        <td className="outline">{project.project_name}</td>
                        <td className="outline">{project.seller_name}</td>
                        <td className="outline">{sellerEmailList.split(",").map((substring, index)=> {
                            return (
                                <div key={index}>
                                    <span>{substring}</span>
                                </div>
                            )
                        })}</td>
                        <td className="outline">{project.buyer_name}</td>
                        <td className="outline">{buyerEmailList.split(",").map((substring, index)=> {
                            return (
                                <div key={index}>
                                    <span>{substring}</span>
                                </div>
                                )
                            })}</td>
                        <td className="outline">{project.batch}</td>
                        <td>
                            <button onClick={() => {fetchProjectData(projectId)}}> Edit </button>
                            <button onClick={() => {openIncrementModal(projectId, project.batch, project.active)}}> Increment<br/>Phase</button>
                            <button onClick={() => {openDecrementModal(projectId, project.batch, project.active)}}> Decrement<br/>Phase</button>
                        </td>

                    </tr>
                )
            })}
            </tbody>
        </table>
    </>
}
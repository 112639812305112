import {React, useEffect, useState } from "react";
import './sellerList.css';
import Modal from "react-modal";
import {useForm} from "react-hook-form";
import FileUploader from "../../components/FileUploader/FileUploader";
import {customStyles} from "../../Constants";
import {getResponseData} from "../../Functions/Responses";
import {downloadFile} from "../../Functions/Files";
import SellerListFilter from "../../components/Filters/SellerFilter";
import {fetchResponse, saveNewResponse, updateResponse} from "../../Functions/Responses";
import {fetchSellerList} from "../../Functions/SellersList";
import ResponseViewModal from "../../components/Modals/ResponseViewModal";
import {fetchStatuses} from "../../Functions/StatusFunction";
import DuplicateSellerRequest from "../../components/Modals/DuplicateSellerRequest";

export default function SellerList({updateSellersListData}) {
    const { register, handleSubmit , reset} = useForm();
    const { register: registerUpdate, handleSubmit: handleSubmitUpdate , reset:resetUpdate} = useForm();
    const[action, setAction] = useState();
    const[dupeSellerRequestId, setDupeSellerRequestId] = useState();
    const[editResponseData, setEditResponseData] = useState([]);
    const[error, setError] = useState();
    const[files, setFiles] = useState([]);
    const[filterOpen, setFilterOpen] = useState(false);
    const[isLoading, setIsLoading] = useState(false);
    const[openDuplicateModalValue, setOpenDuplicateModal] = useState(false);
    const[projectId, setProjectId] = useState(localStorage.getItem('projectId'))
    const[responseError, setResponseError] = useState();
    const[ResponseModalOpen, setOpenResponseModal] = useState(false);
    const[ResponseViewModalOpen, setOpenViewResponseModal] = useState(false);
    const[sellerListRowData, setsellerListRowData] = useState([]);
    const[sellerRequest, setSellerRequest] = useState([]);
    const[sellerResponseRequestId, setSellerResponseRequestId] = useState('');
    const[sellerResponseViewData, setSellerResponseViewData] = useState([]);
    const[sellerReponseId, setSellerRepsonseId] = useState();
    const[sortOpen, setSortOpen] = useState();
    const[statuses, setStatuses] = useState([]);
    const[updateListData, setUpdateListData] = useState(false);
    const[sellerFilteredData, setSellerFilteredData] = useState({
        id: "",
        assigned_date: "",
        deliverable_phase: "",
        data_requested: "",
        parameters: "",
        details: "",
        description: "",
        status: "",
        batch: "",
        category_id: "",
        batch_enabled: true,
        deleted: false,
    });
    const[sellerListSorting, setSellerListSorting] = useState({
        sort_one: "",
        sort_one_direction: "asc",
        sort_two: "",
        sort_two_direction:"asc",
        sort_three: "",
        sort_three_direction:"asc",
        sort_four: "",
        sort_four_direction:"asc",
        sort_five: "",
        sort_five_direction:"asc",
        sort_six: "",
        sort_six_direction:"asc",
        sort_seven: "",
        sort_seven_direction:"asc",
        sort_eight: "",
        sort_eight_direction:"asc",
        sort_nine: "",
        sort_nine_direction:"asc",
    });
    const sort_array = [
        {id: "seller_request_id", name: "Request ID"},
        {id: "category.category_name", name: "Category"},
        {id: "batch", name: "Phase"},
        {id: "assigned_date", name: "Requested On"},
        {id: "data_requested", name: "Request Name"},
        {id: "parameters", name: "Date Range"},
        {id: "details", name: "Request Details"},
        {id: "file_type.file_type", name: "Preferred File Type"},
        {id: "status.status", name: "Status"},
    ];
    const openSort = () => { setSortOpen(!sortOpen); };

    window.addEventListener('projectId', () => {
        if (localStorage.getItem('projectId') === 'Select A Project') {
            localStorage.removeItem('projectId');
        } else {
            setProjectId(localStorage.getItem('projectId'));
        }
        setUpdateListData(true);
        reset();
    })

    function clearSellerFilter() {
        setSellerFilteredData( {
            id: "",
            assigned_date: "",
            deliverable_phase: "",
            data_requested: "",
            parameters: "",
            details: "",
            description: "",
            status: "",
            batch: "",
            category_id: "",
            batch_enabled: true
        });
        fetchSellerList(projectId, sellerFilteredData, setsellerListRowData, setFilterOpen, sellerListSorting, setSortOpen);
    }

    function clearSort() {
        setSellerListSorting({
            sort_one: "",
            sort_one_direction: "asc",
            sort_two: "",
            sort_two_direction:"asc",
            sort_three: "",
            sort_three_direction:"asc",
            sort_four: "",
            sort_four_direction:"asc",
            sort_five: "",
            sort_five_direction:"asc",
            sort_six: "",
            sort_six_direction:"asc",
            sort_seven: "",
            sort_seven_direction:"asc",
            sort_eight: "",
            sort_eight_direction:"asc",
            sort_nine: "",
            sort_nine_direction:"asc",
            sort_ten: "",
            sort_ten_direction:"asc",
            sort_eleven: "",
            sort_eleven_direction:"asc",
            sort_twelve: "",
            sort_twelve_direction:"asc",
        });
        fetchSellerList(projectId, sellerFilteredData, setsellerListRowData, setFilterOpen, sellerListSorting, setSortOpen);
        setUpdateListData(true);
    }
    
    const handleSellersListFetch = (e) => {
        setError(null);
        if (projectId === null) {
            setsellerListRowData([]);
            setUpdateListData(true);
            setError('Please select a project');
            return;
        }
        fetchSellerList(projectId, sellerFilteredData, setsellerListRowData, setFilterOpen, sellerListSorting, setSortOpen);
        reset();
    };

    useEffect(handleSellersListFetch, [updateSellersListData, updateListData, projectId]);
    useEffect(() => fetchStatuses(setStatuses), []);

    function openResponseModal(seller_request_id, seller_response_id, action, data) {
        reset();
        resetUpdate();
        if (action === "PUT") {
            setAction("PUT");
            fetchResponse(seller_response_id, resetUpdate, setEditResponseData, setResponseError);
        } else {
            setAction("POST");
        }
        setSellerRequest(data);
        setOpenViewResponseModal(false);
        setSellerResponseRequestId(seller_request_id);
        setSellerRepsonseId(seller_response_id);
        setOpenResponseModal(!ResponseModalOpen);
    }

    function openViewResponsesModal(sellerResponseRequestid, sellerRequest) {
        setSellerRequest(sellerRequest);
        getResponseData(sellerResponseRequestid, setSellerResponseViewData);
        setOpenViewResponseModal(!ResponseViewModalOpen);
    }

    function openDuplicateModal(sellerRequestId) {
        setDupeSellerRequestId(sellerRequestId);
        setOpenDuplicateModal(true);
    }

    return (
        <div>
            <Modal
                isOpen={ResponseModalOpen}
                onRequestClose={() => setOpenResponseModal(false)}
                style={customStyles}
                contentLabel="Seller Request Response"
                ariaHideApp={false}
            >
                <h2>Seller Request Response</h2>
                <button onClick={() => setOpenResponseModal(false)}>close</button>
                {isLoading && <h1>Loading....</h1>}
                {responseError && <h1 className="userError">{responseError}</h1>}
                {sellerRequest &&
                    <div>
                        <p><b>Data Requested:</b> {sellerRequest.data_requested}</p>
                        <p><b>Request Parameters:</b> {sellerRequest.parameters}</p>
                        <p><b>Details:</b> {sellerRequest.details}</p>
                        <p><b>File Format:</b> {sellerRequest.file_format}</p>
                    </div>
                }
                {action === "POST" &&
                    <form onSubmit={handleSubmit(data => saveNewResponse(data, setResponseError, files, setIsLoading, setError, setOpenResponseModal, handleSellersListFetch))}>
                        <input name="response_type" defaultValue="seller" {...register('response_type')} hidden />
                        <input name="approved" defaultValue={true} {...register('approved')} hidden />
                        <input name="seller_request_id" defaultValue={sellerResponseRequestId} {...register('seller_request_id')} hidden />
                        <input name="response_id" defaultValue={sellerReponseId} {...register('response_id')} hidden />
                        <label>Request Status</label>
                        <select name="status_id" {...register('status_id')}>
                            {statuses.map((status,i) => {
                                return (
                                    <option value={status.id}>{status.status}</option>
                                )
                            })}
                        </select>

                        <label>Details <br /><em>Any pertinent information that might be needed</em></label>
                        <textarea rows={10} name="response_details" {...register('response_details')} />

                        <FileUploader onFilesSelected={setFiles} width="300px" height='200px'/>

                        <input type="submit" value="Save Response" className="saveNewSellerRequestButton"/>
                    </form>
                }

                {action === "PUT" &&
                    <form onSubmit={handleSubmitUpdate(data => updateResponse(data,
                        setResponseError, files, setIsLoading, setOpenResponseModal, handleSellersListFetch,
                        setUpdateListData, setError))}>
                        <input name="id" defaultValue={editResponseData.id} {...registerUpdate('id')} hidden />
                        <label>Request Status</label>
                        <select name="status_id" {...registerUpdate('status_id')}>
                            {statuses.map((status,i) => {
                                return (
                                    <option value={status.id}>{status.status}</option>
                                )
                            })}
                        </select>

                        <label>Details <br /><em>Any pertinent information that might be needed</em></label>
                        <input name="response_details" defaultValue={editResponseData.response_details}  {...registerUpdate('response_details')} />

                        <FileUploader onFilesSelected={setFiles} width="300px" height='200px'/>

                        <input type="submit" value="Update Response" className="saveNewSellerRequestButton"/>
                    </form>
                }
            </Modal>

            <ResponseViewModal
                ResponseViewModalOpen={ResponseViewModalOpen}
                setOpenViewResponseModal={setOpenViewResponseModal}
                sellerResponseViewData={sellerResponseViewData}
                handleSubmit={handleSubmit}
                downloadFile={downloadFile}
                openResponseModal={openResponseModal}
                sellerRequest={sellerRequest}
            />

            <DuplicateSellerRequest
                projectId={projectId}
                sellerRequestId={dupeSellerRequestId}
                modalIsOpen={openDuplicateModalValue}
                setModalIsOpen={setOpenDuplicateModal}
                fetchSellerList={fetchSellerList}
                sellerPageFilteredData={sellerFilteredData}
                setsellerListRowData={setsellerListRowData}
                setSellerPageFilterOpen={setFilterOpen}
                sellerListSorting={sellerListSorting}
                setSortOpen={setSortOpen}
            />
            <h1>Seller Request List</h1>
            {error && <h1 className="userError">{error}</h1>}
            <div>
                <button onClick={() => setFilterOpen(!filterOpen)}>Filter</button>
                <button onClick={()=>clearSellerFilter()}>Clear Filter</button>
                <button onClick={openSort}>Sort</button>
                <button onClick={()=>clearSort()}>Clear Sort</button>
                <SellerListFilter filterOpen={filterOpen} setSellerFilteredData={setSellerFilteredData}
                                  sellerFilteredData={sellerFilteredData} handleSellersListFetch={handleSellersListFetch}
                />
                {sortOpen ? (
                    <ul className="menu">
                        <li className="menu-item">
                            Sort By
                            <select name="id" defaultValue={sellerListSorting.sort_one}
                                    onChange={(e) =>
                                        setSellerListSorting({...sellerListSorting, sort_one: e.target.value})}>
                                <option value={0}>Sort Column</option>
                                {sort_array.map((sort,i)=> {
                                    return (
                                        <option key={sort.id} value={sort.id}>{sort.name}</option>
                                    )
                                })}
                            </select>

                            <select name="sort_one_direction" defaultValue={sellerListSorting.sort_one_direction}
                                    onChange={(e) =>
                                        setSellerListSorting({...sellerListSorting, sort_one_direction: e.target.value})}>
                                <option value="asc">Ascending</option>
                                <option value="desc">Descending</option>
                            </select>
                        </li>

                        <li className="menu-item">
                            Then By
                            <select name="sort_two" defaultValue={sellerListSorting.sort_two}
                                    onChange={(e) =>
                                        setSellerListSorting({...sellerListSorting, sort_two: e.target.value})}>
                                <option value={0}>Sort Column</option>
                                {sort_array.map((sort,i)=> {
                                    return (
                                        <option key={sort.id} value={sort.id}>{sort.name}</option>
                                    )
                                })}
                            </select>
                            <select name="sort_two_direction" defaultValue={sellerListSorting.sort_two_direction}
                                    onChange={(e) =>
                                        setSellerListSorting({...sellerListSorting, sort_two_direction: e.target.value})}>
                                <option value="asc">Ascending</option>
                                <option value="desc">Descending</option>
                            </select>
                        </li>
                        <li className="menu-item">
                            Then By
                            <select name="sort_three" defaultValue={sellerListSorting.sort_three}
                                    onChange={(e) =>
                                        setSellerListSorting({...sellerListSorting, sort_three: e.target.value})}>
                                <option value={0}>Sort Column</option>
                                {sort_array.map((sort,i)=> {
                                    return (
                                        <option key={sort.id} value={sort.id}>{sort.name}</option>
                                    )
                                })}
                            </select>
                            <select name="sort_three_direction" defaultValue={sellerListSorting.sort_three_direction}
                                    onChange={(e) =>
                                        setSellerListSorting({...sellerListSorting, sort_three_direction: e.target.value})}>
                                <option value="asc">Ascending</option>
                                <option value="desc">Descending</option>
                            </select>
                        </li>

                        <li className="menu-item">
                            Then By
                            <select name="sort_four" defaultValue={sellerListSorting.sort_four}
                                    onChange={(e) =>
                                        setSellerListSorting({...sellerListSorting, sort_four: e.target.value})}>
                                <option value={0}>Sort Column</option>
                                {sort_array.map((sort,i)=> {
                                    return (
                                        <option key={sort.id} value={sort.id}>{sort.name}</option>
                                    )
                                })}
                            </select>
                            <select name="sort_four_direction" defaultValue={sellerListSorting.sort_four_direction}
                                    onChange={(e) =>
                                        setSellerListSorting({...sellerListSorting, sort_four_direction: e.target.value})}>
                                <option value="asc">Ascending</option>
                                <option value="desc">Descending</option>
                            </select>
                        </li>

                        <li className="menu-item">
                            Then By
                            <select name="sort_five" defaultValue={sellerListSorting.sort_five}
                                    onChange={(e) =>
                                        setSellerListSorting({...sellerListSorting, sort_five: e.target.value})}>
                                <option value={0}>Sort Column</option>
                                {sort_array.map((sort,i)=> {
                                    return (
                                        <option key={sort.id} value={sort.id}>{sort.name}</option>
                                    )
                                })}
                            </select>
                            <select name="sort_five_direction" defaultValue={sellerListSorting.sort_five_direction}
                                    onChange={(e) =>
                                        setSellerListSorting({...sellerListSorting, sort_five_direction: e.target.value})}>
                                <option value="asc">Ascending</option>
                                <option value="desc">Descending</option>
                            </select>
                        </li>

                        <li className="menu-item">
                            Then By
                            <select name="sort_six" defaultValue={sellerListSorting.sort_six}
                                    onChange={(e) =>
                                        setSellerListSorting({...sellerListSorting, sort_six: e.target.value})}>
                                <option value={0}>Sort Column</option>
                                {sort_array.map((sort,i)=> {
                                    return (
                                        <option key={sort.id} value={sort.id}>{sort.name}</option>
                                    )
                                })}
                            </select>
                            <select name="sort_six_direction" defaultValue={sellerListSorting.sort_six_direction}
                                    onChange={(e) =>
                                        setSellerListSorting({...sellerListSorting, sort_six_direction: e.target.value})}>
                                <option value="asc">Ascending</option>
                                <option value="desc">Descending</option>
                            </select>
                        </li>
                        <li className="menu-item">
                            Then By
                            <select name="sort_seven" defaultValue={sellerListSorting.sort_seven}
                                    onChange={(e) =>
                                        setSellerListSorting({...sellerListSorting, sort_seven: e.target.value})}>
                                <option value={0}>Sort Column</option>
                                {sort_array.map((sort,i)=> {
                                    return (
                                        <option key={sort.id} value={sort.id}>{sort.name}</option>
                                    )
                                })}
                            </select>
                            <select name="sort_seven_direction" defaultValue={sellerListSorting.sort_seven_direction}
                                    onChange={(e) =>
                                        setSellerListSorting({...sellerListSorting, sort_seven_direction: e.target.value})}>
                                <option value="asc">Ascending</option>
                                <option value="desc">Descending</option>
                            </select>
                        </li>
                        <li className="menu-item">
                            Then By
                            <select name="sort_eight" defaultValue={sellerListSorting.sort_eight}
                                    onChange={(e) =>
                                        setSellerListSorting({...sellerListSorting, sort_eight: e.target.value})}>
                                <option value={0}>Sort Column</option>
                                {sort_array.map((sort,i)=> {
                                    return (
                                        <option key={sort.id} value={sort.id}>{sort.name}</option>
                                    )
                                })}
                            </select>
                            <select name="sort_eight_direction" defaultValue={sellerListSorting.sort_eight_direction}
                                    onChange={(e) =>
                                        setSellerListSorting({...sellerListSorting, sort_eight_direction: e.target.value})}>
                                <option value="asc">Ascending</option>
                                <option value="desc">Descending</option>
                            </select>
                        </li>
                        <li className="menu-item">
                            Then By
                            <select name="sort_nine" defaultValue={sellerListSorting.sort_nine}
                                    onChange={(e) =>
                                        setSellerListSorting({...sellerListSorting, sort_nine: e.target.value})}>
                                <option value={0}>Sort Column</option>
                                {sort_array.map((sort,i)=> {
                                    return (
                                        <option key={sort.id} value={sort.id}>{sort.name}</option>
                                    )
                                })}
                            </select>
                            <select name="sort_nine_direction" defaultValue={sellerListSorting.sort_nine_direction}
                                    onChange={(e) =>
                                        setSellerListSorting({...sellerListSorting, sort_nine_direction: e.target.value})}>
                                <option value="asc">Ascending</option>
                                <option value="desc">Descending</option>
                            </select>
                        </li>

                        <li>
                            <button onClick={handleSellersListFetch}>Apply</button>
                        </li>
                    </ul>
                ) : null}
            </div>
            <table className="sellerListTable">
                <thead>
                    <tr className="outline">
                        <th className="outline">Phase</th>
                        <th className="outline">Requested On</th>
                        <th className="outline">Seller Req ID</th>
                        <th className="outline">Buyer Req ID</th>
                        <th className="outline">Category</th>
                        <th className="outline">Request Name</th>
                        <th className="outline">Request Details</th>
                        <th className="outline">Date Range</th>
                        <th className="outline">Preferred File Type</th>
                        <th className="outline">Status</th>
                        <th className="outline">Responses</th>
                        <th className="outline"></th>
                        <th className="outline"></th>
                    </tr>
                </thead>
                <tbody>
                    {sellerListRowData && sellerListRowData.map((sellerListData,i)=>{
                        let showDuplicate = true;
                        if (sellerListData.status == "Closed" || sellerListData.show_duplicate_button == false) {
                            showDuplicate = false;
                        }

                        // const showDuplicate = sellerListData.status == "Closed" ? false : true;
                        return (
                            <tr key={sellerListData.id} className="outline">
                                <td className="outline">{sellerListData.batch}</td>
                                <td className="outline">{sellerListData.assigned_date}</td>
                                <td className="outline">{sellerListData.seller_request_id}</td>
                                <td className="outline">{sellerListData.buyer_request_id}</td>
                                <td className="outline">{sellerListData.category}</td>
                                <td className="outline">{sellerListData.data_requested}</td>
                                <td className="outline">{sellerListData.details}</td>
                                <td className="outline">{sellerListData.parameters}</td>
                                <td className="outline">{sellerListData.file_format}</td>
                                <td className="outline">{sellerListData.status}</td>
                                {sellerListData.response_count>0 && <td className="outline">
                                    <button onClick={() => { openViewResponsesModal(sellerListData.id, sellerListData) }}>
                                        View {sellerListData.response_count} Responses
                                    </button>
                                </td>}
                                {sellerListData.response_count === 0 && <td className="outline"></td>}
                                <td className="outline">
                                    <button onClick={() => { openResponseModal(sellerListData.id,
                                        '', "POST", sellerListData)}}>Upload Response</button>
                                </td>
                                <td className="outline">
                                    {showDuplicate && <button onClick={() => { openDuplicateModal(sellerListData.id)}}>Report Duplicate</button>}

                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    );
}
import {React, useEffect, useState} from "react";
import Modal from "react-modal";
import {fetchAuditlog} from "../../Functions/AuditlogFunctions";

export default function Auditlog() {
    const[auditlogs, setAuditlogs] = useState([]);
    const[filterOpen, setFilterOpen] = useState(false);
    const[sortOpen, setSortOpen] = useState();
    const[auditlogFilteredData, setAuditlogFilteredData] = useState({
        id: "",
        timestamp: "",
        log_entry: ""
    });
    const[auditlogSorting, setAuditlogSorting] = useState({
        sort_one: "",
        sort_one_direction: "asc",
        sort_two: "",
        sort_two_direction:"asc",
        sort_three: "",
        sort_three_direction:"asc",
    });
    const sort_array = [
        {id: "id", name: "ID"},
        {id: "timestamp", name:"Created"},
        {id: "log_entry", name:"Log Entry"},
    ];
    const[updateAuditlogData, setUpdateAuditlogData] = useState();

    useEffect(() => fetchAuditlog(setAuditlogs, auditlogFilteredData, auditlogSorting, setFilterOpen, setSortOpen), [updateAuditlogData]);

    function clearFilter() {
        setAuditlogFilteredData( {
            id: "",
            timestamp: "",
            log_entry: ""
        });
        fetchAuditlog(setAuditlogs, auditlogFilteredData, auditlogSorting, setFilterOpen, setSortOpen)
        setUpdateAuditlogData(true);
    }

    function clearSort() {
        setAuditlogSorting({
            sort_one: "",
            sort_one_direction: "asc",
            sort_two: "",
            sort_two_direction:"asc",
            sort_three: "",
            sort_three_direction:"asc",
        });
        fetchAuditlog(setAuditlogs, auditlogFilteredData, auditlogSorting, setFilterOpen, setSortOpen)
        setUpdateAuditlogData(true);
    }

    const openFilter = () => {
        setFilterOpen(!filterOpen);
    };

    const openSort = () => { setSortOpen(!sortOpen); };


    return (
        <div>
            <h1>Auditlog</h1>
            <br/>
            <div>
                <button onClick={openFilter}>Filter</button>
                <button onClick={()=>clearFilter()}>Clear Filter</button>
                <button onClick={openSort}>Sort</button>
                <button onClick={()=>clearSort()}>Clear Sort</button>
                {filterOpen ? (
                    <ul className="menu">
                        <li className="menu-item">
                            Id
                            <input type="text" defaultValue={auditlogFilteredData.id} name="auditlog_id" placeholder="ID"
                                   onChange={(e) =>
                                       setAuditlogFilteredData( {...auditlogFilteredData, id: e.target.value})}>
                            </input>
                        </li>
                        <li className="menu-item">
                            Log Entry
                            <input type="text" name="filterRequestName" defaultValue={auditlogFilteredData.log_entry} placeholder="Log Entry"
                                   onChange={(e) =>
                                       setAuditlogFilteredData( {...auditlogFilteredData, log_entry: e.target.value})}>
                            </input>
                        </li>
                        <li>
                            <button onClick={() => fetchAuditlog(setAuditlogs, auditlogFilteredData, auditlogSorting, setFilterOpen, setSortOpen)}>Apply</button>
                        </li>
                    </ul>
                ) : null}
            </div>

            {sortOpen ? (
                <ul className="menu">
                    <li className="menu-item">
                        Sort By
                        <select name="id" defaultValue={auditlogSorting.sort_one}
                                onChange={(e) =>
                                    setAuditlogSorting({...auditlogSorting, sort_one: e.target.value})}>
                            <option value={0}>Sort Column</option>
                            {sort_array.map((sort,i)=> {
                                return (
                                    <option key={sort.id} value={sort.id}>{sort.name}</option>
                                )
                            })}
                        </select>

                        <select name="sort_one_direction" defaultValue={auditlogSorting.sort_one_direction}
                                onChange={(e) =>
                                    setAuditlogSorting({...auditlogSorting, sort_one_direction: e.target.value})}>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </select>
                    </li>

                    <li className="menu-item">
                        Then By
                        <select name="sort_two" defaultValue={auditlogSorting.sort_two}
                                onChange={(e) =>
                                    setAuditlogSorting({...auditlogSorting, sort_two: e.target.value})}>
                            <option value={0}>Sort Column</option>
                            {sort_array.map((sort,i)=> {
                                return (
                                    <option key={sort.id} value={sort.id}>{sort.name}</option>
                                )
                            })}
                        </select>
                        <select name="sort_two_direction" defaultValue={auditlogSorting.sort_two_direction}
                                onChange={(e) =>
                                    setAuditlogSorting({...auditlogSorting, sort_two_direction: e.target.value})}>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </select>
                    </li>
                    <li className="menu-item">
                        Then By
                        <select name="sort_three" defaultValue={auditlogSorting.sort_three}
                                onChange={(e) =>
                                    setAuditlogSorting({...auditlogSorting, sort_three: e.target.value})}>
                            <option value={0}>Sort Column</option>
                            {sort_array.map((sort,i)=> {
                                return (
                                    <option key={sort.id} value={sort.id}>{sort.name}</option>
                                )
                            })}
                        </select>
                        <select name="sort_three_direction" defaultValue={auditlogSorting.sort_three_direction}
                                onChange={(e) =>
                                    setAuditlogSorting({...auditlogSorting, sort_three_direction: e.target.value})}>
                            <option value="asc">Ascending</option>
                            <option value="desc">Descending</option>
                        </select>
                    </li>

                    <li>
                        <button onClick={() => fetchAuditlog(setAuditlogs, auditlogFilteredData, auditlogSorting, setFilterOpen, setSortOpen)}>Apply</button>
                    </li>
                </ul>
            ) : null}
            <table className="sellerListTable">
                <thead>
                <tr className="outline">
                    <th className="outline">ID</th>
                    <th className="outline">Timestamp</th>
                    <th className="outline">Log Entry</th>
                </tr>
                {/* Responses (Button), , Edit (Button).*/}
                </thead>
                <tbody>
                {auditlogs.length > 0 && (auditlogs).map((auditlog,i)=>{
                    return (
                        <tr key={auditlog.id} className="outline">
                            <td className="outline">{auditlog.id}</td>
                            <td className="outline">{auditlog.timestamp}</td>
                            <td className="outline">{auditlog.log_entry}</td>
                        </tr>
                    )
                })}
                </tbody>
            </table>
        </div>
    )
}
import Modal from "react-modal";
import {customStyles, statusSelect} from "../../Constants";
import {React, useEffect, useState} from "react";
import {fetchSellerList, fetchSellerRequest, saveNewDuplicateQueue} from "../../Functions/SellersList";
import {useForm} from "react-hook-form";
import fetchCategories from "../../Functions/CategoryFunctions";


function DuplicateSellerRequest({projectId, sellerRequestId, modalIsOpen, setModalIsOpen, fetchSellerList,
                                    sellerPageFilteredData, setsellerListRowData, setSellerPageFilterOpen,
                                    sellerListSorting, setSellerSortOpen}) {
    const { register, handleSubmit, reset , setValue} = useForm();
    const[boxes, setBoxes] = useState({});
    const[categories, setCategories] = useState([]);
    const[disableCheckboxes, setDisableCheckboxes] = useState(false);
    const[filterOpen, setFilterOpen] = useState(false);
    const[sellerRequest, setSellerRequest] = useState();
    const[sellerRequests, setSellerRequests] = useState([]);
    const[sellerFilteredData, setSellerFilteredData] = useState({
        id: "",
        assigned_date: "",
        deliverable_phase: "",
        data_requested: "",
        parameters: "",
        details: "",
        description: "",
        status: "",
        batch: "",
        category_id: "",
        batch_enabled: true
    });
    const[retainedId, setRetainedId] = useState();
    useEffect( () => { fetchSellerRequest(sellerRequestId, setSellerRequest, setFilterOpen); }, [sellerRequestId]);
    useEffect( () => { fetchCategories(setCategories); }, [sellerRequestId]);
    useEffect( () => { fetchSellerList(projectId, sellerFilteredData, setSellerRequests, setFilterOpen); }, [projectId, sellerFilteredData]);

    function clearFilter() {
        setSellerFilteredData( {
            id: "",
            assigned_date: "",
            deliverable_phase: "",
            data_requested: "",
            parameters: "",
            details: "",
            description: "",
            status: "",
            batch: "",
            category_id: "",
            batch_enabled: true
        });
    }

    function handleChange(e) {
        const {
            target: { id, checked }
        } = e;

        setBoxes({ ...boxes, [id]: checked });
    }

    function handleClose() {
        setModalIsOpen(false);
        document.querySelectorAll('input[type=checkbox]').forEach(el => el.checked = false);
    }

    function saveDupeRequest(data) {
        const deleted_seller_request_id = data.id;
        const checkboxes = data.checkbox;
        if (checkboxes.length > 1) {
            checkboxes.map((checkbox)=> {
                const dupe_data = {
                    "project_id": parseInt(projectId),
                    "retained_seller_request_id": parseInt(checkbox),
                    "deleted_seller_request_id": parseInt(deleted_seller_request_id),
                    "status": "Open"
                }
                saveNewDuplicateQueue(dupe_data, setModalIsOpen, reset, fetchSellerList, sellerPageFilteredData, setsellerListRowData, setSellerPageFilterOpen,
                    sellerListSorting, setSellerSortOpen);
            })
        } else {
            const dupe_data = {
                "project_id": parseInt(projectId),
                "retained_seller_request_id": parseInt(data.checkbox[0]),
                "deleted_seller_request_id": parseInt(deleted_seller_request_id),
                "status": "Open"
            }
            saveNewDuplicateQueue(dupe_data, setModalIsOpen, reset, fetchSellerList, sellerPageFilteredData, setsellerListRowData, setSellerPageFilterOpen,
                sellerListSorting, setSellerSortOpen);
        }
    }

    return <>
        <Modal isOpen={modalIsOpen} style={customStyles} ariaHideApp={false}>
            <h2>Report Duplicate</h2>
            <button onClick={() => handleClose()}>close</button>
            <br />
            <br />
            <b>To delete request {sellerRequest && sellerRequest.seller_request_id} as a duplicate, please select the
                pre-existing request in the lower grid.</b>
            <br />
            <br />
            <b>Request to be Deleted</b>
            <table className="sellerListTable">
                <thead>
                    <tr className="outline">
                        <th className="outline">Request ID</th>
                        <th className="outline">Request Name</th>
                        <th className="outline">Date Range</th>
                        <th className="outline">Request Details</th>
                    </tr>
                </thead>
                <tbody>
                {sellerRequest &&
                    <tr key={sellerRequest.id} className="outline">
                        <td className="outline">{sellerRequest && sellerRequest.seller_request_id}</td>
                        <td className="outline">{sellerRequest && sellerRequest.data_requested}</td>
                        <td className="outline">{sellerRequest && sellerRequest.parameters}</td>
                        <td className="outline">{sellerRequest && sellerRequest.details}</td>
                    </tr>}
                </tbody>
            </table>
            <br />
            <br />

            <div style={{width: '50%'}}>
                Request Name
                <input type="text" value={sellerFilteredData.data_requested} placeholder="Request Name" onChange={(e) =>
                    setSellerFilteredData( {...sellerFilteredData, data_requested: e.target.value})}>
                </input>

                Request Details
                <input type="text" value={sellerFilteredData.details} placeholder="Request Details" onChange={(e) =>
                    setSellerFilteredData( {...sellerFilteredData, details: e.target.value})}>
                </input>
                <button onClick={() => fetchSellerList(projectId, sellerFilteredData, setSellerRequests, setFilterOpen)}>Apply</button>
                <button onClick={() => clearFilter()}>Clear Filter</button>
                <br/>
                <br/>
            </div>
            <b>Request to be Retained</b>
            <form style={{width: '90%', maxWidth: '1000px !important'}} onSubmit={handleSubmit(data =>
                saveDupeRequest(data))
            }>

                {setValue('id', sellerRequestId)}
                <input name="seller_request_id" {...register('seller_request_id')} hidden/>
                <input name="project_id" {...register('project_id')} hidden/>
                <table>
                    <thead>
                    <tr className="outline">
                        <th className="outline">Request ID</th>
                        <th className="outline">Request Name</th>
                        <th className="outline">Date Range</th>
                        <th className="outline">Request Details</th>
                    </tr>
                    </thead>
                    <tbody>
                {sellerRequests.length > 0  && sellerRequests.filter(function(request) {
                    if (request.id === sellerRequestId) {
                        return false; // skip
                    }
                    return true;
                }).map((request,i)=> {
                    return (
                        <>
                        <tr key={request.id} className="outline">
                            <td className="outline">{request.seller_request_id}</td>
                            <td className="outline">{request.data_requested} </td>
                            <td className="outline">{request.parameters}</td>
                            <td className="outline">{request.details} </td>
                            <td className="outline">
                                <input type="checkbox" {...register('checkbox')}
                                       value={request.id}
                                       disabled={disableCheckboxes && !boxes[request.id]}
                                       onChange={handleChange}
                                       id={request.id}
                                       onClick={(event) => {
                                           if (event.target.checked) {
                                               setRetainedId(request.id);
                                               setDisableCheckboxes(true);
                                           } else {
                                               setRetainedId();
                                               setDisableCheckboxes(false);
                                           }
                                       }}
                                />
                            </td>
                        </tr>
                        </>
                    )
                })}
                    </tbody>
                </table>
                <input type="submit" value="Report Duplicate" className="reportDuplicateButton"/>
            </form>
        </Modal>
    </>
}

export  default DuplicateSellerRequest;